import { ReactComponent as WarningIcon } from 'assets/images/field-error.svg';
import { rupeeFormatter } from 'central-utils/currencyUtils';
import { handleFoodTypeImage } from 'central-utils/foodTypeImageUtils';
import ConfirmOrderSlider from 'components/ConfirmOrderSlider';
import CounterButton from 'components/CounterButton';
import CustomToast from 'components/CustomToast';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'store/customHooks';
import { handleFooterHeight } from 'store/footer/footerSlice';
import { useUpdateOrderItemQuantityByProductIdMutation } from 'store/orders/ordersAPISlice';
import { handleCounterLoader } from 'store/tab/tabSlice';
import { IObject } from 'types';


// NOTE: STYLES NEEDS TO BE DIVIDED - JSON AND TAILWIND


interface Props {
    pendingOrderGroupData: {
        tabContent: IObject[] | null;
        orderGroupHelperData: IObject | null;
    };
}

const PendingTabContent: React.FC<Props> = ({ pendingOrderGroupData }) => {
    const { tabContent, orderGroupHelperData } = pendingOrderGroupData;

    const navigate = useNavigate();

    const dispatch = useAppDispatch();

    const [updateQuantity] = useUpdateOrderItemQuantityByProductIdMutation();

    // Initialize state with tabContent or an empty array
    const [updatedOrderItems, setUpdatedOrderItems] = useState<IObject[]>([]);
    const [toastMessage, setToastMessage] = useState<string | null>(null);

    // Populate state with initial data on mount or when tabContent changes
    useEffect(() => {
        if (tabContent) {
            setUpdatedOrderItems(tabContent);
        }
    }, [tabContent]);

    // Handle quantity change, call updateQuantity API via RTK hook, and handle response
    const handleOnQuantityChange = async (updatedQuantity: number, orderItem: IObject, categoryId: string) => {
        const updatedTotalPrice = updatedQuantity * orderItem.unitPrice; // Calculate the updated total price

        // Show global loader
        dispatch(handleCounterLoader({
            itemId: orderItem?.id,
            isLoading: true,
        }));

        try {
            // Call the RTK hook
            const res = await updateQuantity({
                orderItemId: orderItem?.id,
                price: updatedTotalPrice || 0,
                quantity: updatedQuantity,
            });

            dispatch(handleCounterLoader({
                itemId: orderItem?.id,
                isLoading: false,
            }));

            if (res?.data || res?.success || res?.status === "success" || (res?.data === null && updatedQuantity === 0)) {
                // Update the local state with the new quantity and price
                const updatedItems = updatedOrderItems
                    ?.map((category) => {
                        if (category?.id === categoryId) {
                            // Filter out items with a quantity of 0
                            const updatedOrderItems = category?.orderItems
                                ?.map((item: IObject) =>
                                    item?.id === orderItem?.id
                                        ? { ...item, quantity: updatedQuantity }
                                        : item
                                )
                                ?.filter((item: any) => item?.quantity > 0); // Remove items with quantity 0

                            return {
                                ...category,
                                orderItems: updatedOrderItems,
                            };
                        }
                        return category;
                    })
                    ?.filter((category) => category?.orderItems?.length > 0);

                setUpdatedOrderItems(updatedItems);
            } else {
                const errMessage =
                    res?.error?.data?.message ??
                    res?.error?.error ??
                    res?.error?.data?.error ??
                    "Something went wrong while updating the quantity. Please try again!";

                if (errMessage === "Order is not in NEW status. Cannot add or update items.") {
                    navigate("/payment-in-progress-block");
                } else {
                    dispatch(handleFooterHeight(160));
                    setToastMessage(errMessage);
                }
            }
        } catch (error) {
            console.error("Error updating quantity:", error);
            dispatch(handleCounterLoader({
                itemId: orderItem?.id,
                isLoading: false,
            }));
            setToastMessage("Something went wrong. Please try again later.");
        }
    };

    // Clear The Toast Message when the toast is removed
    const handleOnToastDismiss = () => {
        setToastMessage(null);
    };

    // Render the toast message if available
    const renderToast = () => {
        if (toastMessage) {
            return (
                <CustomToast
                    message={toastMessage}
                    handleOnToastDismiss={handleOnToastDismiss}
                />
            );
        }
        return null;
    };

    if (updatedOrderItems && updatedOrderItems.length && orderGroupHelperData) {
        return (
            <>
                <div className='mb-[73px]'>
                    {updatedOrderItems.map((categorizedOrderItem: any) => (
                        <div key={categorizedOrderItem?.id} className='last:mb-[65px]'>
                            <div className='text-[#1C1C1C] font-semibold text-[16px] first:mt-[10px] py-[10px]'>
                                {categorizedOrderItem?.categoryName}
                            </div>
                            <div className='bg-white shadow-[0_0_10px_rgba(0,0,0,0.15)] rounded-[5px] text-[#0D0D0D] text-[14px] font-normal'>
                                {categorizedOrderItem?.orderItems?.map((orderItem: any) => {
                                    const { id, foodType, displayName, servingSize, quantity, description, unitPrice } = orderItem;
                                    const foodTypeImage = handleFoodTypeImage(foodType);
                                    const orderItemPrice = quantity * unitPrice;

                                    return (
                                        <div key={id} className='w-full flex justify-between items-center py-[15px] border-[1px] border-[#F5F2F2] last:border-none'>
                                            <div className='w-1/2'>
                                                <div className='ml-[10px] flex items-center'>
                                                    {foodTypeImage && (
                                                        <img
                                                            className='w-[10px] h-[10px] mr-[10px]'
                                                            src={foodTypeImage}
                                                            alt={foodType || 'Food type image'}
                                                        />
                                                    )}
                                                    <span>
                                                        {displayName}
                                                        <span className='whitespace-nowrap'>{` (${servingSize})`}</span>
                                                    </span>
                                                </div>
                                                {description && <p className={`text-[#0D0D0D] opacity-50 text-[14px] font-normal break-words mt-1 ${foodTypeImage ? "ml-[30px]" : "ml-[10px]"}`}>
                                                    {description}
                                                </p>}
                                            </div>
                                            <div className='flex items-center'>
                                                <div className='flex items-center'>
                                                    <CounterButton
                                                        itemId={id}
                                                        initialQuantity={quantity}
                                                        buttonType='SMALL'
                                                        onQuantityChange={(quantity) => handleOnQuantityChange(quantity, orderItem, categorizedOrderItem.id)}
                                                    />
                                                </div>
                                                <div className='text-[#0D0D0D] text-[14px] font-normal ml-[10px] max-w-[60px] w-[60px] text-left break-words'>
                                                    {rupeeFormatter(orderItemPrice)}
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    ))}
                </div>
                <div className='flex items-center justify-center'>
                    <button
                        className="fixed bottom-[68px] w-full"
                        style={{ left: "50%", transform: "translateX(-50%)" }}
                    >
                        <ConfirmOrderSlider orderGroupData={orderGroupHelperData} />
                    </button>
                </div>
                {renderToast()}
            </>
        );
    } else {
        return (
            <div className="flex flex-col items-center justify-center h-full text-[#571246] text-[16px] font-normal leading-[24px]">
                <WarningIcon width={32} height={42} className='mb-[10px]' />
                <div>Please place your orders!</div>
            </div>
        );
    }
};

export default PendingTabContent;