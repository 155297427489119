import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IObject } from "types";

interface ICustomerDetails {
  name?: string;
  mobile?: string;
  guests?: string;
  tableCode?: string;
  terms?: boolean;
  primaryName?: string;
}

// Define a type for the slice state
interface TableStatusState {
  orderDetails: IObject | undefined;
  tableInfo: {
    tableNumber: number[];
    tableCode: string;
    tableNames: string[];
  };
  customerDetails: ICustomerDetails;
}

// Define the initial state using that type
const initialState: TableStatusState = {
  orderDetails: {},
  tableInfo: {
    tableCode: "",
    tableNumber: [],
    tableNames: [],
  },
  customerDetails: {
    name: "",
    mobile: "",
    guests: "",
    tableCode: "",
    terms: false,
  },
};

const tableStatusAndOrderSlice = createSlice({
  name: "tableStatusAndOrder",
  initialState,
  reducers: {
    handleOrderDetails: (state, action: PayloadAction<IObject>) => {
      state.orderDetails = action.payload;

      const tableDetails = {
        tableCode: action.payload?.code,
        tableNumber: action.payload?.tables?.map((obj: any) => obj?.id),
        tableNames: action.payload?.tableNames,
      };

      state.tableInfo = tableDetails;
    },
    handleCustomerDetails: (
      state,
      action: PayloadAction<{
        name?: string;
        mobile?: string;
        guests?: string;
        tableCode?: string;
        terms?: boolean;
        primaryName?: string;
      }>
    ) => {
      state.customerDetails = action.payload;
    },
  },
});

export const { handleOrderDetails, handleCustomerDetails } =
  tableStatusAndOrderSlice.actions;

export default tableStatusAndOrderSlice.reducer;
