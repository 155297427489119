import TableVerification from "components/TableVerification";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "store/customHooks";
import { IObject } from "types";

// NOTE: STYLES NEEDS TO BE DIVIDED - JSON AND TAILWIND

interface Props {
  promotionData: IObject[];
}

const ForYouTabContent: React.FC<Props> = ({ promotionData }) => {
  const navigate = useNavigate();

  const tableInfo = useAppSelector(
    (state) => state.tableStatusAndOrder.tableInfo
  );
  const customerDetails = useAppSelector(
    (state) => state.tableStatusAndOrder.customerDetails
  );

  // If the Image is attached to external link or menu items
  const handleImageClick = (imgInfo: any, promoItemId: number) => {
    if (imgInfo?.link?.trim()?.length) {
      // Open the link in a new tab
      window.open(imgInfo.link, "_blank", "noopener noreferrer");
    } else if (imgInfo?.productMapped) {
      // Navigate to the promotion details page
      sessionStorage.setItem("SelectedPromoId", JSON.stringify(promoItemId));
      navigate("/promotion-details", {
        state: {
          promoItemId: promoItemId,
          url: imgInfo?.mainBanner?.url,
          title: imgInfo?.name,
          description: imgInfo?.description,
        },
      });
    }
  };

  return (
    <div className="mb-[20px]">
      <div className="bg-[#571246] text-[#FFFFFF] text-[14px] leading-[16.41px] font-normal w-full h-[50px] mt-[20px] rounded-[5px] opacity-90 p-2 flex items-center justify-between">
        <div>
          Share this PIN with family/friends who wish to join your table and order
        </div>
        <button className="bg-[#F3C736] h-full text-[#000000] text-[20px] leading-[23.44px] font-bold rounded-[2px] ml-[5px] flex items-center whitespace-nowrap p-1">
          <span>PIN </span>
          <span className="ml-[5px]">{tableInfo?.tableCode ?? customerDetails?.tableCode}</span>
        </button>
      </div>
      <TableVerification />
      {promotionData &&
        promotionData.length ?
        promotionData.map((promoItem: any) => {
          if (
            promoItem &&
            promoItem?.promotions &&
            promoItem?.promotions?.length
          ) {
            return (
              <div key={promoItem.id} className="mt-[20px]">
                <div className="text-[#571246] text-[16px] font-semibold">
                  {promoItem?.mainHeader}
                </div>
                <div className="text-[#0D0D0D] text-[14px] font-normal mt-[5px]">
                  {promoItem?.subHeader}
                </div>

                {/* Scrollable image container */}
                <div className="flex overflow-x-auto space-x-4 hide-scrollbar">
                  {promoItem?.promotions.map((imgInfo: any) => (
                    <div
                      onClick={() => handleImageClick(imgInfo, imgInfo?.id)}
                      key={imgInfo.mainBanner.id}
                      className={`
                        ${promoItem?.containerSize === "TWO_BY_TWO"
                          ? "w-[300px] h-[300px]"
                          : "w-[300px] h-[150px]"
                        }
                      flex-shrink-0 relative`}
                    >
                      <img
                        src={imgInfo.mainBanner.url}
                        alt={`${promoItem?.mainHeader} - ${imgInfo?.name}`}
                        className="w-full h-full object-cover rounded-[5px] shadow-lg"
                      />
                    </div>
                  ))}
                </div>
              </div>
            );
          }
        }) : null}
    </div>
  );
};

export default ForYouTabContent;
