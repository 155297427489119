import { rupeeFormatter } from "central-utils/currencyUtils";
import { convertToCamelCase } from "central-utils/stringUtils";
import CustomCheckBox from "components/CustomCheckBox";
import CustomToast from "components/CustomToast";
import React, { useEffect, useMemo, useState } from "react";
import { useAppDispatch } from "store/customHooks";
import { handleMenuCustomizations } from "store/menuCustomization/menuCustomizationSlice";
import { IObject } from "types";

interface Props {
  mainHeader: string;
  isRequired?: boolean;
  bodyHeader?: string;
  checkBoxType: "MULTI_SELECT" | "SINGLE_SELECT";
  options: Option[];
  editData: IObject;
  minValue: number | null;
  maxValue: number | null;
}

interface Option {
  id?: number | string;
  label: string;
  price?: number;
  helperData?: { [key: string]: any };
}

const MenuItemCustomizationCheckbox: React.FC<Props> = ({
  checkBoxType,
  options,
  bodyHeader,
  isRequired = false,
  mainHeader,
  editData,
  maxValue,
  minValue
}) => {
  const dispatch = useAppDispatch();

  const initialMultiCheckedStates = useMemo(() => {
    const selectedOptions = Array.isArray(editData) ? editData : [];
    return options.map((option) => {
      if (mainHeader === "Recommendations") {
        return false; // Always start unchecked
      }
      if (mainHeader !== "Instructions") {
        const idMatch = selectedOptions.some(
          (selectedOption) => selectedOption.id === option.id
        );
        return idMatch;
      } else {
        const helperDataMatch = selectedOptions.some(
          (selectedOption) =>
            selectedOption.label === option.label &&
            selectedOption.helperData === option.helperData
        );
        return helperDataMatch;
      }
    });
  }, [options, mainHeader, editData]);

  const initialSelectedSingleIndex = useMemo(
    () =>
      options.findIndex((option) =>
        editData?.some((opt: Option) => opt.id === option.id)
      ),
    [options, editData]
  );

  const [multiCheckedStates, setMultiCheckedStates] = useState<boolean[]>(
    initialMultiCheckedStates
  );
  const [selectedSingleIndex, setSelectedSingleIndex] = useState<number | null>(
    initialSelectedSingleIndex === -1 ? null : initialSelectedSingleIndex
  );
  const [showAllOptions, setShowAllOptions] = useState(false);
  const [toastMessage, setToastMessage] = useState<string | null>(null);

  useEffect(() => {
    if (
      mainHeader === "Serving Size" &&
      checkBoxType === "SINGLE_SELECT" &&
      editData?.length === 0
    ) {
      if (selectedSingleIndex === null && options.length > 0) {
        setSelectedSingleIndex(0);
        const updatedOptions = options.map((option, i) => ({
          ...option,
          checked: i === 0,
        }));
        dispatch(
          handleMenuCustomizations({
            fieldName: convertToCamelCase(mainHeader),
            options: updatedOptions,
          })
        );
      }
    }
  }, []);
  useEffect(() => {
    if (checkBoxType === "MULTI_SELECT") {
      const updatedOptions = options.map((option, i) => ({
        ...option,
        checked: multiCheckedStates[i],
      }));
      dispatch(
        handleMenuCustomizations({
          fieldName: convertToCamelCase(mainHeader),
          options: updatedOptions,
        })
      );
    } else if (
      checkBoxType === "SINGLE_SELECT" &&
      selectedSingleIndex !== null
    ) {
      const updatedOptions = options.map((option, i) => ({
        ...option,
        checked: i === selectedSingleIndex,
      }));
      dispatch(
        handleMenuCustomizations({
          fieldName: convertToCamelCase(mainHeader),
          options: updatedOptions,
        })
      );
    }
  }, [multiCheckedStates, selectedSingleIndex]);

  const handleMultiSelectChange = (index: number) => {
    const selectedCount = multiCheckedStates.filter(Boolean).length;

    if (!multiCheckedStates[index] && maxValue !== null && selectedCount >= maxValue) {
      setToastMessage(`You can select up to ${maxValue} items only.`);
      return;
    }

    setToastMessage(null); // Clear any existing error when valid selection

    const updatedStates = multiCheckedStates.map((checked, i) =>
      i === index ? !checked : checked
    );
    setMultiCheckedStates(updatedStates);

    const updatedOptions = options.map((option, i) => ({
      ...option,
      checked: updatedStates[i],
    }));
    dispatch(
      handleMenuCustomizations({
        fieldName: convertToCamelCase(mainHeader),
        options: updatedOptions,
      })
    );
  };

  const handleSingleSelectChange = (index: number) => {
    setSelectedSingleIndex(index);

    const updatedOptions = options.map((option, i) => ({
      ...option,
      checked: i === index,
    }));
    dispatch(
      handleMenuCustomizations({
        fieldName: convertToCamelCase(mainHeader),
        options: updatedOptions,
      })
    );
  };

  const renderCheckbox = (
    checked: boolean,
    onChange: () => void,
    checkBoxType: "CHECKBOX" | "RADIO"
  ) => {
    return (
      <CustomCheckBox
        checked={checked}
        onChange={onChange}
        type={checkBoxType}
      />
    );
  };

  // Clear The Toast Message when the toast is removed
  const handleOnToastDismiss = () => {
    setToastMessage(null);
  };


  const renderToast = () => {
    if (toastMessage) {
      return (
        <CustomToast
          message={toastMessage}
          handleOnToastDismiss={handleOnToastDismiss}
        />
      );
    }
    return null;
  };

  return (
    <>
      <div className="menuItemCustomizationCheckbox  flex flex-col gap-2">
        {mainHeader && (
          <div className="menuItemCustomizationMainHeader">{mainHeader}</div>
        )}
        <div className="menuItemOptionsContainer flex flex-col gap-[1.5px]">
          {bodyHeader && (
            <h3 className="text-[12px] text-gray-500 pb-[10px]">{bodyHeader}</h3>
          )}
          {options
            .slice(0, showAllOptions ? options.length : 4)
            .map((option, index) => (
              <div
                key={index}
                className="flex justify-between items-center pb-[5px] menuItemOptionItem gap-2"
              >
                <div
                  className={`menuItemOptionLabel capitalize ${(checkBoxType === "MULTI_SELECT" &&
                    multiCheckedStates[index]) ||
                    (checkBoxType === "SINGLE_SELECT" &&
                      selectedSingleIndex === index)
                    ? "font-bold" // Apply bold font weight if selected
                    : "normal"
                    }`}
                >
                  {option.label}
                </div>
                <div className="flex justify-end items-center">
                  {option?.price ? (
                    <div className="menuItemOptionPrice pr-[10px]">
                      {rupeeFormatter(option.price)}
                    </div>
                  ) : (
                    ""
                  )}
                  {checkBoxType === "MULTI_SELECT" &&
                    renderCheckbox(
                      multiCheckedStates[index],
                      () => handleMultiSelectChange(index),
                      "CHECKBOX"
                    )}
                  {checkBoxType === "SINGLE_SELECT" &&
                    renderCheckbox(
                      selectedSingleIndex === index,
                      () => handleSingleSelectChange(index),
                      "RADIO"
                    )}
                </div>
              </div>
            ))}
          {options.length > 4 && (
            <div className="flex justify-end pt-[10px]">
              <button
                onClick={() => setShowAllOptions(!showAllOptions)}
                className="menuItemShowMoreButton"
              >
                {showAllOptions ? "Show Less" : "Show More"}
              </button>
            </div>
          )}
        </div>
      </div>
      {renderToast()}
    </>
  );
};

export default MenuItemCustomizationCheckbox;
