import InitialDataFetcher from "components/InitialDataFetcher";
import OrderProvider from "context/order/OrderContext";
import PusherProvider from "context/pusher";
import CartChannelProvider from "context/pusher/cart";
import CheckoutChannelProvider from "context/pusher/checkout";
import CustomerLogoutChannelProvider from "context/pusher/customer-logout";
import PriceChannelProvider from "context/pusher/price";
import TableVerificationChannelProvider from "context/pusher/table-verification";
import VoidOrderChannelProvider from "context/pusher/void-order";
import WaiterChannelProvider from "context/pusher/waiter";
import MainLayout from "layout";
import CheckoutPage from "pages/checkout-details-page";
import CustomerDetailsPage from "pages/customer-details-page";
import EnterNamePage from "pages/enter-name-page";
import HomePage from "pages/home-page";
import MenuListingPage from "pages/menu-listing-page";
import OrdersPage from "pages/order-details-page";
import OtpVerificationPage from "pages/otp-verification-page";
import PaymentConfirmationPage from "pages/payment-confirmation-page";
import PaymentInProgressBlockPage from "pages/payment-in-progress-block-page";
import PaymentStatusPage from "pages/payment-status-page";
import PromotionsDetailsPage from "pages/promotion-details-page";
import ReorderPage from "pages/reorder-page";
import SearchPage from "pages/search-page";
import TablePinVerificationPage from "pages/table-pin-verification-page";
import TermsAndConditionsPage from "pages/terms-and-conditions-page";
import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

const RouteCenter: React.FC = () => {
  return (
    <Router>
      <InitialDataFetcher />
      <PusherProvider>
        <TableVerificationChannelProvider>
          <PriceChannelProvider>
            <CartChannelProvider>
              <CheckoutChannelProvider>
                <WaiterChannelProvider>
                  <CustomerLogoutChannelProvider>
                    <OrderProvider>
                      <VoidOrderChannelProvider>
                        <MainLayout>
                          <Routes>
                            <Route path="/" element={<CustomerDetailsPage />} />
                            <Route
                              path="/verify-otp"
                              element={<OtpVerificationPage />}
                            />
                            <Route
                              path="/enter-name"
                              element={<EnterNamePage />}
                            />
                            <Route
                              path="/verify-pin"
                              element={<TablePinVerificationPage />}
                            />
                            <Route path="/home" element={<HomePage />} />
                            <Route path="/search" element={<SearchPage />} />
                            <Route
                              path="/category"
                              element={<MenuListingPage />}
                            />
                            <Route path="/order" element={<OrdersPage />} />
                            <Route path="/re-order" element={<ReorderPage />} />
                            <Route path="/check-out" element={<CheckoutPage />} />
                            <Route
                              path="/payment-confirmation"
                              element={<PaymentConfirmationPage />}
                            />
                            <Route
                              path="/payment-in-progress-block"
                              element={<PaymentInProgressBlockPage />}
                            />
                            <Route
                              path="/payment-status"
                              element={<PaymentStatusPage />}
                            />
                            <Route
                              path="/promotion-details"
                              element={<PromotionsDetailsPage />}
                            />
                            <Route
                              path={`/terms/:type`}
                              element={<TermsAndConditionsPage />}
                            />
                          </Routes>
                        </MainLayout>
                      </VoidOrderChannelProvider>
                    </OrderProvider>
                  </CustomerLogoutChannelProvider>
                </WaiterChannelProvider>
              </CheckoutChannelProvider>
            </CartChannelProvider>
          </PriceChannelProvider>
        </TableVerificationChannelProvider>
      </PusherProvider>
    </Router>
  );
};

export default RouteCenter;
