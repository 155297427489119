import { Modal } from "antd";
import modalClose from "assets/images/modal-close-icon.svg";
import { handleValuesAreDiff } from "central-utils/comparisonUtils";
import { rupeeFormatter } from "central-utils/currencyUtils";
import { convertToCapitalizedString } from "central-utils/stringUtils";
import { handleValidImageUrl } from "central-utils/validImageUrlUtils";
import CustomLoader from "components/common/CustomLoader";
import CounterButton from "components/CounterButton";
import CustomToast from "components/CustomToast";
import MenuItemCustomizationCheckbox from "components/menu-item-customization-modal/MenuItemCustomizationCheckbox";
import { useOrder } from "context/order/OrderContext";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { handleGlobalLoader } from "store/auth/authSlice";
import { useAppDispatch, useAppSelector } from "store/customHooks";
import { useGetMenuItemByProductIdAndBranchIdQuery } from "store/menuCustomization/menuCustomizationAPISlice";
import {
  getMenuCustomizationRelatedStates,
  handleClearMenuCustomizations,
  handleMenuItemBannerDisplay,
  handleMenuItemsBannerType,
} from "store/menuCustomization/menuCustomizationSlice";
import {
  useAddMenuItemToOrderByOrderIdMutation,
  useUpdateOrderItemInOrderByOrderItemIdMutation,
} from "store/orders/ordersAPISlice";
import {
  handleRefetchCategorizedMenuListAPIInMenuListingPage,
  handleRefetchDashboardAPIInHomePage,
} from "store/refetchCounter/refetchCounterSlice";
import { IObject } from "types";

interface Option {
  id: number;
  label: string;
  value?: string;
  price?: number;
  helperData?: any;
}

type CheckBoxType = "SINGLE_SELECT" | "MULTI_SELECT";

interface IComponentPropDetail {
  bodyHeader: string;
  isRequired: boolean;
  mainHeader: string;
  checkBoxType: CheckBoxType;
  editData: IObject;
  minValue: number | null;
  maxValue: number | null;
}

const MenuItemCustomizationModal: React.FC<{
  type?: "ADD" | "EDIT";
  source?: "MODAL" | "LISTING" | "CARD";
  editData?: IObject;
  productMasterId: number;
  onClose: () => void;
  setAddItemQuantity?: (quantity: number) => void;
}> = ({
  productMasterId,
  onClose,
  source = "LISTING",
  type = "ADD",
  editData,
  setAddItemQuantity,
}) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { orderIsUninitialized, refetchOrderData } = useOrder();

    const [prevSelectedData, setPrevSelectedData] = useState<any>([]);
    const [minMaxModValue, setMinMaxValue] = useState<{
      minValue: number | null;
      maxValue: number | null;
    } | null>(null);
    const [disableAddButton, setDisableAddButton] = useState<boolean>(false);
    const [toastMessage, setToastMessage] = useState<string | null>(null);
    const [removeToast, setRemoveToast] = useState<boolean>(true);

    useEffect(() => {
      if (editData && Object?.keys(editData)?.length > 0) {
        const prevData = {
          ...selectedMenuCustomizationOptions,
          quantity: editData?.quantity,
        };
        setPrevSelectedData(prevData);
      }
    }, [editData]);

    const initialAPICallSuccess = useAppSelector(
      (state) => state.auth.initialAPISuccess
    );

    const ids = sessionStorage.getItem("Ids");
    const parsedIds = ids && JSON.parse(ids);
    const orderId = JSON.parse(sessionStorage.getItem("OrderId") || "{}");

    const [selectedPrice, setSelectedPrice] = useState(0);

    const {
      data: menuDetailData,
      isLoading: menuDetailIsLoading,
      isError: menuDetailIsError,
      isSuccess: menuDetailIsSuccess,
      isFetching: menuDetailIsFetching,
    } = useGetMenuItemByProductIdAndBranchIdQuery(
      { productMasterId, branchId: parsedIds?.branchId },
      { skip: !initialAPICallSuccess }
    );

    const [addMenuItemToOrder] = useAddMenuItemToOrderByOrderIdMutation();
    const [updateOrderItem] = useUpdateOrderItemInOrderByOrderItemIdMutation();

    const handleCustomizationSectionData = (type: string): Option[] => {
      switch (type) {
        case "Serving Size":
          return (
            menuDetailData?.menuItems
              .map((item: any) => ({
                id: item?.menuItemMasterId,
                label: item?.productVariant?.size?.size,
                price: item?.branchSettings?.price ?? undefined,
                helperData: item,
              })) || []
          );
        case "Recommendations":
          return (
            menuDetailData?.recommendations?.map((item: any) => ({
              id: item.id,
              label: `${item?.displayName} (${item?.servingSize})`,
              price: item?.price ?? undefined,
              helperData: item,
            })) || []
          );
        case "Instructions":
          return (
            menuDetailData?.instructions?.map((item: any, index: number) => ({
              id: index,
              label: item,
              helperData: item,
            })) || []
          );
        case "Extras & Add-ons":
          return (
            menuDetailData?.modifiers?.map((item: any) => ({
              id: item?.id,
              label: item?.modifier?.itemName,
              price: item?.price ?? undefined,
              helperData: item,
            })) || []
          );
        default:
          return [];
      }
    };

    const handleCustomizationEditData = (type: string): Option[] | [] => {
      switch (type) {
        case "Serving Size":
          return editData?.menuItemMaster?.id
            ? [
              {
                id: editData?.menuItemMaster?.id,
                label: editData?.menuItemMaster?.productVariant?.size?.size,
                price: editData?.branchSettings?.price ?? undefined,
                helperData: editData?.menuItemMaster,
              },
            ]
            : [];
        case "Instructions":
          return editData?.instructions?.length > 0
            ? editData?.instructions?.map((item: any, index: number) => ({
              id: index,
              label: item,
              helperData: item,
            }))
            : [];
        case "Extras & Add-ons":
          return editData?.modifiers?.length > 0
            ? editData?.modifiers?.map((item: any) => ({
              id: item?.id,
              label: item?.modifier?.itemName,
              price: item?.price ?? undefined,
              helperData: item,
            }))
            : [];
        default:
          return [];
      }
    };

    const defaultComponentPropDetail: IComponentPropDetail[] = useMemo(() => {
      // Determine the appropriate bodyHeader based on minMaxModValue
      const getBodyHeader = () => {
        if (!minMaxModValue) return ""; // No minMaxModValue provided

        const { minValue, maxValue } = minMaxModValue;
        const filledDot = "●"; // Unicode character for filled dot

        if (minValue !== null && maxValue !== null) {
          return `Required ${filledDot} Select any from ${minValue} to ${maxValue} options.`;
        } else if (minValue !== null) {
          return `Required ${filledDot} Select at least ${minValue} from the options.`;
        } else if (maxValue !== null) {
          return `Required ${filledDot} Select up to ${maxValue} from the options.`;
        }

        return "";
      };

      const bodyHeader = getBodyHeader(); // Get the dynamically generated header

      return [
        {
          bodyHeader: "",
          isRequired: true,
          mainHeader: "Serving Size",
          checkBoxType: "SINGLE_SELECT",
          editData:
            type === "EDIT"
              ? handleCustomizationEditData("Serving Size")
              : handleCustomizationSectionData("Serving Size"),
          maxValue: null,
          minValue: null
        },
        {
          bodyHeader: bodyHeader,
          isRequired: false,
          mainHeader: "Extras & Add-ons",
          checkBoxType: "MULTI_SELECT",
          editData:
            type === "EDIT"
              ? handleCustomizationEditData("Extras & Add-ons")
              : handleCustomizationSectionData("Extras & Add-ons"),
          minValue: minMaxModValue ? minMaxModValue?.minValue : null,
          maxValue: minMaxModValue ? minMaxModValue?.maxValue : null,
        },
        {
          bodyHeader: "",
          isRequired: false,
          mainHeader: "Instructions",
          checkBoxType: "MULTI_SELECT",
          editData:
            type === "EDIT"
              ? handleCustomizationEditData("Instructions")
              : handleCustomizationSectionData("Instructions"),
          maxValue: null,
          minValue: null
        },
      ];
    }, [minMaxModValue, type]);

    const componentPropDetails: IComponentPropDetail[] =
      type === "EDIT"
        ? defaultComponentPropDetail
        : [
          defaultComponentPropDetail?.[0],
          defaultComponentPropDetail?.[1],
          {
            bodyHeader: "",
            isRequired: false,
            mainHeader: "Recommendations",
            checkBoxType: "MULTI_SELECT",
            editData: handleCustomizationSectionData("Recommendations"),
            maxValue: null,
            minValue: null
          },
          defaultComponentPropDetail?.[2],
        ];

    const handleCloseModal = () => {
      dispatch(handleClearMenuCustomizations());
      !orderIsUninitialized && refetchOrderData();
      source === "LISTING"
        ? dispatch(handleRefetchCategorizedMenuListAPIInMenuListingPage())
        : dispatch(handleRefetchDashboardAPIInHomePage());
      onClose();
    };

    useEffect(() => {
      if (menuDetailIsError) {
        setToastMessage("Something went wrong while fetching details!");
        handleCloseModal();
      }
    }, [menuDetailIsError]);

    const { selectedMenuCustomizationOptions } = useAppSelector(
      getMenuCustomizationRelatedStates
    );

    useEffect(() => {
      const totalPrice = handleSelectedPrice(selectedMenuCustomizationOptions);
      setSelectedPrice(totalPrice);
    }, [selectedMenuCustomizationOptions]);

    useEffect(() => {
      const { servingSize } = selectedMenuCustomizationOptions;
      const findMenuItemsWithMinMaxMod = menuDetailData?.menuItems?.find(
        (menuItem: any) => menuItem?.menuItemMasterId === servingSize?.[0]?.id
      );
      if (findMenuItemsWithMinMaxMod) {
        if (
          !findMenuItemsWithMinMaxMod?.branchSettings?.minModifier ||
          !findMenuItemsWithMinMaxMod?.branchSettings?.maxModifier
        ) {
          setMinMaxValue({
            minValue:
              findMenuItemsWithMinMaxMod?.branchSettings?.minModifiers ===
                undefined
                ? null
                : findMenuItemsWithMinMaxMod?.branchSettings?.minModifiers,
            maxValue:
              findMenuItemsWithMinMaxMod?.branchSettings?.maxModifiers ===
                undefined
                ? null
                : findMenuItemsWithMinMaxMod?.branchSettings?.maxModifiers,
          });
        } else {
          setMinMaxValue(null);
        }
      }
    }, [menuDetailData?.menuItems, selectedMenuCustomizationOptions]);

    useEffect(() => {
      if (selectedMenuCustomizationOptions?.["extras&AddOns"]?.length) {
        setRemoveToast(false);
      }
    }, [selectedMenuCustomizationOptions?.["extras&AddOns"]]);

    useEffect(() => {
      if (minMaxModValue && selectedMenuCustomizationOptions) {
        const { recommendations, servingSize, instructions, ...extrasAndAddOns } =
          selectedMenuCustomizationOptions;

        // Ensure `extrasAndAddOns` is an object that we can get length from
        if (
          extrasAndAddOns["extras&AddOns"] &&
          Array.isArray(Object.values(extrasAndAddOns["extras&AddOns"]))
        ) {
          const extrasAndAddOnsLength = Object.values(
            extrasAndAddOns["extras&AddOns"]
          ).length;

          // Calculate `disableCheck`
          const disableCheck =
            (minMaxModValue.minValue !== null &&
              extrasAndAddOnsLength < minMaxModValue.minValue) ||
            (minMaxModValue.maxValue !== null &&
              extrasAndAddOnsLength > minMaxModValue.maxValue);

          // Set toast message based on validation
          if (disableCheck && !removeToast) {
            if (
              minMaxModValue.minValue !== null &&
              extrasAndAddOnsLength < minMaxModValue.minValue
            ) {
              setToastMessage(
                `Please select at least ${minMaxModValue.minValue} option${minMaxModValue.minValue > 1 ? "s" : ""}.`
              );
            } else if (
              minMaxModValue.maxValue !== null &&
              extrasAndAddOnsLength > minMaxModValue.maxValue
            ) {
              setToastMessage(
                `Please select no more than ${minMaxModValue.maxValue} option${minMaxModValue.maxValue > 1 ? "s" : ""}.`
              );
            }
          } else {
            setToastMessage(null); // Clear toast message if selection is valid
          }
          setDisableAddButton(disableCheck);
        }
      }
    }, [minMaxModValue, selectedMenuCustomizationOptions, removeToast]);

    const [menuQuantity, setMenuQuantity] = useState(
      type === "EDIT" ? Number(editData?.quantity) : 1
    );
    const handleQuantityUpdate = (quantity: number) => {
      setMenuQuantity(quantity);
    };

    const handleSubmit = async () => {
      if (!selectedMenuCustomizationOptions?.servingSize?.length) {
        setToastMessage("Please select a serving size before adding the item!");
        return;
      }

      // Show global loader
      dispatch(handleGlobalLoader(true));

      // Extract necessary fields
      const { recommendations, servingSize, instructions, ...extrasAndAddOns } =
        selectedMenuCustomizationOptions;
      const servingSizeId = servingSize?.[0]?.id;

      // Build the payload
      let customizedMenuItemPayload: any = {
        quantity: menuQuantity,
        menuItemMaster: {
          id: servingSizeId,
        },
        price: selectedPrice * menuQuantity,
      };

      if (extrasAndAddOns?.["extras&AddOns"]?.length) {
        customizedMenuItemPayload.modifiers = extrasAndAddOns[
          "extras&AddOns"
        ].map((obj: { id: number }) => ({ id: obj.id }));
      }

      if (instructions?.length) {
        customizedMenuItemPayload.instructions = instructions.map(
          (obj: any) => obj.label
        );
      }

      let customerDetailsPayload: any = {};
      const customerId = JSON.parse(sessionStorage.getItem("CustomerId") || "{}");
      if (typeof customerId !== "object") {
        customerDetailsPayload = {
          customerId: customerId,
        };
        customizedMenuItemPayload.customerId = customerId;
      }

      if (type === "ADD") {
        try {
          // Add the customized menu item to the order
          const res = await addMenuItemToOrder({
            orderId,
            menuItemsBody: customizedMenuItemPayload,
          });

          if (res?.data) {
            console.log("[customized menu order add] res?.data :>> ", res?.data);
          } else {
            throw new Error(
              res?.error?.data?.message ??
              res?.error?.error ??
              res?.error?.data?.error ??
              `Something went wrong while adding ${menuDetailData?.displayName ??
              menuDetailData?.productMaster?.itemName
              }. Please try again!`
            );
          }

          let recommendationsAdded: number = 0;
          // Process recommendations if any
          if (recommendations?.length) {
            const recommendationPromises = recommendations.map((recc: any) =>
              addMenuItemToOrder({
                orderId,
                menuItemsBody: {
                  quantity: 1,
                  menuItemMaster: { id: recc?.id },
                  price: recc?.price ? recc?.price : 0,
                  ...customerDetailsPayload,
                },
              })
            );

            const recommendationResults = await Promise.all(
              recommendationPromises
            );

            recommendationResults.forEach((result: any, index: number) => {
              if (result?.data) {
                recommendationsAdded++;
                console.log(
                  "[recommendations menu order add] res?.data :>> ",
                  result?.data
                );
              }
            });
          }

          if (setAddItemQuantity) {
            setAddItemQuantity(menuQuantity + recommendationsAdded);
            dispatch(handleMenuItemsBannerType("ADD"));
            dispatch(handleMenuItemBannerDisplay(true));
          }
        } catch (error: any) {
          console.error("Error during ADD operation: ", error);
          setToastMessage(error.message);
          error.message ===
            "Order is not in NEW status. Cannot add or update items." &&
            navigate("/payment-in-progress-block");
        } finally {
          // Hide global loader
          dispatch(handleGlobalLoader(false));
          handleCloseModal();
        }
      } else if (type === "EDIT") {
        if (
          handleValuesAreDiff(prevSelectedData, {
            ...selectedMenuCustomizationOptions,
            quantity: menuQuantity,
          })
        ) {
          if (setAddItemQuantity) {
            setAddItemQuantity(menuQuantity);
            dispatch(handleMenuItemsBannerType("EDIT"));
            dispatch(handleMenuItemBannerDisplay(true));
          }

          updateOrderItem({
            orderItemId: editData?.id,
            orderItemBody: customizedMenuItemPayload,
          }).then((res: any) => {
            handleCloseModal();
            if (res?.data) {
              console.log("[edit menu custom] res?.data :>> ", res?.data);
            } else {
              const errMessage =
                res?.error?.data?.message ??
                res?.error?.error ??
                res?.error?.data?.error ??
                "Something went wrong while updating your order. Please try again!";
              console.error("Error during EDIT operation: ", res);
              setToastMessage(errMessage);
              errMessage ===
                "Order is not in NEW status. Cannot add or update items." &&
                navigate("/payment-in-progress-block");
            }
            dispatch(handleGlobalLoader(false));
          });
        } else {
          dispatch(handleGlobalLoader(false));
          handleCloseModal();
          // toast.info("There was no change made for your preferences !");
        }
      }

      dispatch(handleGlobalLoader(false));
    };

    const menuItemImage = handleValidImageUrl(
      menuDetailData?.productMaster?.images
    );

    // Clear The Toast Message when the toast is removed
    const handleOnToastDismiss = () => {
      setToastMessage(null);
    };

    // Render the toast message if available
    const renderToast = () => {
      if (toastMessage) {
        return (
          <CustomToast
            message={toastMessage}
            handleOnToastDismiss={handleOnToastDismiss}
          />
        );
      }
      return null;
    };

    return (
      <Modal
        width={"100vw"}
        closable={false}
        open={true}
        onCancel={handleCloseModal}
        footer={null}
        className="repeatCustomizationModal"
        style={{
          padding: 0,
          margin: 0,
          borderRadius: "999px",
          maxWidth: "100vw",
        }}
      >
        <div className="repeatCustomizationModal">
          {(menuDetailIsLoading || menuDetailIsFetching) && (
            <CustomLoader
              isLoading={menuDetailIsLoading || menuDetailIsFetching}
            />
          )}
          <div className="relative menuCustomizationModalContainer flex flex-col overflow-y-auto justify-between gap-1">
            <div>
              <div
                className={`flex items-center justify-between p-4 sticky top-0 bg-white z-10`}
              >
                <div className={`menuCustomizationModalHeader capitalize `}>
                  {convertToCapitalizedString(
                    menuDetailData?.displayName ??
                    menuDetailData?.productMaster?.itemName
                  )}
                </div>
                <button onClick={handleCloseModal}>
                  <img src={modalClose} alt="modalClose" />
                </button>
              </div>
              {menuDetailIsSuccess && menuDetailData && (
                <div className="flex flex-col">
                  <div>
                    <div>
                      {menuItemImage ? (
                        <div className="h-full flex flex-col justify-start items-center relative pb-4">
                          <img
                            src={menuItemImage}
                            alt={
                              menuDetailData?.displayName ??
                              menuDetailData?.productMaster?.itemName
                            }
                            className="menuCustomizationModalImage"
                          />
                          <div className="absolute bottom-6 flex flex-row w-full items-center justify-start gap-2 px-2 whitespace-nowrap overflow-x-scroll [&::-webkit-scrollbar]:hidden [-ms-overflow-style:none] [scrollbar-width:none]">
                            {menuDetailData?.calories && (
                              <div className="bg-[#F3C736] py-[2px] px-2 rounded-xl text-black">
                                {menuDetailData?.calories} Kcal
                              </div>
                            )}
                            {menuDetailData?.tag?.length &&
                              menuDetailData?.tag?.map((tag: any) => (
                                tag?.id ? (
                                  <div className="bg-[#F3C736] py-[2px] px-2 rounded-xl text-black">
                                    {convertToCapitalizedString(tag?.name)}
                                  </div>
                                ) : null
                              ))}
                          </div>
                        </div>
                      ) : (
                        <div className="flex flex-row w-full items-center justify-start gap-2 px-2 whitespace-nowrap overflow-x-scroll [&::-webkit-scrollbar]:hidden [-ms-overflow-style:none] [scrollbar-width:none]">
                          {menuDetailData?.calories && (
                            <div className="bg-[#F3C736] py-[2px] px-2 rounded-xl text-black">
                              {menuDetailData?.calories} Kcal
                            </div>
                          )}
                          {menuDetailData?.tag?.length &&
                            menuDetailData?.tag?.map((tag: any) => (
                              tag?.id ? (
                                <div className="bg-[#F3C736] py-[2px] px-2 rounded-xl text-black">
                                  {convertToCapitalizedString(tag?.name)}
                                </div>
                              ) : null
                            ))}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="px-3">
                    <div>
                      <div
                        className={`pt-3 pb-5 menuCustomizationModalDescription`}
                      >
                        {menuDetailData?.description}
                      </div>
                      <div className="flex flex-col gap-2">
                        {componentPropDetails.map(
                          (item, index) =>
                            handleCustomizationSectionData(item.mainHeader)
                              .length > 0 && (
                              <div key={index} className="pt-[10px] first:pt-0">
                                <MenuItemCustomizationCheckbox
                                  editData={item.editData}
                                  bodyHeader={item.bodyHeader}
                                  isRequired={item.isRequired}
                                  mainHeader={item.mainHeader}
                                  checkBoxType={item.checkBoxType}
                                  options={handleCustomizationSectionData(
                                    item.mainHeader
                                  )}
                                  maxValue={item.maxValue}
                                  minValue={item.minValue}
                                />
                              </div>
                            )
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {renderToast()}
            </div>
            <div className="sticky bottom-0 inset-x-0 bg-white w-full h-[65px] flex items-center justify-between py-1 px-3 gap-3">
              <CounterButton
                initialQuantity={menuQuantity}
                onQuantityChange={handleQuantityUpdate}
              />
              <button
                disabled={disableAddButton}
                onClick={handleSubmit}
                className={`flex items-center w-full footerAddItemButton py-4 px-3 justify-around ${disableAddButton && "opacity-75"
                  }`}
              >
                {type === "EDIT" ? "Edit Item" : "Add Item"}
                {selectedPrice * menuQuantity !== 0 && (
                  <div className="pl-3">
                    {rupeeFormatter(selectedPrice * menuQuantity)}
                  </div>
                )}
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  };

const handleSelectedPrice = (obj: any): number => {
  let total = 0;

  // Check if obj is an array
  if (Array.isArray(obj)) {
    // Sum up prices in the array
    obj.forEach((item) => {
      if (item.checked && typeof item.price === "number") {
        total += item.price;
      }
    });
  } else if (typeof obj === "object" && obj !== null) {
    // Recursively iterate through object properties
    Object.entries(obj).forEach(([key, value]) => {
      if (key !== "recommendations") {
        // Exclude recommendations
        total += handleSelectedPrice(value); // Recursive call
      }
    });
  }

  return total;
};

export default MenuItemCustomizationModal;
