import { Modal } from "antd";
import errorIcon from "assets/images/error-icon.svg";
import { useNavigate } from "react-router-dom";

const CheckoutModal = ({ onClose }: any) => {
  const navigate = useNavigate();
  return (
    <Modal
      width={"100vw"}
      closable={false}
      open={true}
      onCancel={onClose}
      footer={null}
      className="repeatCustomizationModal"
      style={{
        padding: 0,
        margin: 0,
        borderRadius: "999px",
        maxWidth: "100vw",
      }}
    >
      <div className="repeatCustomizationModal">
        <div className="repeatCustomizationModalContent">
          <div className="p-5 ">
            <div className="text-2xl font-light">
              Are you sure you’d like to pay the bill and close your table?
            </div>
          </div>
          <div className="w-full bg-[#D2000033] font-bold px-5 flex flex-row items-center py-1">
            <img src={errorIcon} alt="errorIcon" className="mr-2" />
            This action cannot be undone
          </div>
          <div className="px-5 flex flex-row items-center gap-3 py-5">
            <button
              onClick={onClose}
              className="w-full text-center repeatCustomizationModalEditCustomizationButton  p-4 rounded-md"
            >
              Cancel
            </button>
            <button
              onClick={() => navigate("/check-out")}
              className="w-full text-center repeatCustomizationModalAddCustomizationButton p-4 rounded-md"
            >
              Yes, Continue
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CheckoutModal;
