import { convertToCapitalizedString } from "central-utils/stringUtils";
import { handleValidImageUrl } from "central-utils/validImageUrlUtils";
import ThirdLevelCategoryCard from "components/category/ThirdLevelCategoryCard";

// Function to handle ordered menu items
const handleOrderedMenuItems = (
  obj: any,
  orderData: any
): [boolean, number, any] => {
  const isMatched = (orderData?.orderGroups ?? [])
    ?.filter((orderGroup: any) => orderGroup?.status === "NEW")
    ?.flatMap((order: any) => order?.orderItems)
    ?.filter((orderGroup: any) => {
      // menuMasterIds?.includes(orderGroup?.menuItemMaster?.id)
      return (
        obj?.productMaster?.id === orderGroup?.menuItemMaster?.productMaster?.id
      );
    });

  if (isMatched?.length) {
    let totalQuantity = 0;
    for (const item of isMatched) {
      totalQuantity += item.quantity;
    }
    const [matchedData] = isMatched;
    if (totalQuantity === 0) {
      return [false, 0, {}];
    }

    return [true, totalQuantity, matchedData];
  }

  return [false, 0, {}];
};

// Function to handle the last level of data
const handleLastLevelData = (lastLevelData: any[], orderData: any) => {
  return lastLevelData?.map((obj: any) => {
    let validImageUrl = handleValidImageUrl(obj?.productMaster?.images);

    const [isOrdered, initialQuantity, orderHelperData] =
      handleOrderedMenuItems(obj, orderData);

    let menuItemPrice: number | undefined = undefined;
    if (obj?.menuItems?.[0]?.branchSettings?.price) {
      menuItemPrice = obj?.menuItems?.[0]?.branchSettings?.price;
    }

    return {
      foodType: obj?.foodType,
      cardName: convertToCapitalizedString(obj?.displayName),
      cardImage: validImageUrl,
      defaultPrice: obj?.menuItems?.[0]?.branchSettings?.defaultPrice || 0,
      originalPrice: menuItemPrice,
      helperData: { ...obj, orderItemHelperData: orderHelperData },
      isMenuItemOrdered: isOrdered,
      initialQuantity: initialQuantity,
    };
  });
};

// Function to handle the accordion body data
const handleAccordionBodyData = (
  bodyData: any[],
  orderData: any,
  setAddItemQuantity: (quantity: number) => void
) => {
  if (!bodyData?.length) return null;

  const processedLastLevelData = handleLastLevelData(bodyData, orderData);

  return processedLastLevelData?.map((lastLevel, index) => (
    <div key={index} className="mb-[10px]">
      <ThirdLevelCategoryCard
        cardDetails={lastLevel}
        cardType="COMPACT"
        setAddItemQuantity={setAddItemQuantity}
      />
    </div>
  ));
};

// Function to handle nested accordion data
const handleNestedAccordionData = (
  nestedData: any[],
  // activeFilter: "VEGETARIAN" | "NON_VEGETARIAN" | "EGGETARIAN" | null,
  // searchQuery: string,
  orderData: any,
  setAddItemQuantity: (quantity: number) => void
) => {
  if (!nestedData?.length) return null;

  return nestedData?.map((nextLevel, nextLevelIndex) => ({
    accordionHeader: convertToCapitalizedString(nextLevel?.categoryName),
    accordionHeaderCount: nextLevel?.menuResponseDetails?.length,
    isAccordionOpen:
      (nextLevelIndex === 0 || null) ??
      // || activeFilter !== null || searchQuery?.trim() !== ""
      true,
    accordionBody: handleAccordionBodyData(
      nextLevel?.menuResponseDetails,
      orderData,
      setAddItemQuantity
    ),
  }));
};

// Function to handle the top level accordion data
export const handleAccordionData = (
  apiFilteredData: any,
  // activeFilter: "VEGETARIAN" | "NON_VEGETARIAN" | "EGGETARIAN" | null,
  // searchQuery: string,
  orderData: any,
  setAddItemQuantity: (quantity: number) => void
) => {
  if (apiFilteredData && apiFilteredData?.length > 0) {
    const [initialData] = apiFilteredData;

    const convertedAPIData = initialData?.subCategories?.map(
      (secondLevel: any, secondLevelIndex: number) => {
        if (
          secondLevel?.subCategories?.length === 0 &&
          secondLevel?.menuResponseDetails?.length > 0
        ) {
          return {
            accordionHeader: convertToCapitalizedString(
              secondLevel?.categoryName
            ),
            accordionHeaderCount: secondLevel?.menuResponseDetails?.length,
            isMainAccordionOpen:
              (secondLevelIndex === 0 || null) ??
              // || activeFilter !== null
              true,
            isNestedAccordion: false,
            mainAccordionBody: handleAccordionBodyData(
              secondLevel?.menuResponseDetails,
              orderData,
              setAddItemQuantity
            ),
            helperData: secondLevel,
          };
        } else if (secondLevel?.subCategories?.length > 0) {
          const accordionHeaderCount = secondLevel?.subCategories?.reduce((sum: number, item: any) => sum + item.menuResponseDetails.length, 0);
          return {
            accordionHeader: convertToCapitalizedString(
              secondLevel?.categoryName
            ),
            accordionHeaderCount: accordionHeaderCount,
            isMainAccordionOpen:
              (secondLevelIndex === 0 || null) ??
              // || activeFilter !== null
              true,
            isNestedAccordion: true,
            nestedAccordionBody: handleNestedAccordionData(
              secondLevel?.subCategories,
              // activeFilter,
              // searchQuery,
              orderData,
              setAddItemQuantity
            ),
            helperData: secondLevel,
          };
        }
        return null; // Handle other cases as needed
      }
    );
    return convertedAPIData?.filter(Boolean); // Return processed data for further use
  }
  return null; // Handle case where apiFilteredData is not valid or empty
};

// Function to handle Direct last level cards
export const handleDirectCardLevelData = (
  apiFilteredData: any,
  orderData: any,
  setAddItemQuantity: (quantity: number) => void
) => {
  console.log("[search] apiFilteredData", apiFilteredData);
  if (apiFilteredData && apiFilteredData?.length > 0) {
    const [initialData] = apiFilteredData;
    const directData =
      apiFilteredData &&
      // initialData?.subCategories?.length === 0 &&
      initialData?.menuResponseDetails?.length > 0 &&
      handleAccordionBodyData(
        initialData?.menuResponseDetails,
        orderData,
        setAddItemQuantity
      );

    return directData;
  }
  return null; // Handle case where apiFilteredData is not valid or empty
};

// Function that formats data for Parent Category
export const handleMainCategoryData = (apiData: any) => {
  const filterCategories = (category: any) => {
    const filteredCategory = { ...category };

    if (
      filteredCategory?.subCategories &&
      filteredCategory?.subCategories?.length
    ) {
      filteredCategory.subCategories = filteredCategory?.subCategories
        ?.map((subCategory: any) => filterCategories(subCategory))
        ?.filter(
          (subCategory: any) =>
            subCategory?.menuResponseDetails?.length ||
            subCategory?.subCategories.length
        );
    }

    return filteredCategory;
  };

  return apiData
    ?.map((category: any) => filterCategories(category))
    ?.filter(
      (category: any) =>
        category?.menuResponseDetails?.length || category?.subCategories?.length
    );
};
