import React from "react";

interface CustomCheckBoxProps {
  type?: "RADIO" | "CHECKBOX";
  checked: boolean;
  onChange: () => void;
}

const CustomCheckBox: React.FC<CustomCheckBoxProps> = ({
  checked,
  onChange,
  type = "CHECKBOX",
}) => {
  if (type === "RADIO") {
    return (
      <label className="inline-flex items-center cursor-pointer">
        <input
          type="checkbox"
          checked={checked}
          onChange={onChange}
          className="hidden"
        />
        <span
          className={`w-5 h-5 flex items-center justify-center bg-[#ffffff] border-2 rounded-full border-[#571246] `}
        >
          {checked ? (
            <span className="w-3 h-3 bg-[#571246] rounded-full"></span>
          ) : (
            <span className="bg-white rounded-full"></span>
          )}
        </span>
      </label>
    );
  } else {
    return (
      <label className="inline-flex items-center cursor-pointer">
        <input
          type="checkbox"
          checked={checked}
          onChange={onChange}
          className="hidden"
        />
        <span
          className={`w-5 h-5 flex items-center justify-center bg-[#ffffff] border-2 border-[#571246] rounded`}
        >
          {checked && (
            <svg
              className="w-4 h-4 text-[#ffffff] bg-[#571246]"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              viewBox="0 0 24 24"
            >
              <path
                d="M5 13l4 4L19 7"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          )}
        </span>
      </label>
    );
  }
};

export default CustomCheckBox;
