import { createSlice } from "@reduxjs/toolkit";

interface RefetchCounterState {
  refetchDashboardAPIInHomePage: number;
  refetchReOrderAPIInReOrderPage: number;
  refetchCategorizedMenuListAPIInMenuListingPage: number;
  refetchRepeatCustomizationModal: number;
}

const initialState: RefetchCounterState = {
  refetchDashboardAPIInHomePage: 0,
  refetchCategorizedMenuListAPIInMenuListingPage: 0,
  refetchReOrderAPIInReOrderPage: 0,
  refetchRepeatCustomizationModal: 0,
};

const refetchCounterSlice = createSlice({
  name: "refetchCounter",
  initialState,
  reducers: {
    // Increment the counter for refetching Dashboard API in Home Page
    handleRefetchDashboardAPIInHomePage: (state) => {
      state.refetchDashboardAPIInHomePage++;
    },
    // Increment the counter for refetching ReOrder API in ReOrder Page
    handleRefetchReOrderAPIInReOrderPage: (state) => {
      state.refetchReOrderAPIInReOrderPage++;
    },
    // Increment the counter for refetching Categorized Menu List API in Menu Listing Page
    handleRefetchCategorizedMenuListAPIInMenuListingPage: (state) => {
      state.refetchCategorizedMenuListAPIInMenuListingPage++;
    },
    handleRefetchRepeatCustomizationModal: (state) => {
      state.refetchRepeatCustomizationModal++;
    },
  },
});

export const {
  handleRefetchCategorizedMenuListAPIInMenuListingPage,
  handleRefetchDashboardAPIInHomePage,
  handleRefetchReOrderAPIInReOrderPage,
  handleRefetchRepeatCustomizationModal,
} = refetchCounterSlice.actions;

export default refetchCounterSlice.reducer;
