import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/customHooks";
import {
  handleSelectedTabDetails,
  handleTabChange,
  handleShowCallWaiterModal,
} from "store/tab/tabSlice";
import { TabItem } from "types";
import CallWaiterModal from "components/call-waiter-modal";

interface Props {
  tabItems: TabItem[];
  identifierKey: string;
  defaultTab?: any;
}

const CustomTab: React.FC<Props> = ({
  tabItems,
  identifierKey,
  defaultTab,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const [activeTab, setActiveTab] = useState<number | null>(null);

  const showCallWaiter = useAppSelector(
    (state) => state.tab.showCallWaiterModal
  );
  const unconfirmedOrderItemsCount = useAppSelector(
    (state) => state.footer.unconfirmedOrderItems
  );
  const { changeDiffTabDetails } = useAppSelector(
    (state) => state.tab
  );

  // Initialize activeTab on component mount
  useEffect(() => {
    const storedHomeTab = sessionStorage.getItem("activeHomeHeaderTab");
    const storedOrderTab = sessionStorage.getItem("activeOrderHeaderTab");

    if (identifierKey === "homePageHeaderTabs") {
      if (defaultTab) {
        setActiveTab(parseInt(defaultTab));
      } else if (storedHomeTab) {
        setActiveTab(parseInt(storedHomeTab));
      } else {
        setActiveTab(tabItems[0]?.id);
      }
    } else if (identifierKey === "orderPageHeaderTabs") {
      if (defaultTab) {
        setActiveTab(parseInt(defaultTab));
      } else if (storedOrderTab) {
        setActiveTab(parseInt(storedOrderTab));
      } else {
        setActiveTab(tabItems[0]?.id);
      }
    } else {
      if (!showCallWaiter) {
        // Automatically select the tab when the pathName matches location.pathname
        const matchingTab = tabItems.find(
          (item) => item.type === "ROUTE" && item.pathName === location.pathname
        );
        // If there's a matching tab, set it as active
        if (matchingTab) {
          setActiveTab(matchingTab.id);
          dispatch(
            handleSelectedTabDetails({
              [identifierKey]: { ...matchingTab, icon: undefined },
            })
          );
        } else if (tabItems?.[0]?.type === "TAB") {
          // Default behavior for non-route tabs
          setActiveTab(tabItems?.[0]?.id);
          dispatch(
            handleSelectedTabDetails({
              [identifierKey]: { ...tabItems[0], icon: undefined },
            })
          );
        }
      }
    }
  }, [
    defaultTab,
    tabItems,
    identifierKey,
    location.pathname,
    showCallWaiter,
    dispatch,
  ]);

  // Handle tab changes from external sources
  useEffect(() => {
    if (changeDiffTabDetails && changeDiffTabDetails[identifierKey]) {
      const matchingTab = tabItems.find(
        (item) => item.id === changeDiffTabDetails[identifierKey].id
      );

      if (matchingTab?.id && matchingTab?.id !== activeTab) {
        handleTabClick(matchingTab.id, matchingTab);
      }
      dispatch(handleTabChange({}));
    }
  }, [changeDiffTabDetails, dispatch, identifierKey, tabItems]);

  // Update selected tab details when activeTab changes
  useEffect(() => {
    if (activeTab !== null) {
      const currentTab = tabItems.find((item) => item.id === activeTab);
      if (currentTab) {
        const { handleOnClick, icon, ...serializableItem } = currentTab;
        dispatch(
          handleSelectedTabDetails({
            [identifierKey]: { ...serializableItem, icon: undefined },
          })
        );
      }
    }
  }, [activeTab, dispatch, identifierKey, tabItems]);

  const handleTabClick = (index: number, item: TabItem) => {
    if (item.disabled) return;

    setActiveTab(index);

    if (identifierKey === "homePageHeaderTabs") {
      sessionStorage.setItem("activeHomeHeaderTab", index.toString());
    }

    if (identifierKey === "orderPageHeaderTabs") {
      sessionStorage.setItem("activeOrderHeaderTab", index.toString());
    }

    const { handleOnClick, icon, ...serializableItem } = item;

    dispatch(
      handleSelectedTabDetails({
        [identifierKey]: { ...serializableItem, icon: undefined },
      })
    );

    if (item.pathName && item.type === "ROUTE") {
      navigate(item.pathName, { state: { id: item.id, label: item.label } });
    }

    item.handleOnClick?.();
  };

  return (
    <div className="bg-white h-[70px] w-full flex items-center">
      {tabItems.map((item) => (
        <div
          onClick={() => handleTabClick(item.id, item)}
          key={item.id}
          className={`flex-1 h-full flex justify-center items-center 
            ${activeTab === item.id
              ? "border-b-[5px] border-[#571246]"
              : "border-b-[5px] border-transparent"
            }`}
        >
          <button
            className={`flex flex-col items-center justify-center
              ${activeTab === item.id
                ? "text-[#571246]"
                : item.disabled
                  ? " opacity-50"
                  : "text-black"
              }`}
            disabled={item.disabled}
          >
            <div className="relative">
              <item.icon
                height={item.iconHeight || 25}
                width={item.iconWidth || 25}
                className={`
                  ${activeTab === item.id
                    ? "text-[#571246]"
                    : item.disabled
                      ? "text-gray-900"
                      : "text-black"
                  }
                `}
              />
              {item?.unconfirmedItems && unconfirmedOrderItemsCount > 0 && (
                <div className="bg-[#F3C736] w-7 h-7 text-black rounded-full absolute left-3 bottom-1 flex items-center justify-center font-bold">
                  {unconfirmedOrderItemsCount}
                </div>
              )}
            </div>
            <span
              className={`${activeTab === item.id
                ? item.activeLabelClassName
                : item.inactiveLabelClassName
                } mt-[10px]`}
            >
              {item.label}
            </span>
          </button>
        </div>
      ))}
      {showCallWaiter && (
        <CallWaiterModal
          handleCloseModal={() => dispatch(handleShowCallWaiterModal(false))}
        />
      )}
    </div>
  );
};

export default CustomTab;
