import { rupeeFormatter } from "central-utils/currencyUtils";
import usePreventBackNavigation from "hooks/usePreventBackNavigation";
import React, { useEffect, useState } from "react";
import { useLogoutMutation } from "store/auth/authApiSlice";
import { handleLogOut, handleLogOutState } from "store/auth/authSlice";
import { useAppDispatch } from "store/customHooks";
import { IObject } from "types";
import { ReactComponent as JollyFace } from "assets/images/happy-face.svg";

// NOTE: STYLES NEEDS TO BE DIVIDED - JSON AND TAILWIND

const PaymentStatusPage: React.FC = () => {
  usePreventBackNavigation("/payment-status");

  const dispatch = useAppDispatch();

  const [sessionStorageData] = useState(sessionStorage);
  const [paymentStatusData, setPaymentStatusData] = useState<IObject>({});

  useEffect(() => {
    if (
      sessionStorageData &&
      sessionStorageData?.PaymentStatusAPIData &&
      sessionStorageData?.PaymentStatusAPIData?.length > 0
    ) {
      const parsedPaymentStatusData = JSON.parse(
        sessionStorageData?.PaymentStatusAPIData
      );
      setPaymentStatusData(parsedPaymentStatusData);
    }
  }, [sessionStorageData]);

  useEffect(() => {
    dispatch(handleLogOutState());
  }, [dispatch]);

  const [logout] = useLogoutMutation();

  useEffect(() => {
    const performLogout = async () => {
      try {
        // Call the logout API
        await logout({}).unwrap();
        dispatch(handleLogOut());
      } catch (error) {
        console.error("Error during logout:", error);
        dispatch(handleLogOut());
      }
    };

    performLogout();
  }, [dispatch, logout]);

  return (
    <div className="flex flex-col items-center justify-center h-full text-[#571246] text-[16px] font-normal leading-[24px] text-center mx-10">
      <JollyFace width={44} height={45} className="mb-[20px]" />
      <div className="font-semibold text-[24px] leading-[28.13px]">
        Payment Successful.
      </div>
      <div className="my-[10px]">
        {paymentStatusData && Object.keys(paymentStatusData)?.length > 0
          ? `We have received your payment of ${rupeeFormatter(
              paymentStatusData?.paymentAmount
            )} for the Order ID ${
              paymentStatusData?.orderId
            }. Thank you for visiting our Bob's Bar.`
          : "Thanks for choosing JollyGunj, come back soon for more fun!"}
      </div>
      {/* <button className="bg-[#571246] w-[145px] h-[60px] text-white text-center rounded-[5px] mt-[10px]">
        Download Invoice
      </button> */}
    </div>
  );
};

export default PaymentStatusPage;
