import { UnorderedListOutlined } from "@ant-design/icons";
import { convertToCapitalizedString } from "central-utils/stringUtils";
import React, { useState, useRef, useEffect } from "react";
import { useAppDispatch } from "store/customHooks";
import { handleMenuItemClicked } from "store/floatingButton/floatingButtonSlice";

interface Props {
  menuListData: {
    name: string;
    helperData?: any;
  }[];
}

const FloatingMenuListButton: React.FC<Props> = ({ menuListData }) => {
  const dispatch = useAppDispatch();

  const [showPopover, setShowPopover] = useState(false);
  const [windowHeight] = useState<number>(window.innerHeight);
  const popoverRef = useRef<HTMLDivElement>(null);

  const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setShowPopover((prev) => !prev);
  };
  const handleItemClick = (menuItem: any) => {
    dispatch(handleMenuItemClicked(menuItem));
    setShowPopover(false);
  };

  const renderSubCategories = (subCategories: any[]) => {
    return subCategories.map((subCategory) => (
      <div key={subCategory.id}>
        {/* Display subcategory name */}
        <div
          className="floatingMenuPopoverContent py-2 pl-4"
          onClick={() => handleItemClick(subCategory)}
        >
          {convertToCapitalizedString(subCategory.categoryName)}
        </div>
      </div>
    ));
  };

  // Close the popover when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        popoverRef.current &&
        !popoverRef.current.contains(event.target as Node)
      ) {
        setShowPopover(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div
      className={`fixed right-[3vw] ${
        windowHeight <= 750
          ? windowHeight <= 730
            ? "bottom-[10vh]"
            : "bottom-[9.5vh]"
          : "bottom-[8.5vh]"
      } z-999`}
    >
      <div className="flex flex-col justify-end items-end" ref={popoverRef}>
        {showPopover && (
          <div className="z-40 flex flex-col floatingMenuPopover overflow-y-auto py-[10px] px-[15px] rounded my-2">
            {menuListData?.map((menuItem, index: number) => (
              <div key={`${index}+${menuItem.name}`} className="py-2">
                {/* Display main category */}
                <div
                  className="floatingMenuPopoverContent py-2 first:pt-0 last:pb-0 font-normal text-[#F3C736]"
                  onClick={() => handleItemClick(menuItem)}
                >
                  {convertToCapitalizedString(menuItem.name)}
                </div>
                {/* Render subcategories if they exist */}
                {menuItem?.helperData?.subCategories &&
                  renderSubCategories(menuItem.helperData.subCategories)}
              </div>
            ))}
          </div>
        )}
        <button
          className="floatingMenuButton text-center font-bold rounded-md w-12 h-12 flex items-center justify-center shadow-[rgba(17,_17,_26,_0.1)_0px_0px_16px]"
          onClick={handleButtonClick}
        >
          <UnorderedListOutlined className="floatingMenuIcon text-2xl" />
        </button>
      </div>
    </div>
  );
};

export default FloatingMenuListButton;
