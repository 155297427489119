import React, { useEffect, useState } from 'react';
import { ReactComponent as Error } from "assets/images/field-error.svg";
import { useLocation } from 'react-router-dom';
import { useOrder } from 'context/order/OrderContext';
import { useAppSelector } from 'store/customHooks';

type ToastProps = {
    message?: string;
    type?: 'success' | 'error' | 'warning' | 'info';
    duration?: number;
    handleOnToastDismiss?: () => void;
};

// NOTE: STYLES NEEDS TO BE DIVIDED - JSON AND TAILWIND

const CustomToast: React.FC<ToastProps> = ({
    message = "Oops! There was an error. Please try again.",
    type = "error",
    duration = 3000,
    handleOnToastDismiss
}) => {
    const { pathname } = useLocation();
    const { orderData, orderIsUninitialized } = useOrder();

    const footerHeightStore = useAppSelector((state) => state.footer.footerHeight);

    const getFooterHeight = () => {
        if (pathname === "/order" && !orderIsUninitialized && orderData && !orderData?.isVerified) {
            return "bottom-[9rem]";
        } else if (pathname === "/re-order") {
            return footerHeightStore === 70 ? "bottom-[5rem]" : "bottom-[9rem]";
        } else {
            return "bottom-[5rem]";
        }
    };

    const [bottomCSS, setBottomCSS] = useState(getFooterHeight());
    useEffect(() => {
        // Update footer height if pathname changes
        setBottomCSS(getFooterHeight());
    }, [pathname, footerHeightStore]);

    const [visible, setVisible] = useState(false);
    useEffect(() => {
        setVisible(true);
        const timer = setTimeout(() => {
            handleOnToastDismiss?.();
            setVisible(false);
        }, duration);
        return () => clearTimeout(timer);
    }, [duration, handleOnToastDismiss]);

    if (!visible) return null;

    const typeStyles: Record<string, {
        background: string; icon: React.FunctionComponent<React.SVGProps<SVGSVGElement> & {
            title?: string;
        }>; color: string; text: string; fill: string;
    }> = {
        success: { background: 'bg-green-600', icon: Error, fill: "#D20101", color: "#FFFFFF", text: "text-white" },
        error: { background: 'bg-[#D20101]', icon: Error, fill: "#D20101", color: "#FAFAFA", text: "text-[#FAFAFA]" },
        warning: { background: 'bg-yellow-500', icon: Error, fill: "#D20101", color: "#FFFFFF", text: "text-white" },
        info: { background: 'bg-blue-600', icon: Error, fill: "#D20101", color: "#FFFFFF", text: "text-white" },
    };

    const { background, icon: ToastIcon, color, text, fill } = typeStyles[type];

    return (
        <div
            onClick={() => {
                setVisible(false);
                handleOnToastDismiss?.();
            }}
            className={`fixed ${bottomCSS} left-0 right-0 z-[9999] flex justify-center`}
        >
            <div
                className={`${background} ${text} px-4 py-3 rounded-lg shadow-md flex items-center space-x-2 transition-all duration-500 ease-out`}
            >
                <span><ToastIcon fill={fill} height={20.5} width={20.5} color={color} /></span>
                <span className="text-[16px] font-normal break-words">{message}</span>
            </div>
        </div>
    );
};

export default CustomToast;