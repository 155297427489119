import {
  convertStringToHyphenated,
  convertToCapitalizedString,
} from "central-utils/stringUtils";
import SecondLevelCategoryAccordion from "components/category/SecondLevelCategoryAccordion";
import CustomLoader from "components/common/CustomLoader";
import ErrorMessage from "components/common/ErrorMessage";
import FloatingMenuListButton from "components/FloatingMenuListButton";
import SearchSection from "components/toggle-item-section";
import { useOrder } from "context/order/OrderContext";
import { useCartChannel } from "context/pusher/cart";
import { usePriceChannel } from "context/pusher/price";
import {
  handleAccordionData,
  handleDirectCardLevelData,
  handleMainCategoryData,
} from "pages/menu-listing-page/accordionUtils";
import { handleAPIFiltering } from "pages/menu-listing-page/apiFormattingUtils";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/customHooks";
import { handleSearchAndFilterReset } from "store/globalSearchAndFilters/globalSearchAndFiltersSlice";
import { useGetCategorizedMenuItemsQuery } from "store/menuCustomization/menuCustomizationAPISlice";

interface IMainCategory {
  id: number | string;
  categoryName: string;
  helperData: any;
}

const MenuListingPage: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const paramText = queryParams.get("name");

  const dispatch = useAppDispatch();

  const {
    orderData,
    orderIsLoading,
    orderIsUninitialized,
    orderIsError,
    orderIsSuccess,
    orderIsFetching,
    refetchOrderData,
  } = useOrder();
  const pusherCartData = useCartChannel();
  const pusherPriceData = usePriceChannel();

  const ids = sessionStorage.getItem("Ids");
  const parsedIds = ids && JSON.parse(ids);

  const displayBannerFlag = useAppSelector(
    (state) => state.menuCustomization.menuItemsBannerDisplay
  );
  const {
    eggetarianFilter,
    nonVegetarianFilter,
    searchQuery,
    vegetarianFilter,
  } = useAppSelector((state) => state.globalSearchAndFilters);
  const refetchMenuListingPage = useAppSelector(
    (state) =>
      state.refetchCounter.refetchCategorizedMenuListAPIInMenuListingPage
  );
  const initialAPICallSuccess = useAppSelector(
    (state) => state.auth.initialAPISuccess
  );

  const [initialAPIData, setInitialAPIData] = useState<any>([]);
  const [eggFilter, setEggFilter] = useState<boolean>(false);
  const [nonVegFilter, setNonVegFilter] = useState<boolean>(false);
  const [vegFilter, setVegFilter] = useState<boolean>(false);
  const [finalAPIFilteredData, setFinalAPIFilteredData] = useState<any>([]);
  const [finalAccordionData, setFinalAccordionData] = useState<any>([]);
  const [mainCategory, setMainCategory] = useState<IMainCategory[]>([]);
  const [floatingButtonData, setFloatingButtonData] = useState<any>([]);
  const [activeFilter, setActiveFilter] = useState<
    "VEGETARIAN" | "NON_VEGETARIAN" | "EGGETARIAN" | null
  >(null);
  const [updateMenuItemPrice, setUpdateMenuItemPrice] =
    useState<boolean>(false);
  const [addItemQuantity, setAddItemQuantity] = useState<number>(0);
  const [queryResInDiffCategoryData, setQueryResInDiffCategoryData] = useState<
    string | null
  >(null);

  // Not displaying the banner if the quantity is 0
  useEffect(() => {
    if (!displayBannerFlag) {
      setAddItemQuantity(0);
    }
  }, [displayBannerFlag]);

  // Price Update Pusher
  useEffect(() => {
    if (
      pusherPriceData &&
      Object.keys(pusherPriceData ?? {})?.length &&
      Object.hasOwn(pusherPriceData, "isPriceUpdateFlag")
    ) {
      console.log("pusherPriceData :>> ", pusherPriceData);
      setUpdateMenuItemPrice(pusherPriceData?.isPriceUpdateFlag);
    }
  }, [pusherPriceData]);

  // Unconfirmed Order Items
  useEffect(() => {
    if (pusherCartData && Object.keys(pusherCartData ?? {})?.length) {
      console.log("pusherCartData :>> ", pusherCartData);
    }
  }, [pusherCartData]);

  useEffect(() => {
    if (orderIsError) {
      console.error("Order by ID - Error");
    }
  }, [orderIsError]);

  //update activeFilter
  useEffect(() => {
    setActiveFilter(
      eggetarianFilter
        ? "EGGETARIAN"
        : nonVegetarianFilter
          ? "NON_VEGETARIAN"
          : vegetarianFilter
            ? "VEGETARIAN"
            : null
    );

    setEggFilter(eggetarianFilter);
    setNonVegFilter(nonVegetarianFilter);
    setVegFilter(vegetarianFilter);
  }, [eggetarianFilter, nonVegetarianFilter, vegetarianFilter]);

  // Function to handle toggle and dispatch filter actions
  const handleToggle = (
    filterName: "VEGETARIAN" | "NON_VEGETARIAN" | "EGGETARIAN"
  ) => {
    if (activeFilter === filterName) {
      // Remove filter if already active
      //   dispatch(handleFoodTypeFiltersReset());
      setActiveFilter(null);
    } else {
      // Apply filter and reset others
      //   dispatch(handleFoodTypeFilters(filterName, true));
      setActiveFilter(filterName);
    }
  };

  const {
    data: categorizedMenuData,
    isLoading: categorizedMenuIsLoading,
    isError: categorizedMenuIsError,
    isSuccess: categorizedMenuIsSuccess,
    isFetching: categorizedMenuIsFetching,
    refetch: refetchCategorizedMenuData,
  } = useGetCategorizedMenuItemsQuery(
    {
      branchId: parsedIds?.branchId,
      vegFilter,
      nonVegFilter,
      eggFilter,
    },
    { skip: !initialAPICallSuccess }
  );

  useEffect(() => {
    if (categorizedMenuIsSuccess && categorizedMenuData) {
      if (categorizedMenuData.length > 0) {
        const hasValidData = (obj: any) => {
          if (!obj) return false;

          const hasMenuResponseDetails = obj?.menuResponseDetails?.length > 0;
          const hasSubCategories =
            obj?.subCategories?.length > 0 &&
            obj.subCategories.some((sub: any) => hasValidData(sub));

          return hasMenuResponseDetails || hasSubCategories;
        };

        const initialFiltering = categorizedMenuData?.filter((obj: any) =>
          hasValidData(obj)
        );

        if (initialFiltering.length > 0) {
          setInitialAPIData(initialFiltering);
          const firstCategoryName =
            initialFiltering[0]?.categoryName || initialFiltering[0]?.tagName;
          sessionStorage.setItem(
            "FirstCategoryName",
            convertStringToHyphenated(firstCategoryName)
          );
        } else {
          setInitialAPIData([]); // If filtering results in no valid data, clear the API data
        }
      } else {
        setInitialAPIData([]); // If categorizedMenuData is empty, clear the API data
      }
    }
  }, [
    categorizedMenuData,
    categorizedMenuIsSuccess,
    orderIsSuccess,
    orderData,
    // activeFilter,
  ]);

  useEffect(() => {
    if (initialAPIData && !initialAPIData?.length) {
      setMainCategory([]);
      setFinalAPIFilteredData([]);
      setQueryResInDiffCategoryData(null);
      setFloatingButtonData([]);
      setFinalAccordionData([]);
      return;
    }

    const mainLevel = handleMainCategoryData(initialAPIData)?.map(
      (category: any) => ({
        id: category?.id,
        categoryName: convertToCapitalizedString(category?.categoryName),
        helperData: category,
      })
    );
    setMainCategory(mainLevel);

    const [finalFilteredData, queryInDiffCategoryData] = handleAPIFiltering(
      initialAPIData,
      searchQuery,
      paramText ?? ""
    );
    setFinalAPIFilteredData([finalFilteredData]);
    setQueryResInDiffCategoryData(queryInDiffCategoryData);

    setFloatingButtonData(handleFloatingButtonData([finalFilteredData]));

    setFinalAccordionData(
      handleAccordionData(
        [finalFilteredData],
        // activeFilter,
        // searchQuery,
        orderData,
        setAddItemQuantity
      )
    );
  }, [
    paramText,
    // activeFilter,
    // searchQuery,
    initialAPIData,
    orderIsSuccess,
    orderData,
  ]);

  useEffect(() => {
    console.log("initialAPIData :>> ", initialAPIData);
  }, [initialAPIData]);

  useEffect(() => {
    console.log("finalAPIFilteredData :>> ", finalAPIFilteredData);
  }, [finalAPIFilteredData]);

  useEffect(() => {
    console.log("finalAccordionData :>> ", finalAccordionData);
  }, [finalAccordionData]);

  useEffect(() => {
    const [data] = finalAPIFilteredData;
    if (
      data &&
      data?.length === 0 &&
      queryResInDiffCategoryData &&
      queryResInDiffCategoryData?.length > 0
    ) {
      navigate(`/category?name=${queryResInDiffCategoryData}`);
    }
  }, [queryResInDiffCategoryData, finalAPIFilteredData, navigate]);

  useEffect(() => {
    const refetchData = () => {
      refetchCategorizedMenuData({
        branchId: parsedIds?.branchId,
        vegFilter,
        nonVegFilter,
        eggFilter,
      });
      !orderIsUninitialized && refetchOrderData();
    };

    if (refetchMenuListingPage !== 0) {
      refetchData();
      setUpdateMenuItemPrice(false);
    }

    if (updateMenuItemPrice) {
      setVegFilter(false);
      setNonVegFilter(false);
      setEggFilter(false);
      refetchData();
      setUpdateMenuItemPrice(false);
    }
  }, [
    orderIsUninitialized,
    refetchCategorizedMenuData,
    refetchMenuListingPage,
    parsedIds?.branchId,
    vegFilter,
    nonVegFilter,
    eggFilter,
    refetchOrderData,
    updateMenuItemPrice,
  ]);

  useEffect(() => {
    if (
      !finalAPIFilteredData?.length &&
      (activeFilter !== null || searchQuery !== "")
    ) {
      const firstCategoryName = sessionStorage.getItem("FirstCategoryName");
      if (firstCategoryName) {
        // Navigate to the category with the name
        navigate(`/category?name=${encodeURIComponent(firstCategoryName)}`);
      } else {
        // Optionally handle the case where `firstCategoryName` is not found
        console.error("No firstCategoryName found in sessionStorage.");
        // navigate("/home");
      }
    }
  }, [finalAPIFilteredData, searchQuery, activeFilter, navigate]);

  const handleFloatingButtonData = ([apiData]: any) => {
    if (apiData?.subCategories?.length === 0) {
      return [];
    }

    const secondLevelData = apiData?.subCategories?.map((secondLevel: any) => ({
      name: secondLevel?.categoryName,
      helperData: secondLevel,
    }));

    return secondLevelData;
  };

  const handleClearFilter = () => {
    dispatch(handleSearchAndFilterReset());
  };

  return (
    <div className="py-3">
      {(categorizedMenuIsLoading ||
        categorizedMenuIsFetching ||
        orderIsLoading ||
        orderIsFetching) && (
          <CustomLoader
            isLoading={
              categorizedMenuIsLoading ||
              categorizedMenuIsFetching ||
              orderIsLoading ||
              orderIsFetching
            }
          />
        )}

      {categorizedMenuIsError && <ErrorMessage />}
      {categorizedMenuIsSuccess && categorizedMenuData && (
        <div>
          {mainCategory && mainCategory?.length > 0 && (
            <>
              {finalAccordionData &&
                finalAccordionData?.length > 0 &&
                finalAccordionData.map(
                  (data: any, index: number) =>
                    data && (
                      <div key={index} className="my-[10px]">
                        <SecondLevelCategoryAccordion
                          index={index}
                          accordionHeader={data.accordionHeader}
                          accordionHeaderCount={data.accordionHeaderCount}
                          isMainAccordionOpen={data.isMainAccordionOpen}
                          mainAccordionBody={data.mainAccordionBody}
                          isNestedAccordion={data.isNestedAccordion}
                          nestedAccordionBody={data.nestedAccordionBody}
                          helperData={data?.helperData}
                        />
                      </div>
                    )
                )}
              {finalAPIFilteredData &&
                Object.keys(finalAPIFilteredData)?.length > 0 && (
                  <div className="my-[10px]">
                    {handleDirectCardLevelData(
                      finalAPIFilteredData,
                      orderData,
                      setAddItemQuantity
                    )}
                  </div>
                )}
              {(finalAPIFilteredData?.[0] === undefined ||
                finalAccordionData === undefined) && (
                  <div className="flex justify-center items-center">
                    <ErrorMessage message="No Data Available!" />
                    {(activeFilter !== null || searchQuery?.trim() !== "") && (
                      <button
                        className="ml-2 text-lg font-semibold"
                        onClick={handleClearFilter}
                      >
                        Clear Filter
                      </button>
                    )}
                  </div>
                )}
              {floatingButtonData && floatingButtonData?.length > 0 && (
                <FloatingMenuListButton menuListData={floatingButtonData} />
              )}
            </>
          )}
          {!(categorizedMenuIsLoading || categorizedMenuIsFetching) &&
            categorizedMenuIsSuccess &&
            categorizedMenuData &&
            mainCategory &&
            !mainCategory?.length && (
              <div className="flex justify-center items-center">
                <ErrorMessage message="No Data Available!" />
                {(activeFilter !== null || searchQuery?.trim() !== "") && (
                  <button
                    className="ml-2 text-lg font-semibold"
                    onClick={handleClearFilter}
                  >
                    Clear Filter
                  </button>
                )}
              </div>
            )}
        </div>
      )}
    </div>
  );
};

export default MenuListingPage;
