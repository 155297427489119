import React from 'react'

type Props = {
    message?: string;
}

const ErrorMessage: React.FC<Props> = ({ message = "Something went wrong! Please try again." }) => {
    return (
        <div className='text-center errMessage py-4'>
            {message}
        </div>
    )
}

export default ErrorMessage