import { ReactComponent as WarningIcon } from 'assets/images/field-error.svg';
import { rupeeFormatter } from 'central-utils/currencyUtils';
import { handleFoodTypeImage } from "central-utils/foodTypeImageUtils";
import { convertToCapitalizedString } from 'central-utils/stringUtils';
import CustomLoader from 'components/common/CustomLoader';
import CounterButton from 'components/CounterButton';
import CustomToast from 'components/CustomToast';
import OrderItemsBanner from 'components/OrderItemsBanner';
import { usePriceChannel } from 'context/pusher/price';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { handleGlobalLoader } from 'store/auth/authSlice';
import { useAppDispatch, useAppSelector } from 'store/customHooks';
import { handleFooterHeight } from 'store/footer/footerSlice';
import { useAddMenuItemToOrderByOrderIdMutation, useGetMenuItemsForReorderByOrderIdQuery } from 'store/orders/ordersAPISlice';
import { IObject } from 'types';

// NOTE: STYLES NEEDS TO BE DIVIDED - JSON AND TAILWIND

const ReorderPage: React.FC = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const pusherPriceData = usePriceChannel();

    const refetchPage = useAppSelector((state) => state.refetchCounter.refetchReOrderAPIInReOrderPage);
    const initialAPICallSuccess = useAppSelector((state) => state.auth.initialAPISuccess);
    const initialOrderDetails = useAppSelector((state) => state.tableStatusAndOrder.orderDetails);
    const orderId = sessionStorage.getItem("OrderId");

    const {
        data: reOrderData,
        isLoading: reOrderIsLoading,
        isError: reOrderIsError,
        isSuccess: reOrderIsSuccess,
        isFetching: reOrderIsFetching,
        refetch: refetchReOrderData,
    } = useGetMenuItemsForReorderByOrderIdQuery(
        { orderId: initialOrderDetails?.id ?? Number(orderId) },
        { skip: !initialAPICallSuccess }
    );

    const [addMenuItemToOrder] = useAddMenuItemToOrderByOrderIdMutation();

    useEffect(() => {
        if (refetchPage !== 0) {
            refetchReOrderData({ id: initialOrderDetails?.id ?? orderId })
        }
    }, [refetchPage, initialOrderDetails?.id, orderId, refetchReOrderData]);

    // Func that handles Formatting of Reorder API Data
    const handleReOrderData = (apiData: any) => {
        let processedDetails: any = apiData
            ?.filter((item: any) => item?.status !== "NEW")
            ?.map((item: any) => {
                let description = "";

                if (item?.instructions?.length) {
                    const instructionString = item?.instructions?.join(", ");
                    description += description !== "" ? `, ${instructionString}` : instructionString;
                }

                if (item?.modifiers?.length) {
                    const modifierString = item?.modifiers?.map((mod: any) => mod?.modifier?.displayName ?? mod?.modifier?.itemName).join(", ");
                    description += description !== "" ? `, Served with ${modifierString}` : `Served with ${modifierString}`;
                }

                return {
                    id: item?.id,
                    displayName: item?.menuItemMaster?.displayName,
                    foodType: item?.menuItemMaster?.foodType,
                    servingSize: item?.menuItemMaster?.productVariant?.size?.size,
                    unitPrice: (item?.price / item?.quantity),  // Calculate unit price per item
                    quantity: 0,  // Initial quantity is zero
                    description: description,
                    helperData: item
                }
            });

        return processedDetails;
    }

    const [processedReOrderData, setProcessedReOrderData] = useState<IObject[]>([]);
    const [menuItemsToBeOrdered, setMenuItemsToBeOrdered] = useState<{ menuItem: IObject, quantity: number, totalPrice: number }[]>([]);
    const [updateMenuItemPrice, setUpdateMenuItemPrice] = useState<boolean>(false);
    const [toastMessage, setToastMessage] = useState<string | null>(null);
    const [noReorderDataMessage, setNoReorderDataMessage] = useState<string | null>(null);

    // Error Handling
    useEffect(() => {
        if (reOrderIsError) {
            dispatch(handleFooterHeight(70));
            setToastMessage("Oops! There was an error. Please try again.");
        }
    }, [dispatch, reOrderIsError]);

    // Formatting of Reorder API
    useEffect(() => {
        if (reOrderIsSuccess && reOrderData) {
            const transformedData = Object.keys(reOrderData.categorizedItems).map(category => ({
                categoryName: category,
                menuResponseDetails: handleReOrderData(reOrderData.categorizedItems[category]),
            })).sort((a, b) => a.categoryName.localeCompare(b.categoryName));
            if (!transformedData?.length) {
                setNoReorderDataMessage("Please place the order and confirm it!");
            }
            setProcessedReOrderData(transformedData);

            // Initialize the menuItemsToBeOrdered with 0 quantities and calculated total prices
            const initialMenuItems = transformedData.flatMap((categoryData: any) =>
                categoryData.menuResponseDetails.map((menuItem: any) => ({
                    menuItem,
                    quantity: 0,
                    totalPrice: menuItem?.unitPrice,
                }))
            );

            setMenuItemsToBeOrdered(initialMenuItems);
        }
    }, [reOrderIsSuccess, reOrderData, dispatch]);


    // Handle quantity changes and update total price
    const handleOnQuantityChange = (quantity: number, menuItem: any) => {
        const unitPrice = menuItem.unitPrice;
        const newTotalPrice = quantity === 0 ? unitPrice : quantity * unitPrice;  // Calculate the total price

        setMenuItemsToBeOrdered(prevMenuItems => {
            const existingItem = prevMenuItems.find(item => item.menuItem.id === menuItem.id);
            if (existingItem) {
                return prevMenuItems.map(item =>
                    item.menuItem.id === menuItem.id
                        ? { ...item, quantity, totalPrice: newTotalPrice }
                        : item
                );
            } else {
                return [...prevMenuItems, { menuItem, quantity, totalPrice: newTotalPrice }];
            }
        });
    };

    // API Call and Redirection to Order Page on Success
    const handleConfirmOrder = async () => {
        dispatch(handleGlobalLoader(true));

        // Filter the menu items that have a quantity greater than zero
        let initialFiltering = menuItemsToBeOrdered
            ?.map((obj: any) => obj.quantity !== 0 && { ...obj?.menuItem?.helperData, updatedQuantity: obj.quantity })
            ?.filter(item => item !== false);

        // If there are no items with quantity greater than zero, show a toast error message and return
        if (!initialFiltering?.length) {
            dispatch(handleGlobalLoader(false)); // Hide global loader if no items
            setToastMessage("Please increase the quantity of the item!");  // Show custom toast
            dispatch(handleFooterHeight(130));
            return;
        }

        // Process each filtered item and handle the API call
        try {
            for (const obj of initialFiltering) {
                let payload: any = {
                    menuItemMaster: {
                        id: obj?.menuItemMaster?.id
                    },
                    quantity: obj?.updatedQuantity,
                    price: (obj?.price / obj?.quantity) * obj?.updatedQuantity
                };

                // Add instructions if they exist
                if (obj?.instructions?.length) {
                    payload.instructions = obj?.instructions;
                }

                // Add modifiers if they exist
                if (obj?.modifiers?.length) {
                    payload.modifiers = obj?.modifiers.map((modifier: { id: number }) => ({ id: modifier.id }));
                }

                // Call the API and handle the response
                const res = await addMenuItemToOrder({
                    orderId,
                    menuItemsBody: payload
                });

                if (res?.data) {
                    setMenuItemsToBeOrdered([]);
                    navigate("/order", { state: { previousRoute: "reorder" } });
                } else {
                    const errMessage = res?.error?.data?.message ??
                        res?.error?.error ??
                        res?.error?.data?.error ??
                        "Something went wrong while updating the quantity. Please try again!";
                    setToastMessage(errMessage);
                    dispatch(handleFooterHeight(130));
                    refetchReOrderData();
                    errMessage === "Order is not in NEW status. Cannot add or update items." && navigate("/payment-in-progress-block");
                }
            }
        } catch (error) {
            // Handle unexpected errors
            console.error('Error during order confirmation: ', error);
        } finally {
            dispatch(handleGlobalLoader(false));  // Hide global loader
        }
    };

    // Price Update Pusher
    useEffect(() => {
        if (
            pusherPriceData &&
            Object.keys(pusherPriceData ?? {})?.length &&
            Object.hasOwn(pusherPriceData, "isPriceUpdateFlag")
        ) {
            setUpdateMenuItemPrice(pusherPriceData?.isPriceUpdateFlag);
        }

        // Set a timer to reset the updateMenuItemPrice after 3 seconds
        const timer = setTimeout(() => {
            setUpdateMenuItemPrice(false);
        }, 3000);

        // Clean up the timer when the component unmounts or when pusherPriceData changes
        return () => clearTimeout(timer);
    }, [pusherPriceData]);

    // Clear The Toast Message when the toast is removed
    const handleOnToastDismiss = () => {
        dispatch(handleFooterHeight(70));
        setToastMessage(null);
    }

    // Render the toast message if available
    const renderToast = () => {
        if (toastMessage) {
            return (
                <CustomToast
                    message={toastMessage}
                    handleOnToastDismiss={handleOnToastDismiss}
                />
            );
        }
        return null;
    };

    // Render a Message when there's no order placed yet
    const renderNoReorderData = () => {
        if (noReorderDataMessage) {
            return (
                <div className="flex flex-col items-center justify-center h-full text-[#571246] text-[16px] font-normal leading-[24px]">
                    <WarningIcon width={32} height={42} className='mb-[10px]' />
                    <div>{noReorderDataMessage}</div>
                </div>
            );
        }
        return null;
    };

    return (
        <>
            {
                (reOrderIsLoading || reOrderIsFetching) &&
                <CustomLoader isLoading={reOrderIsLoading || reOrderIsFetching} />
            }

            {
                reOrderIsSuccess &&
                reOrderData &&
                <div>
                    {
                        (processedReOrderData &&
                            processedReOrderData.length) ?
                            processedReOrderData.map((reorderItem: any) => {
                                return (
                                    <div key={"a" + reorderItem?.id} className='last:mb-[70px]'>
                                        <div className='text-[#1C1C1C] font-semibold text-[16px] first:mt-[10px] py-[10px]'>
                                            {convertToCapitalizedString(reorderItem?.categoryName)}
                                        </div>
                                        <div className='bg-white shadow-[0_0_10px_rgba(0,0,0,0.15)] rounded-[5px] text-[#0D0D0D] text-[14px] font-normal'>
                                            {reorderItem?.menuResponseDetails?.map((orderItem: any) => {
                                                const { id, foodType, displayName, servingSize, description, unitPrice } = orderItem;
                                                const foodTypeImage = handleFoodTypeImage(foodType);
                                                const itemToOrder = menuItemsToBeOrdered.find(item => item.menuItem.id === orderItem.id);
                                                const totalPrice = itemToOrder ? itemToOrder.totalPrice : unitPrice;

                                                return (
                                                    <div key={"b" + id} className='w-full flex justify-between items-center py-[15px] border-[1px] border-[#F5F2F2] last:border-none'>
                                                        <div className='w-1/2'>
                                                            <div className='ml-[10px] flex items-center'>
                                                                {foodTypeImage && (
                                                                    <img
                                                                        className='w-[10px] h-[10px] mr-[10px]'
                                                                        src={foodTypeImage}
                                                                        alt={foodType || 'Food type image'}
                                                                    />
                                                                )}
                                                                <span>
                                                                    {convertToCapitalizedString(displayName)}
                                                                    <span className='whitespace-nowrap'>{` (${servingSize})`}</span>
                                                                </span>
                                                            </div>
                                                            {description && <p className={`text-[#0D0D0D] opacity-50 text-[14px] font-normal break-words mt-1 ${foodTypeImage ? "ml-[30px]" : "ml-[10px]"}`}>
                                                                {convertToCapitalizedString(description)}
                                                            </p>}
                                                        </div>
                                                        <div className='flex items-center'>
                                                            <CounterButton
                                                                buttonType='SMALL'
                                                                initialQuantity={0}
                                                                onQuantityChange={(quantity) => (handleOnQuantityChange(quantity, orderItem))}
                                                            />
                                                            <div className='text-[#0D0D0D] text-[14px] font-normal ml-[10px] max-w-[60px] w-[60px] text-left break-words'>
                                                                {rupeeFormatter(totalPrice)}
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                );
                            }) : ""
                    }
                    {
                        reOrderData !== undefined &&
                        processedReOrderData &&
                        processedReOrderData?.length > 0 &&
                        <div className='flex items-center justify-center'>
                            <button
                                onClick={() => handleConfirmOrder()}
                                className="fixed bottom-[68px] h-[60px] w-full text-center bg-[#571246] text-white text-[16px] leading-[19.2px] font-semibold z-10"
                                style={{ left: "50%", transform: "translateX(-50%)" }}
                            >
                                Add to Cart
                            </button>
                        </div>
                    }
                    {
                        updateMenuItemPrice &&
                        <OrderItemsBanner
                            bannerType='PRICE_ALERT'
                        />
                    }
                </div>
            }
            {renderNoReorderData()}
            {renderToast()}
        </>
    );
}

export default ReorderPage;