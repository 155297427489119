import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    globalStyleConfigModule: 'bobsBar',
    globalStyleSheet:null
}
const styleConfigSlice=createSlice({
    name: 'setupTesting',
    initialState,
    reducers: {
        GlobalStyleConfigModuleAction: (state,action) => {
            state.globalStyleConfigModule=action.payload
        },
        GlobalStyleSheetSetup: (state, action) => {
            state.globalStyleSheet=action.payload
        }
    },
})
export default styleConfigSlice.reducer
export const { GlobalStyleConfigModuleAction,GlobalStyleSheetSetup } = styleConfigSlice.actions