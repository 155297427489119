import { ConfigProvider } from "antd";
import "App.css";
import CustomLoader from "components/common/CustomLoader";
import React, { useEffect } from "react";
import RouteCenter from "routes";
import { useAppDispatch, useAppSelector } from "store/customHooks";
import { GlobalStyleSheetSetup } from "store/styleConfigSetup";
import { bobsbarDynamicCss } from "styles/bobsbar";
import "styles/index.scss";

const App: React.FC = () => {
  const dispatch = useAppDispatch();

  const globalStyleConfigModule = useAppSelector(
    (state) => state.styleConfig.globalStyleConfigModule
  );

  const globalStyleSheet = useAppSelector(
    (state) => state.styleConfig.globalStyleSheet
  );

  const globalLoader = useAppSelector((state) => state.auth.globalLoader)

  useEffect(() => {
    switch (globalStyleConfigModule) {
      case "bobsBar":
        dispatch(GlobalStyleSheetSetup(bobsbarDynamicCss));
        break;
    }
  }, [dispatch, globalStyleConfigModule]);

  return (
    <React.Fragment>
      <ConfigProvider
        theme={{
          token: {
            fontFamily: "Roboto Condensed, sans-serif"
          },
        }}
      >
        {globalLoader && (
          <CustomLoader isLoading={globalLoader} />
        )}
        <style>{globalStyleSheet}</style>
        <div id={globalStyleConfigModule} className="typography">
          <RouteCenter />
        </div>
      </ConfigProvider>
    </React.Fragment>
  );
}

export default App;
