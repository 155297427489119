import { apiSlice } from "store/api/apiSlice";

export const initialAPISlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getTenantById: builder.query({
      query: ({ brandId }) => ({
        url: `/tenant?id=${brandId}`,
        method: "GET",
      }),
      keepUnusedDataFor: 0,
    }),
    getBranchById: builder.query({
      query: ({ branchId }) => ({
        url: `/branch?id=${branchId}`,
        method: "GET",
      }),
      keepUnusedDataFor: 0,
    }),
    getTableStatusByBranchIdAndTableId: builder.query({
      query: ({ branchId, tableId }) => ({
        url: `/order/get-table-order?branchId=${branchId}&tableId=${tableId}`,
        method: "GET",
      }),
      keepUnusedDataFor: 0,
    }),
  }),
});
export const {
  useGetTenantByIdQuery,
  useGetBranchByIdQuery,
  useGetTableStatusByBranchIdAndTableIdQuery,
} = initialAPISlice;
