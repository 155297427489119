export const transferSessionToLocalExceptTokens = () => {
  for (let i = 0; i < sessionStorage.length; i++) {
    const key = sessionStorage.key(i);
    if (key && key !== "refreshToken" && key !== "accessToken") {
      const value = sessionStorage.getItem(key);
      if (value) {
        localStorage.setItem(key, value);
      }
    }
  }
};

export const transferLocalToSessionAndClear = () => {
  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i);
    if (key) {
      const value = localStorage.getItem(key);
      if (value) {
        sessionStorage.setItem(key, value);
      }
    }
  }

  // Now clear localStorage
  localStorage.clear();
};
