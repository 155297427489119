import backArrow from "assets/images/arrow-back.svg";
import { useNavigate, useParams } from "react-router-dom";

const TermsAndConditionsPage = () => {
  const navigate = useNavigate();
  const params = useParams();
  return (
    <div className="flex flex-col gap-4 p-5">
      <div className="flex flex-row gap-2" onClick={() => navigate(-1)}>
        <img src={backArrow} alt="backArrow" />
        <span className="text-[#571246] font-bold underline decoration-[#571246] text-md">
          BACK
        </span>
      </div>
      {params?.type?.toLowerCase() === "conditions" ? (
        <div className="text-black ">
          <span className="font-bold pb-5 text-lg">Terms & Conditions</span>
          <div>
            <br />
            <span className="font-bold"> Data Collection and Usage:</span>
            <br />
            By entering the bar, you consent to the collection and use of your
            personal information, including:
            <br />
            - Phone number
            <br />
            - Name
            <br />
            - Payment details
            <br />
            <br />
            This information will be used for:
            <br />
            - Order processing and payment verification
            <br />
            - Marketing and promotional purposes (optional)
            <br />
            - Compliance with legal and regulatory requirements
            <br />
            <br />
            We will protect your information in accordance with applicable data
            protection laws.
            <br />
            <br />
            <span className="font-bold">
              By entering the bar, you acknowledge and agree to abide by the
              below mentioned terms.
            </span>
            <br />
            Notice: Rights of Admission Reserved
            <br />
            <br />
            <span className="font-semibold">Dress Code</span>: Smart casual
            attire required. No shorts or slippers allowed.
            <br />
            <span className="font-semibold">Age Restriction</span>: Drinks will
            only be served to patrons 21 years and above. Valid ID proof
            required for age verification at entry.
            <br />
            <span className="font-semibold">Outside Food and Drinks</span>: Not
            permitted.
            <br />
            <span className="font-semibold">Responsibility</span>: Management
            not liable for loss or theft of personal belongings.
            <br />
            <span className="font-semibold">Operating Hours</span>: 11:30 am to
            12:45 am. Food and beverage services available within this time
            frame.
            <br />
            <span className="font-semibold">Pricing</span>: Selling price of
            each item clearly stated at the time of ordering. Government taxes
            and 7.5% service charge are extra.
            <br />
            <span className="font-semibold">Prohibited Items</span>: Arms,
            ammunition, narcotic drugs, and psychotropic substances strictly not
            allowed on premises.
            <br />
            <span className="font-semibold">Code of Conduct</span>: No violence
            or disturbance will be tolerated. Any damage to bar property will be
            charged to the responsible patron.
            <br />
            <span className="font-semibold">Drink Responsibly</span>: Please
            consume alcohol responsibly.
            <br />
            <span className="font-semibold">Dispute Resolution</span>: Any
            disputes or claims subject to local law and jurisdiction.
            <br />
            <br />
            Please note that these terms and conditions are in place to ensure a
            safe and enjoyable experience for all patrons.
            <br />
            <br /> By entering the bar, you acknowledge and agree to abide by
            these terms.
          </div>
        </div>
      ) : (
        <div className="text-black ">
          <div className="font-bold pb-5 text-lg">Service Charges</div>
          <div>
            A standard service charge of 7.5% is applied to all orders. This
            charge is levied to support the dedicated team members who strive to
            provide a high standard of service and ensure a seamless experience
            for our valued guests.
            <br />
            <br />
            The service charge is automatically added to the bill for each menu
            item or service provided. Please note that this fee is distinct from
            government taxes and is applied consistently across all menu items
            and services.
            <br />
            <br />
            We appreciate your understanding, cooperation and support. Should
            you have any questions regarding this policy, our team will be happy
            to assist.
          </div>
        </div>
      )}
    </div>
  );
};

export default TermsAndConditionsPage;
