import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type MenuCustomizationOptions = { [key: string]: any };

interface MenuCustomizationState {
  selectedMenuCustomizationOptions: MenuCustomizationOptions;
  menuCustomizationOptions: MenuCustomizationOptions;
  menuItemsBannerType: "ADD" | "EDIT" | null;
  menuItemsBannerDisplay: boolean;
  menuItemsBannerQuantities: number;
}

const initialState: MenuCustomizationState = {
  selectedMenuCustomizationOptions: {},
  menuCustomizationOptions: {},
  menuItemsBannerType: null,
  menuItemsBannerDisplay: false,
  menuItemsBannerQuantities: 0,
};

const menuCustomizationSlice = createSlice({
  name: "menuCustomization",
  initialState,
  reducers: {
    handleMenuCustomizations: (
      state,
      action: PayloadAction<{
        fieldName: string;
        options: MenuCustomizationOptions[];
      }>
    ) => {
      const { fieldName, options } = action.payload;
      // Ensure fieldName exists in menuCustomizationOptions
      if (!state.menuCustomizationOptions[fieldName]) {
        state.menuCustomizationOptions[fieldName] = [];
      }

      // Update existing options or add new ones
      options.forEach((newOption) => {
        const existingIndex = state.menuCustomizationOptions[
          fieldName
        ].findIndex(
          (existingOption: { id: number }) => existingOption.id === newOption.id
        );

        if (existingIndex !== -1) {
          // Update existing option
          state.menuCustomizationOptions[fieldName][existingIndex] = newOption;
        } else {
          // Add new option
          state.menuCustomizationOptions[fieldName].push(newOption);
        }
      });

      // Filter options for the given fieldName
      const filterTrueOptions = state.menuCustomizationOptions[
        fieldName
      ].filter((checkedItem: any) => checkedItem.checked);

      // Update selectedMenuCustomizationOptions
      state.selectedMenuCustomizationOptions[fieldName] = filterTrueOptions;
    },
    handleClearMenuCustomizations: (state) => {
      state.menuCustomizationOptions = {};
      state.selectedMenuCustomizationOptions = {};
    },
    handleMenuItemsBannerType: (
      state,
      action: PayloadAction<"ADD" | "EDIT" | null>
    ) => {
      state.menuItemsBannerType = action.payload;
    },
    handleMenuItemBannerDisplay: (state, action: PayloadAction<boolean>) => {
      state.menuItemsBannerDisplay = action.payload;
    },
    handleMenuItemsBannerQuantities: (state, action: PayloadAction<number>) => {
      state.menuItemsBannerQuantities = action.payload;
    },
  },
});

export default menuCustomizationSlice.reducer;
export const {
  handleMenuCustomizations,
  handleClearMenuCustomizations,
  handleMenuItemsBannerType,
  handleMenuItemBannerDisplay,
  handleMenuItemsBannerQuantities,
} = menuCustomizationSlice.actions;

export const getMenuCustomizationRelatedStates = (state: {
  menuCustomization: MenuCustomizationOptions;
}) => state.menuCustomization;
