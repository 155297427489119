import JollyGunjLogo from "assets/images/jolly-gunj-default-header.svg";
import ProfileIcon from "assets/images/profile.svg";
import SearchIcon from "assets/images/search.svg";
import UserProfileModal from "components/header/UserProfileModal";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppSelector } from "store/customHooks";

// NOTE: STYLES NEEDS TO BE DIVIDED - JSON AND TAILWIND

const DefaultHeaderContent: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const branchData = useAppSelector((state) => state.auth.branch);
  const tableInfo = useAppSelector(
    (state) => state.tableStatusAndOrder.tableInfo
  );
  const customerDetails = useAppSelector(
    (state) => state.tableStatusAndOrder.customerDetails
  );

  const checkIn = sessionStorage.getItem("CheckIn");
  const branchSessionStorageData = JSON.parse(
    sessionStorage.getItem("Branch") || "{}"
  );

  const [showCustomerDetails, setShowCustomerDetails] = useState(false);

  useEffect(() => {
    if (!checkIn) {
      window.location.href = "/";
    }
  }, [checkIn]);

  useEffect(() => {
    setShowCustomerDetails(false);
  }, [location]);

  return (
    <div className="bg-[#571246]">
      <div className="flex justify-between relative items-center h-[62px] bg-[#571246]">
        <div className="flex">
          <img
            alt="jolly-gunj"
            src={JollyGunjLogo}
            className="mx-[20px]"
            onClick={() => navigate("/home")}
          />
          <div>
            <div className="text-[10px] text-white font-normal capitalize">
              <span>
                {branchData?.displayName ||
                  branchSessionStorageData?.displayName}
                :
              </span>
              <span className="ml-[5px]">
                TABLE {tableInfo?.tableNames?.join(", ")}
              </span>
            </div>
            <button className="bg-[#F3C736] text-[12px] font-bold p-[2px] rounded-[2px]">
              <span>PIN </span>
              <span>{tableInfo?.tableCode ?? customerDetails?.tableCode}</span>
            </button>
          </div>
        </div>
        <div className="flex">
          <img
            src={SearchIcon}
            alt="search-icon"
            className="mr-[33px]"
            onClick={() => navigate("/search")}
          />
          <img
            src={ProfileIcon}
            alt="profile-icon"
            className={`mr-[27px] ${showCustomerDetails && "z-[9999]"}`}
            onClick={() => setShowCustomerDetails(!showCustomerDetails)}
          />
        </div>
      </div>
      {showCustomerDetails && (
        <UserProfileModal handleClose={() => setShowCustomerDetails(false)} />
      )}
    </div>
  );
};

export default DefaultHeaderContent;
