import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface PromotionBannerState {
  showBigPromotionBanner: boolean;
}

const initialState: PromotionBannerState = {
  showBigPromotionBanner: true,
};

const promotionBannerSlice = createSlice({
  name: "promotionBanner",
  initialState,
  reducers: {
    handleBigPromotionBannerState(state, action: PayloadAction<boolean>) {
      state.showBigPromotionBanner = action.payload;
    },
  },
});

export const { handleBigPromotionBannerState } = promotionBannerSlice.actions;

export const getPromotionBannerRelatedStates = (state: {
  promotionBanner: PromotionBannerState;
}) => state.promotionBanner;

export default promotionBannerSlice.reducer;
