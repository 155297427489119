import downArrow from "assets/images/down-arrow.svg";
import upArrow from "assets/images/up-arrow.svg";
import React, { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "store/customHooks";
import { handleTabToggleAccordionStates } from "store/globalSearchAndFilters/globalSearchAndFiltersSlice";
import { IObject } from "types";
import { resetFloatingMenuClick } from "store/floatingButton/floatingButtonSlice";

interface NestedAccordionProps {
  accordionHeader: string;
  accordionHeaderCount: number;
  isAccordionOpen?: boolean;
  accordionBody?: JSX.Element | JSX.Element[];
}

interface Props {
  index: number;
  accordionHeader: string;
  accordionHeaderCount: number;
  isMainAccordionOpen?: boolean;
  mainAccordionBody?: JSX.Element | JSX.Element[];
  isNestedAccordion?: boolean;
  nestedAccordionBody?: NestedAccordionProps[];
  helperData: IObject;
}

// NOTE: STYLES NEEDS TO BE DIVIDED - JSON AND TAILWIND

const SecondLevelCategoryAccordion: React.FC<Props> = ({
  index,
  accordionHeader,
  accordionHeaderCount,
  isMainAccordionOpen = false,
  mainAccordionBody,
  isNestedAccordion = false,
  nestedAccordionBody = [],
  helperData,
}) => {
  const SCROLL_OFFSET = 80; // Adjust this value based on your header height
  const dispatch = useAppDispatch();

  const scrollToElement = (element: HTMLElement | null) => {
    if (element) {
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition =
        elementPosition + window.pageYOffset - SCROLL_OFFSET;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  };

  const floatingMenuItemClicked = useAppSelector(
    (state) => state.floatingButton.menuItemClicked
  );
  const { label: selectedTabLabel } = useAppSelector(
    (state) => state.tab.selectedTabDetails.homePageHeaderTabs
  );

  const isAccordionOpenStore = useAppSelector((state) => {
    if (selectedTabLabel.toLowerCase() === "drink") {
      return state.globalSearchAndFilters.drinkTabAccordionState[index];
    } else if (selectedTabLabel.toLowerCase() === "eat") {
      return state.globalSearchAndFilters.eatTabAccordionState[index];
    } else {
      return state.globalSearchAndFilters.smokeTabAccordionState[index];
    }
  });

  const [manualToggle, setManualToggle] = useState(false);

  const accordionRef = useRef<HTMLDivElement>(null);
  const nestedAccordionRefs = useRef<any>([]); // Array of refs for subcategories

  // Handle toggling of the accordion manually
  const handleAccordionToggle = () => {
    setManualToggle(true); // Mark as a manual toggle
    dispatch(
      handleTabToggleAccordionStates({
        value: !isAccordionOpenStore,
        index: index,
        tabType: selectedTabLabel.trim().toUpperCase(),
      })
    );
  };

  useEffect(() => {
    if (floatingMenuItemClicked && helperData && !manualToggle) {
      // Check if the clicked item is a main category
      const isMainCategoryMatch =
        floatingMenuItemClicked?.helperData?.id === helperData?.id &&
        floatingMenuItemClicked?.helperData?.categoryName ===
        helperData?.categoryName;

      // Check if the clicked item is a subcategory by comparing its id with helperData.subCategories ids
      const subCategoryIndex = helperData?.subCategories?.findIndex(
        (subCategory: any) => subCategory?.id === floatingMenuItemClicked?.id
      );

      // Handle main category click
      if (isMainCategoryMatch && accordionRef.current) {
        scrollToElement(accordionRef.current);
        dispatch(
          handleTabToggleAccordionStates({
            value: true,
            index: index,
            tabType: selectedTabLabel.trim().toUpperCase(),
          })
        );

        // Reset floating menu click after scrolling
        dispatch(resetFloatingMenuClick());
      }

      // Handle subcategory click
      if (subCategoryIndex !== -1) {
        // If the parent accordion is closed, open it
        if (!isAccordionOpenStore) {
          dispatch(
            handleTabToggleAccordionStates({
              value: true,
              index: index,
              tabType: selectedTabLabel.trim().toUpperCase(),
            })
          );
        }

        // After ensuring the parent accordion is open, scroll to the subcategory
        setTimeout(() => {
          if (nestedAccordionRefs.current[subCategoryIndex]) {
            scrollToElement(nestedAccordionRefs.current[subCategoryIndex]);
          }

          // Reset floating menu click after scrolling
          dispatch(resetFloatingMenuClick());
        }, 300); // Delay the scroll to ensure the parent accordion opens first
      }
    }
  }, [dispatch, floatingMenuItemClicked, helperData, index, isAccordionOpenStore, manualToggle, selectedTabLabel]);

  // Reset manual toggle when switching categories
  useEffect(() => {
    if (floatingMenuItemClicked?.helperData?.id !== helperData.id) {
      setManualToggle(false);
    }
  }, [floatingMenuItemClicked, helperData.id]);

  return (
    <div ref={accordionRef} className="secondLevelCategoryAccordion">
      <div
        onClick={handleAccordionToggle}
        className="w-full mainAccordionHeader px-4 py-3 flex gap-3 items-center cursor-pointer"
      >
        <div>
          {isAccordionOpenStore ? (
            <img src={upArrow} alt="upArrow" />
          ) : (
            <img src={downArrow} alt="downArrow" />
          )}
        </div>
        <div
          className={`mainAccordianTitle ${isAccordionOpenStore ? `font-bold` : `font-[500]`
            }`}
        >
          {accordionHeader} ({accordionHeaderCount})
        </div>
      </div>
      {isAccordionOpenStore && !isNestedAccordion && (
        <div className="mt-1">{mainAccordionBody}</div>
      )}
      {isAccordionOpenStore &&
        isNestedAccordion &&
        nestedAccordionBody &&
        nestedAccordionBody.length > 0 && (
          <div className="nestedAccordionContainer">
            {nestedAccordionBody.map((nestedDetails, index) => (
              <div
                key={index}
                ref={(el) => (nestedAccordionRefs.current[index] = el)}
                className="nestedAccordionItem"
              >
                <div className="w-full nestedAccordionHeader px-3 flex justify-between items-center cursor-pointer">
                  <div className="nestedAccordianTitle">
                    {nestedDetails.accordionHeader} (
                    {nestedDetails.accordionHeaderCount})
                  </div>
                  <div>
                    {/* {nestedAccordionStates[index] ? (
                      <UpOutlined className="nestedIcon" />
                    ) : (
                      <DownOutlined className="nestedIcon" />
                    )} */}
                  </div>
                </div>
                {/* {nestedAccordionStates[index] && ( */}
                <div className="mt-1">
                  {nestedDetails.accordionBody ? (
                    <div>{nestedDetails.accordionBody}</div>
                  ) : (
                    <div>No content available</div>
                  )}
                </div>
                {/* )} */}
              </div>
            ))}
          </div>
        )}
    </div>
  );
};

export default SecondLevelCategoryAccordion;
