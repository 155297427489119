import fontAndColorInfo from "styles/variableJSON/fontAndColor.json";
import layoutInfo from "styles/variableJSON/layout.json";

export const bobsbarDynamicCss = `

.pageHeader {
    font-size: ${fontAndColorInfo.layout.pageHeader.fontSize};
    font-weight: ${layoutInfo.pageHeader.fontWeight};
    line-height: ${layoutInfo.pageHeader.lineHeight};
    letter-spacing: ${layoutInfo.pageHeader.letterSpacing};                
}

.errMessage {
    font-size: ${fontAndColorInfo.layout.errMessage.fontSize};
    color: ${fontAndColorInfo.layout.errMessage.color};   
    font-weight: ${layoutInfo.errMessage.fontWeight};
    line-height: ${layoutInfo.errMessage.lineHeight};
}

.fieldError {
    color: ${fontAndColorInfo.layout.fieldError.color}; 
    line-height: ${layoutInfo.fieldError.lineHeight}; 

    .fieldErrorIcon {
        font-size: ${fontAndColorInfo.layout.fieldError.fieldErrorIcon.fontSize}; 
        line-height: ${layoutInfo.fieldError.fieldErrorIcon.lineHeight}; 
    }

    .fieldErrorMessage {
        font-size: ${fontAndColorInfo.layout.fieldError.fieldErrorMessage.fontSize}; 
        line-height: ${layoutInfo.fieldError.fieldErrorMessage.lineHeight}; 
    }
}

.typography {
    color:${fontAndColorInfo.typography.commonFontColor};
}

@keyframes slide-up {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}


.welcomeUser {
    font-size:${fontAndColorInfo.layout.welcomeUser.fontSize};
    font-weight:${layoutInfo.welcomeUser.fontWeight};
    line-height: ${layoutInfo.welcomeUser.lineHeight};
    letter-spacing: ${layoutInfo.welcomeUser.letterSpacing};
}

.mainContentBackground {
    background: ${fontAndColorInfo.colors.mainContentBackgroundColor};
}

.headerNavbar {
    background: ${fontAndColorInfo.layout.header.headerBackgroundColor};
    min-height: ${layoutInfo.header.minHeight};
}

.fixHeader {
    z-index: 1000;               
} 

.defaultHeaderContentContainer {
    .tableInfoText {
        color: ${fontAndColorInfo.layout.defaultHeader.defaultHeaderContentContainer.tableInfoText.color};
        font-weight: ${layoutInfo.defaultHeader.defaultHeaderContentContainer.tableInfoText.fontWeight};
    }

    .tableNumber {
        font-weight: ${layoutInfo.defaultHeader.defaultHeaderContentContainer.tableNumber.fontWeight};
    }

    .verifyButton {
        background-color: ${fontAndColorInfo.layout.defaultHeader.defaultHeaderContentContainer.verifyButton.backgroundColor};
        color: ${fontAndColorInfo.layout.defaultHeader.defaultHeaderContentContainer.verifyButton.color};
        width: ${layoutInfo.defaultHeader.defaultHeaderContentContainer.verifyButton.width};
        // height: ${layoutInfo.defaultHeader.defaultHeaderContentContainer.verifyButton.height};
        border-radius: ${layoutInfo.defaultHeader.defaultHeaderContentContainer.verifyButton.borderRadius};
        font-weight: ${layoutInfo.defaultHeader.defaultHeaderContentContainer.verifyButton.fontWeight};
    }
}

.paymentInProgressMessage,
.paymentSuccessfulMessage {
    color: ${fontAndColorInfo.layout.paymentMessages.color};
    font-size: ${fontAndColorInfo.layout.paymentMessages.fontSize};
    line-height: ${layoutInfo.paymentMessages.lineHeight};
    letter-spacing: ${layoutInfo.paymentMessages.letterSpacing};
    font-weight: ${layoutInfo.paymentMessages.fontWeight};
}

.customerDetailsCard {
  background-color: ${fontAndColorInfo.layout.defaultHeader.customerDetailsCard.backgroundColor};
  border-color: ${fontAndColorInfo.layout.defaultHeader.customerDetailsCard.borderColor};
  border-width: ${layoutInfo.defaultHeader.customerDetailsCard.borderWidth};
  border-style: ${layoutInfo.defaultHeader.customerDetailsCard.borderStyle};
  border-radius: ${layoutInfo.defaultHeader.customerDetailsCard.borderRadius};
  box-shadow: ${layoutInfo.defaultHeader.customerDetailsCard.boxShadow};
  width: ${layoutInfo.defaultHeader.customerDetailsCard.width};

  .customerDetailsIcon {
    color: ${fontAndColorInfo.layout.defaultHeader.customerDetailsCard.customerDetailsIcon.color};
    font-size: ${fontAndColorInfo.layout.defaultHeader.customerDetailsCard.customerDetailsIcon.fontSize};
    line-height: ${layoutInfo.defaultHeader.customerDetailsCard.customerDetailsIcon.lineHeight};
  }

  .customerDetailsText {
    font-size: ${fontAndColorInfo.layout.defaultHeader.customerDetailsCard.customerDetailsText.fontSize};
    line-height: ${layoutInfo.defaultHeader.customerDetailsCard.customerDetailsText.lineHeight};
    letter-spacing: ${layoutInfo.defaultHeader.customerDetailsCard.customerDetailsText.letterSpacing};
    font-weight: ${layoutInfo.defaultHeader.customerDetailsCard.customerDetailsText.fontWeight};
  }
    
  .customerDetailsBorder {
    border-top-color: ${fontAndColorInfo.layout.defaultHeader.customerDetailsCard.customerDetailsBorder.borderTopColor};
    border-top-style: ${layoutInfo.defaultHeader.customerDetailsCard.customerDetailsBorder.borderTopStyle};
    border-top-width: ${layoutInfo.defaultHeader.customerDetailsCard.customerDetailsBorder.borderTopWidth};
  }
}

.searchSectionSearchField::placeholder {
    color:${fontAndColorInfo.typography.commonFontColor};
}

.chefsSpecialCardImage {
    width: ${layoutInfo.chefsSpecialCard.imageWidth} !important;
    height: ${layoutInfo.chefsSpecialCard.imageHeight} !important;
    border-radius:${layoutInfo.chefsSpecialCard.borderRadius} !important;
}

.chefsSpecialCardName {
    font-size:${fontAndColorInfo.layout.chefsSpecialCard.menuFontSize};
    font-weight:${layoutInfo.chefsSpecialCard.menuFontWeight};
}

.chefsSpecialCardPrice {
    font-size:${fontAndColorInfo.layout.chefsSpecialCard.priceFontSize};
    color:${fontAndColorInfo.layout.chefsSpecialCard.priceFontColor};
    font-weight:${layoutInfo.chefsSpecialCard.priceFontWeight};
}

.sectionHeader {
    color:${fontAndColorInfo.layout.sectionHeader.color};
    font-size:${fontAndColorInfo.layout.sectionHeader.fontSize};
    font-weight:${layoutInfo.sectionHeader.fontWeight};
}

.floatingMenuButton {
    color: ${fontAndColorInfo.layout.floatingMenuSection.buttonCss.color};
    background-color: ${fontAndColorInfo.layout.floatingMenuSection.buttonCss.backgroundColor};
    height: ${layoutInfo.floatingMenuSection.buttonCss.height};
    width: ${layoutInfo.floatingMenuSection.buttonCss.width};
}

.floatingMenuIcon {
    height: ${layoutInfo.floatingMenuSection.buttonCss.iconHeight};
    width: ${layoutInfo.floatingMenuSection.buttonCss.iconWidth};
}

.floatingMenuPopover {
    background-color: ${fontAndColorInfo.layout.floatingMenuSection.popoverCss.backgroundColor};
    color: ${fontAndColorInfo.layout.floatingMenuSection.popoverCss.color};
    border-color: ${fontAndColorInfo.layout.floatingMenuSection.popoverCss.borderColor};
    border-width: ${layoutInfo.floatingMenuSection.popoverCss.borderWidth};
    border-style: ${layoutInfo.floatingMenuSection.popoverCss.borderStyle};
    min-height: ${layoutInfo.floatingMenuSection.popoverCss.minHeight}; 
    max-height: ${layoutInfo.floatingMenuSection.popoverCss.maxHeight}; 
    width: ${layoutInfo.floatingMenuSection.popoverCss.width};
}

.floatingMenuPopover:after{
    content: "";
    bottom: 26%;
    position: absolute;
    left: 86%;
    right: 14%;
    margin: 0;
    width: 0;
    height: 0;
    border-top: 8px solid  ${fontAndColorInfo.layout.floatingMenuSection.popoverCss.backgroundColor};
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
}

.floatingMenuPopoverContent {
    border-bottom-color: ${fontAndColorInfo.layout.floatingMenuSection.popoverCss.popoverContentCss.borderBottomColor};
    border-bottom-width: ${layoutInfo.floatingMenuSection.popoverCss.popoverContentCss.borderBottomWidth};
    border-bottom-style: ${layoutInfo.floatingMenuSection.popoverCss.popoverContentCss.borderBottomStyle};
    

}

.orderInfoButton {
    background-color: ${fontAndColorInfo.layout.orderInformationSection.infoButtonCss.backgroundColor};
    width: ${layoutInfo.orderInformationSection.infoButtonCss.width};
    height: ${layoutInfo.orderInformationSection.infoButtonCss.height};
}

.orderInfoButtonIcon {
    font-size: ${fontAndColorInfo.layout.orderInformationSection.infoButtonCss.iconCss.fontSize};
    color: ${fontAndColorInfo.layout.orderInformationSection.infoButtonCss.iconCss.color};
}

.orderInfoCard {
    background-color: ${fontAndColorInfo.layout.orderInformationSection.infoCardCss.backgroundColor};
    border-color: ${fontAndColorInfo.layout.orderInformationSection.infoCardCss.borderColor};
    border-width: ${layoutInfo.orderInformationSection.infoCardCss.borderWidth};
    border-style: ${layoutInfo.orderInformationSection.infoCardCss.borderStyle};
    width: ${layoutInfo.orderInformationSection.infoCardCss.width};
    height: ${layoutInfo.orderInformationSection.infoCardCss.height};
}

.orderInfoCardText {
    font-size: ${fontAndColorInfo.layout.orderInformationSection.infoCardCss.infoTextCss.fontSize};
    font-weight: ${layoutInfo.orderInformationSection.infoCardCss.infoTextCss.fontWeight};
}

.orderInfoCardTable {
    width: ${layoutInfo.orderInformationSection.infoCardCss.infoTableCss.width};
    height: ${layoutInfo.orderInformationSection.infoCardCss.infoTableCss.height};
}

.orderInfoCardTableHeader {
   font-size: ${fontAndColorInfo.layout.orderInformationSection.infoCardCss.infoTableCss.tableHeaderCellCss.fontSize};
   border-right-color: ${fontAndColorInfo.layout.orderInformationSection.infoCardCss.infoTableCss.tableHeaderCellCss.borderRightColor};
   line-height: ${layoutInfo.orderInformationSection.infoCardCss.infoTableCss.tableHeaderCellCss.lineHeight};
   border-right-style: ${layoutInfo.orderInformationSection.infoCardCss.infoTableCss.tableHeaderCellCss.borderRightStyle};
   border-right-width: ${layoutInfo.orderInformationSection.infoCardCss.infoTableCss.tableHeaderCellCss.borderRightWidth};
}

.orderOpenIcon {
    color: ${fontAndColorInfo.layout.orderInformationSection.infoCardCss.infoTableCss.tableHeaderCellCss.openIconColor};
}

.orderInProcessIcon {
    color: ${fontAndColorInfo.layout.orderInformationSection.infoCardCss.infoTableCss.tableHeaderCellCss.inProcessIconColor};
}

.orderCompletedIcon {
    color: ${fontAndColorInfo.layout.orderInformationSection.infoCardCss.infoTableCss.tableHeaderCellCss.completedIconColor};
    border: none
}

.orderDetailsAccordion {
    background-color:${fontAndColorInfo.layout.orderDetailsAccordion.backgroundColor};
    border-radius: ${layoutInfo.orderDetailsAccordion.borderRadius};

    .orderAccordionHeader {

        .orderAccordionTitle {
            font-size: ${fontAndColorInfo.layout.orderDetailsAccordion.orderAccordionHeader.orderAccordionTitle.fontSize};
            font-weight: ${layoutInfo.orderDetailsAccordion.orderAccordionHeader.orderAccordionTitle.fontWeight};
        }

        .orderAccordionStatus {
            font-weight: ${layoutInfo.orderDetailsAccordion.orderAccordionHeader.orderAccordionStatus.fontWeight};
        }
    }

    .orderAccordionBody {

        .orderAccordionItem {

            .orderItemBorder {
                border-top-color: ${fontAndColorInfo.layout.orderDetailsAccordion.orderAccordionBody.orderAccordionItem.borderTopColor};
                border-top-width: ${layoutInfo.orderDetailsAccordion.orderAccordionBody.orderAccordionItem.borderTopWidth};
                border-top-style: ${layoutInfo.orderDetailsAccordion.orderAccordionBody.orderAccordionItem.borderTopStyle};
            }

            .orderItemDetails {

                .orderItemIcon {
                    font-size: ${fontAndColorInfo.layout.orderDetailsAccordion.orderAccordionBody.orderAccordionItem.orderItemDetails.orderItemIcon.fontSize};

                    &.loading {
                        color:${fontAndColorInfo.layout.orderDetailsAccordion.orderAccordionBody.orderAccordionItem.orderItemDetails.orderItemIcon.loading.color};
                        animation: ${layoutInfo.orderDetailsAccordion.orderAccordionBody.orderAccordionItem.orderItemDetails.orderItemIcon.loading.animation};
                    }

                    &.completed {
                        color: ${fontAndColorInfo.layout.orderDetailsAccordion.orderAccordionBody.orderAccordionItem.orderItemDetails.orderItemIcon.completed.color};
                    }

                    &.new {
                        color: ${fontAndColorInfo.layout.orderDetailsAccordion.orderAccordionBody.orderAccordionItem.orderItemDetails.orderItemIcon.new.color};
                    }
                }

                .orderItemInfo {
                    .orderItemName {
                        font-size: ${fontAndColorInfo.layout.orderDetailsAccordion.orderAccordionBody.orderAccordionItem.orderItemDetails.orderItemInfo.orderItemName.fontSize};
                        font-weight: ${layoutInfo.orderDetailsAccordion.orderAccordionBody.orderAccordionItem.orderItemDetails.orderItemInfo.orderItemName.fontWeight};
                        line-height: ${layoutInfo.orderDetailsAccordion.orderAccordionBody.orderAccordionItem.orderItemDetails.orderItemInfo.orderItemName.lineHeight};
                        letter-spacing: ${layoutInfo.orderDetailsAccordion.orderAccordionBody.orderAccordionItem.orderItemDetails.orderItemInfo.orderItemName.letterSpacing};
                    }

                    .orderItemDetail {
                        font-size: ${fontAndColorInfo.layout.orderDetailsAccordion.orderAccordionBody.orderAccordionItem.orderItemDetails.orderItemInfo.orderItemDetail.fontSize};
                        font-weight: ${layoutInfo.orderDetailsAccordion.orderAccordionBody.orderAccordionItem.orderItemDetails.orderItemInfo.orderItemDetail.fontWeight};
                        line-height: ${layoutInfo.orderDetailsAccordion.orderAccordionBody.orderAccordionItem.orderItemDetails.orderItemInfo.orderItemDetail.lineHeight};
                        letter-spacing: ${layoutInfo.orderDetailsAccordion.orderAccordionBody.orderAccordionItem.orderItemDetails.orderItemInfo.orderItemDetail.letterSpacing};
                    }
                }
            }

            .orderItemQuantity {
                font-size: ${fontAndColorInfo.layout.orderDetailsAccordion.orderAccordionBody.orderAccordionItem.orderItemQuantity.fontSize};
                font-weight: ${layoutInfo.orderDetailsAccordion.orderAccordionBody.orderAccordionItem.orderItemQuantity.fontWeight};
                line-height: ${layoutInfo.orderDetailsAccordion.orderAccordionBody.orderAccordionItem.orderItemQuantity.lineHeight};
                letter-spacing: ${layoutInfo.orderDetailsAccordion.orderAccordionBody.orderAccordionItem.orderItemQuantity.letterSpacing};
            }
        }

        .orderButton {
            background-color: ${fontAndColorInfo.layout.orderDetailsAccordion.orderAccordionBody.orderButton.backgroundColor};
            color: ${fontAndColorInfo.layout.orderDetailsAccordion.orderAccordionBody.orderButton.color};
            font-size: ${fontAndColorInfo.layout.orderDetailsAccordion.orderAccordionBody.orderButton.fontSize};
            font-weight: ${layoutInfo.orderDetailsAccordion.orderAccordionBody.orderButton.fontWeight};
            border-radius: ${layoutInfo.orderDetailsAccordion.orderAccordionBody.orderButton.borderRadius};
            width: ${layoutInfo.orderDetailsAccordion.orderAccordionBody.orderButton.width};
            height: ${layoutInfo.orderDetailsAccordion.orderAccordionBody.orderButton.height};
        }

        .orderMessage {
            color: ${fontAndColorInfo.layout.orderDetailsAccordion.orderAccordionBody.orderMessage.color};
            font-size: ${fontAndColorInfo.layout.orderDetailsAccordion.orderAccordionBody.orderMessage.fontSize};
            font-weight: ${layoutInfo.orderDetailsAccordion.orderAccordionBody.orderMessage.fontWeight};
            line-height: ${layoutInfo.orderDetailsAccordion.orderAccordionBody.orderMessage.lineHeight};
            letter-spacing: ${layoutInfo.orderDetailsAccordion.orderAccordionBody.orderMessage.letterSpacing};
        }
    }
}

.checkoutTable {

    .checkoutTableHeader {
        font-size: ${fontAndColorInfo.layout.checkoutSection.checkoutTable.checkoutTableHeader.fontSize};
        line-height: ${layoutInfo.checkoutSection.checkoutTable.checkoutTableHeader.lineHeight};
        letter-spacing: ${layoutInfo.checkoutSection.checkoutTable.checkoutTableHeader.letterSpacing};
        font-weight: ${layoutInfo.checkoutSection.checkoutTable.checkoutTableHeader.fontWeight};
    }

    .checkoutTableCell {
        font-size: ${fontAndColorInfo.layout.checkoutSection.checkoutTable.checkoutTableCell.fontSize};
        line-height: ${layoutInfo.checkoutSection.checkoutTable.checkoutTableCell.lineHeight};
        letter-spacing: ${layoutInfo.checkoutSection.checkoutTable.checkoutTableCell.letterSpacing};
    }

    .checkoutTableCellAmount {
        font-weight: ${layoutInfo.checkoutSection.checkoutTable.checkoutTableCellAmount.fontWeight};
    }

    .checkoutTableRow {
        border-bottom-color: ${fontAndColorInfo.layout.checkoutSection.checkoutTable.checkoutTableRow.borderBottomColor};
        border-bottom-width: ${layoutInfo.checkoutSection.checkoutTable.checkoutTableRow.borderBottomWidth};
        border-bottom-style: ${layoutInfo.checkoutSection.checkoutTable.checkoutTableRow.borderBottomStyle};
    }

    .checkoutTableFooter {
        .checkoutTableFooterTotal {
            color: ${fontAndColorInfo.layout.checkoutSection.checkoutTable.checkoutTableFooter.checkoutTableFooterTotal.color};
            font-size: ${fontAndColorInfo.layout.checkoutSection.checkoutTable.checkoutTableFooter.checkoutTableFooterTotal.fontSize};
            line-height: ${layoutInfo.checkoutSection.checkoutTable.checkoutTableFooter.checkoutTableFooterTotal.lineHeight};
            letter-spacing: ${layoutInfo.checkoutSection.checkoutTable.checkoutTableFooter.checkoutTableFooterTotal.letterSpacing};
            font-weight: ${layoutInfo.checkoutSection.checkoutTable.checkoutTableFooter.checkoutTableFooterTotal.fontWeight};
        }
    }
}

.checkoutBillDetails {
    background-color: ${fontAndColorInfo.layout.checkoutSection.checkoutBillDetails.backgroundColor};
    font-size: ${fontAndColorInfo.layout.checkoutSection.checkoutBillDetails.fontSize};
    line-height: ${layoutInfo.checkoutSection.checkoutBillDetails.lineHeight};
    letter-spacing: ${layoutInfo.checkoutSection.checkoutBillDetails.letterSpacing};
    font-weight: ${layoutInfo.checkoutSection.checkoutBillDetails.fontWeight};
    border-radius: ${layoutInfo.checkoutSection.checkoutBillDetails.borderRadius};

    &.checkoutBillDetailsOpen {
        height: ${layoutInfo.checkoutSection.checkoutBillDetails.checkoutBillDetailsOpen.height};
    }

    &.checkoutBillDetailsClosed {
        height: ${layoutInfo.checkoutSection.checkoutBillDetails.checkoutBillDetailsClosed.height};
    }

    .checkoutBillDetailsContent {
        background-color:${fontAndColorInfo.layout.checkoutSection.checkoutBillDetails.checkoutBillDetailsContent.backgroundColor};
        width: ${layoutInfo.checkoutSection.checkoutBillDetails.checkoutBillDetailsContent.width};
        border-radius: ${layoutInfo.checkoutSection.checkoutBillDetails.checkoutBillDetailsContent.borderRadius};

        .checkoutBillItemTotal {
            font-size: ${fontAndColorInfo.layout.checkoutSection.checkoutBillDetails.checkoutBillDetailsContent.checkoutBillItemTotal.fontSize};
            border-bottom-color: ${fontAndColorInfo.layout.checkoutSection.checkoutBillDetails.checkoutBillDetailsContent.checkoutBillItemTotal.borderBottomColor};
            border-bottom-style: ${layoutInfo.checkoutSection.checkoutBillDetails.checkoutBillDetailsContent.checkoutBillItemTotal.borderBottomStyle};
            border-bottom-width: ${layoutInfo.checkoutSection.checkoutBillDetails.checkoutBillDetailsContent.checkoutBillItemTotal.borderBottomWidth};
            line-height: ${layoutInfo.checkoutSection.checkoutBillDetails.checkoutBillDetailsContent.checkoutBillItemTotal.lineHeight};
            font-weight: ${layoutInfo.checkoutSection.checkoutBillDetails.checkoutBillDetailsContent.checkoutBillItemTotal.fontWeight};
            letter-spacing: ${layoutInfo.checkoutSection.checkoutBillDetails.checkoutBillDetailsContent.checkoutBillItemTotal.letterSpacing};
        }

        .checkoutBillItemDetails {
            font-size: ${fontAndColorInfo.layout.checkoutSection.checkoutBillDetails.checkoutBillDetailsContent.checkoutBillItemDetails.fontSize};
            border-bottom-color: ${fontAndColorInfo.layout.checkoutSection.checkoutBillDetails.checkoutBillDetailsContent.checkoutBillItemDetails.borderBottomColor};
            border-bottom-style: ${layoutInfo.checkoutSection.checkoutBillDetails.checkoutBillDetailsContent.checkoutBillItemDetails.borderBottomStyle};
            border-bottom-width: ${layoutInfo.checkoutSection.checkoutBillDetails.checkoutBillDetailsContent.checkoutBillItemDetails.borderBottomWidth};
            line-height: ${layoutInfo.checkoutSection.checkoutBillDetails.checkoutBillDetailsContent.checkoutBillItemDetails.lineHeight};
            font-weight: ${layoutInfo.checkoutSection.checkoutBillDetails.checkoutBillDetailsContent.checkoutBillItemDetails.fontWeight};
            letter-spacing: ${layoutInfo.checkoutSection.checkoutBillDetails.checkoutBillDetailsContent.checkoutBillItemDetails.letterSpacing};

            .checkoutBillNegated {
            color: ${fontAndColorInfo.layout.checkoutSection.checkoutBillDetails.checkoutBillDetailsContent.checkoutBillItemDetails.checkoutBillNegated.color};
            }
        }

        .checkoutBillPay {
            font-size: ${fontAndColorInfo.layout.checkoutSection.checkoutBillDetails.checkoutBillDetailsContent.checkoutBillPay.fontSize};
            line-height: ${layoutInfo.checkoutSection.checkoutBillDetails.checkoutBillDetailsContent.checkoutBillPay.lineHeight};
            font-weight: ${layoutInfo.checkoutSection.checkoutBillDetails.checkoutBillDetailsContent.checkoutBillPay.fontWeight};
            letter-spacing: ${layoutInfo.checkoutSection.checkoutBillDetails.checkoutBillDetailsContent.checkoutBillPay.letterSpacing};
        }
    }
}

.menuItemCustomizationCheckbox {

  .menuItemCustomizationMainHeader {
        font-size: ${fontAndColorInfo.layout.menuItemCustomizationCheckbox.menuItemCustomizationMainHeader.fontSize};
        line-height: ${layoutInfo.menuItemCustomizationCheckbox.menuItemCustomizationMainHeader.lineHeight};
        letter-spacing: ${layoutInfo.menuItemCustomizationCheckbox.menuItemCustomizationMainHeader.letterSpacing};
        font-weight: ${layoutInfo.menuItemCustomizationCheckbox.menuItemCustomizationMainHeader.fontWeight};
    }

  .menuItemOptionsContainer {
        /* background-color: ${fontAndColorInfo.layout.menuItemCustomizationCheckbox.menuItemOptionsContainer.backgroundColor}; */
       /* border-radius: ${layoutInfo.menuItemCustomizationCheckbox.menuItemOptionsContainer.borderRadius};*/
    }

  .menuItemBodyHeader {
        font-size: ${fontAndColorInfo.layout.menuItemCustomizationCheckbox.menuItemBodyHeader.fontSize};
        line-height: ${layoutInfo.menuItemCustomizationCheckbox.menuItemBodyHeader.lineHeight};
        font-weight: ${layoutInfo.menuItemCustomizationCheckbox.menuItemBodyHeader.fontWeight};
    }

  .menuItemOptionItem {
        .menuItemOptionLabel {
            font-size: ${fontAndColorInfo.layout.menuItemCustomizationCheckbox.menuItemOptionItem.menuItemOptionLabel.fontSize};
            line-height: ${layoutInfo.menuItemCustomizationCheckbox.menuItemOptionItem.menuItemOptionLabel.lineHeight};
        }

        .menuItemOptionPrice {
            color: ${fontAndColorInfo.layout.menuItemCustomizationCheckbox.menuItemOptionItem.menuItemOptionPrice.color};
            font-size: ${fontAndColorInfo.layout.menuItemCustomizationCheckbox.menuItemOptionItem.menuItemOptionPrice.fontSize};
            line-height: ${layoutInfo.menuItemCustomizationCheckbox.menuItemOptionItem.menuItemOptionPrice.lineHeight};
            letter-spacing: ${layoutInfo.menuItemCustomizationCheckbox.menuItemOptionItem.menuItemOptionPrice.letterSpacing};
            font-weight: ${layoutInfo.menuItemCustomizationCheckbox.menuItemOptionItem.menuItemOptionPrice.fontWeight};
        }
    }

  .menuItemShowMoreButton {
      color: ${fontAndColorInfo.layout.menuItemCustomizationCheckbox.menuItemShowMoreButton.color};
      font-size: ${fontAndColorInfo.layout.menuItemCustomizationCheckbox.menuItemShowMoreButton.fontWeight};
      line-height: ${layoutInfo.menuItemCustomizationCheckbox.menuItemShowMoreButton.lineHeight};
      letter-spacing: ${layoutInfo.menuItemCustomizationCheckbox.menuItemShowMoreButton.letterSpacing};
      font-weight: ${layoutInfo.menuItemCustomizationCheckbox.menuItemShowMoreButton.fontWeight};
    }
}


.menuCustomizationModalOverlay {
    background-color: ${fontAndColorInfo.layout.menuCustomizationModal.menuCustomizationModalOverlay.backgroundColor};
}

.menuCustomizationModalContainer {
    background-color: ${fontAndColorInfo.layout.menuCustomizationModal.menuCustomizationModalContainer.backgroundColor};
    border-radius: ${layoutInfo.menuCustomizationModal.menuCustomizationModalContainer.borderRadius};
    box-shadow: ${layoutInfo.menuCustomizationModal.menuCustomizationModalContainer.boxShadow};
    width: ${layoutInfo.menuCustomizationModal.menuCustomizationModalContainer.width};
    max-width: ${layoutInfo.menuCustomizationModal.menuCustomizationModalContainer.maxWidth};
    min-height: ${layoutInfo.menuCustomizationModal.menuCustomizationModalContainer.minHeight};
    max-height: ${layoutInfo.menuCustomizationModal.menuCustomizationModalContainer.maxHeight};
}

.menuCustomizationModalImage {
    width: ${layoutInfo.menuCustomizationModal.menuCustomizationModalImage.width};
}

.menuCustomizationModalHeader {
    font-size: ${fontAndColorInfo.layout.menuCustomizationModal.menuCustomizationModalHeader.fontSize};
    line-height: ${layoutInfo.menuCustomizationModal.menuCustomizationModalHeader.lineHeight};
    font-weight: ${layoutInfo.menuCustomizationModal.menuCustomizationModalHeader.fontWeight};
}

.menuCustomizationModalHeaderDetails {
    font-size: ${fontAndColorInfo.layout.menuCustomizationModal.menuCustomizationModalHeaderDetails.fontSize};
    color: ${fontAndColorInfo.layout.menuCustomizationModal.menuCustomizationModalHeaderDetails.color};
    line-height: ${layoutInfo.menuCustomizationModal.menuCustomizationModalHeaderDetails.lineHeight};
    font-weight: ${layoutInfo.menuCustomizationModal.menuCustomizationModalHeaderDetails.fontWeight};
}

.menuCustomizationModalDescription {
    font-size: ${fontAndColorInfo.layout.menuCustomizationModal.menuCustomizationDescription.fontSize};
    line-height: ${layoutInfo.menuCustomizationModal.menuCustomizationDescription.lineHeight};
    font-weight: ${layoutInfo.menuCustomizationModal.menuCustomizationDescription.fontWeight};
}

.menuCustomizationHeader {
    font-size: ${fontAndColorInfo.layout.menuCustomizationModal.menuCustomizationHeader.fontSize};
    line-height: ${layoutInfo.menuCustomizationModal.menuCustomizationHeader.lineHeight};
    font-weight: ${layoutInfo.menuCustomizationModal.menuCustomizationHeader.fontWeight};
}

.menuItemOptionItem{
padding:${layoutInfo.menuCustomizationModal.menuItemOptionItem.padding};
background-color:${fontAndColorInfo.layout.menuCustomizationModal.menuItemOptionItem.backgroundColor};
}

.menuCustomizationSubHeader {
    color: ${fontAndColorInfo.layout.menuCustomizationModal.menuCustomizationSubHeader.color};
    font-size: ${fontAndColorInfo.layout.menuCustomizationModal.menuCustomizationSubHeader.fontSize};
    line-height: ${layoutInfo.menuCustomizationModal.menuCustomizationSubHeader.lineHeight};
    font-weight: ${layoutInfo.menuCustomizationModal.menuCustomizationSubHeader.fontWeight};
}

.footerAddItemButton {
    background-color: ${fontAndColorInfo.layout.menuCustomizationModal.footerAddItemButton.backgroundColor};
    color: ${fontAndColorInfo.layout.menuCustomizationModal.footerAddItemButton.color};
    font-size: ${fontAndColorInfo.layout.menuCustomizationModal.footerAddItemButton.fontSize};
    font-weight: ${layoutInfo.menuCustomizationModal.footerAddItemButton.fontWeight};
    line-height: ${layoutInfo.menuCustomizationModal.footerAddItemButton.lineHeight};
    border-radius: ${layoutInfo.menuCustomizationModal.footerAddItemButton.borderRadius};
}

.customerDetailsFormContainer {
   // background-color: ${fontAndColorInfo.layout.customerDetailsForm.customerDetailsFormContainer.backgroundColor};
    font-size: ${fontAndColorInfo.layout.customerDetailsForm.customerDetailsFormContainer.fontSize};
   // border-color: ${fontAndColorInfo.layout.customerDetailsForm.customerDetailsFormContainer.borderColor};
   // border-style: ${layoutInfo.customerDetailsForm.customerDetailsFormContainer.borderStyle};
   // border-width: ${layoutInfo.customerDetailsForm.customerDetailsFormContainer.borderWidth};
   // border-radius: ${layoutInfo.customerDetailsForm.customerDetailsFormContainer.borderRadius};
    line-height: ${layoutInfo.customerDetailsForm.customerDetailsFormContainer.lineHeight};
    letter-spacing: ${layoutInfo.customerDetailsForm.customerDetailsFormContainer.letterSpacing};
    font-weight: ${layoutInfo.customerDetailsForm.customerDetailsFormContainer.fontWeight};

    .tableCodeTooltip {
        background-color: ${fontAndColorInfo.layout.customerDetailsForm.customerDetailsFormContainer.tableCodeTooltip.backgroundColor};
        color: ${fontAndColorInfo.layout.customerDetailsForm.customerDetailsFormContainer.tableCodeTooltip.color};
        font-size: ${fontAndColorInfo.layout.customerDetailsForm.customerDetailsFormContainer.tableCodeTooltip.fontSize};
        line-height: ${layoutInfo.customerDetailsForm.customerDetailsFormContainer.tableCodeTooltip.lineHeight};
        border-radius: ${layoutInfo.customerDetailsForm.customerDetailsFormContainer.tableCodeTooltip.borderRadius};
        width: ${layoutInfo.customerDetailsForm.customerDetailsFormContainer.tableCodeTooltip.width};
    }

    .tableCodeTooltip:after{
        content: "";
        bottom: -0.4vh;
        position: absolute;
        left: 0.2vw;
        right: 0;
        margin: 0;
        width: 0;
        height: 0;
        border-top: 8px solid  ${fontAndColorInfo.layout.customerDetailsForm.customerDetailsFormContainer.tableCodeTooltip.backgroundColor};
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
    }
}

.inputField {
    background-color: ${fontAndColorInfo.layout.customerDetailsForm.inputField.backgroundColor} !important;
    font-size: ${fontAndColorInfo.layout.customerDetailsForm.inputField.fontSize};
    font-weight: ${fontAndColorInfo.layout.customerDetailsForm.inputField.fontWeight};
    border-radius: ${layoutInfo.customerDetailsForm.inputField.borderRadius};
    min-height: ${layoutInfo.customerDetailsForm.inputField.minHeight};
    // min-width: ${layoutInfo.customerDetailsForm.inputField.minWidth};
    &:focus {
        outline: ${layoutInfo.customerDetailsForm.inputField.focus.outline};
    }
}

.inputField::placeholder{
    font-weight: ${fontAndColorInfo.layout.customerDetailsForm.inputPlaceholder.fontWeight};
}
.otpInput::placeholder{
    font-weight: ${fontAndColorInfo.layout.customerDetailsForm.inputPlaceholder.fontWeight};
}

.numberPrefix{
    background-color: ${fontAndColorInfo.layout.customerDetailsForm.numberPrefix.backgroundColor};
    border-right: ${fontAndColorInfo.layout.customerDetailsForm.numberPrefix.borderRight};
    height: ${layoutInfo.customerDetailsForm.inputField.minHeight};
    border-radius: ${fontAndColorInfo.layout.customerDetailsForm.numberPrefix.borderRadius};

}

.normalInputBorder {
    border-color: ${fontAndColorInfo.layout.customerDetailsForm.normalInputBorder.borderColor};
    border-style: ${layoutInfo.customerDetailsForm.normalInputBorder.borderStyle};
    border-width: ${layoutInfo.customerDetailsForm.normalInputBorder.borderWidth};
}

.errorInputBorder {
    border-color: ${fontAndColorInfo.layout.customerDetailsForm.errorInputBorder.borderColor};
    border-style: ${layoutInfo.customerDetailsForm.errorInputBorder.borderStyle};
    border-width: ${layoutInfo.customerDetailsForm.errorInputBorder.borderWidth};
}

.customerFormSubmitButton {
    background-color: ${fontAndColorInfo.layout.customerDetailsForm.customerFormSubmitButton.backgroundColor};
    font-size: ${fontAndColorInfo.layout.customerDetailsForm.customerFormSubmitButton.fontSize};
    color: ${fontAndColorInfo.layout.customerDetailsForm.customerFormSubmitButton.color};
    width: ${layoutInfo.customerDetailsForm.customerFormSubmitButton.width};
    height: ${layoutInfo.customerDetailsForm.customerFormSubmitButton.height};
    border-radius: ${layoutInfo.customerDetailsForm.customerFormSubmitButton.borderRadius};
    font-weight: ${layoutInfo.customerDetailsForm.customerFormSubmitButton.fontWeight};
}

.customerFormSubmitButton:disabled{
opacity:0.7;
}

.mainContentBackground:has(.customerDetailsPage){
 padding: 0px;
}
.mainContentBackground:has(.promotionDetailsPage){
 padding: 0px;
}

.tableCodeCustomerFormContainer {
    font-size: ${fontAndColorInfo.layout.customerDetailsForm.tableCodeContainer.fontSize};
    font-weight: ${layoutInfo.customerDetailsForm.tableCodeContainer.fontWeight};
}
.promotionDetailsPage{
.thirdLevelCategoryCard {
background-color: ${fontAndColorInfo.layout.category.secondLevelCategoryAccordion.backgroundColor};
box-shadow: none!important;
border-radius: 0!important;
}
}
.secondLevelCategoryAccordion {
background-color: ${fontAndColorInfo.layout.category.secondLevelCategoryAccordion.backgroundColor};
box-shadow: ${fontAndColorInfo.layout.category.secondLevelCategoryAccordion.boxShadow};
border-radius: ${layoutInfo.category.secondLevelCategoryAccordion.borderRadius};
    .mainAccordionHeader {
        background-color: ${fontAndColorInfo.layout.category.secondLevelCategoryAccordion.mainAccordionHeader.backgroundColor};
        font-size: ${fontAndColorInfo.layout.category.secondLevelCategoryAccordion.mainAccordionHeader.fontSize};
        letter-spacing: ${layoutInfo.category.secondLevelCategoryAccordion.mainAccordionHeader.letterSpacing};
        line-height: ${layoutInfo.category.secondLevelCategoryAccordion.mainAccordionHeader.lineHeight};
        font-weight: ${layoutInfo.category.secondLevelCategoryAccordion.mainAccordionHeader.fontWeight};
        height: ${layoutInfo.category.secondLevelCategoryAccordion.mainAccordionHeader.height};
    }
    .mainAccordianTitle{
    color: ${fontAndColorInfo.layout.category.secondLevelCategoryAccordion.mainAccordianTitle.color};
    font-family: ${fontAndColorInfo.layout.category.secondLevelCategoryAccordion.mainAccordianTitle.fontFamily}
    }
.thirdLevelCategoryCard {
background-color: ${fontAndColorInfo.layout.category.secondLevelCategoryAccordion.backgroundColor};
box-shadow: none!important;
border-radius: 0!important;
}

    .nestedAccordionHeader {
        color: ${fontAndColorInfo.layout.category.secondLevelCategoryAccordion.nestedAccordionHeader.color};
        font-size: ${fontAndColorInfo.layout.category.secondLevelCategoryAccordion.nestedAccordionHeader.fontSize};
        letter-spacing: ${layoutInfo.category.secondLevelCategoryAccordion.nestedAccordionHeader.letterSpacing};
        line-height: ${layoutInfo.category.secondLevelCategoryAccordion.nestedAccordionHeader.lineHeight};
        font-weight: ${layoutInfo.category.secondLevelCategoryAccordion.nestedAccordionHeader.fontWeight};
        height: ${layoutInfo.category.secondLevelCategoryAccordion.nestedAccordionHeader.height};
    }

    .nestedIcon {
        color: ${fontAndColorInfo.layout.category.secondLevelCategoryAccordion.nestedIcon.color};
    }
}

.reorderPage {

    .reorderPageContainer {
        background: ${fontAndColorInfo.layout.reorderPage.reorderPageContainer.background};
        border-radius: ${layoutInfo.reorderPage.reorderPageContainer.borderRadius};

        .menuItem {
            border-bottom-color: ${fontAndColorInfo.layout.reorderPage.reorderPageContainer.menuItem.borderBottomColor};
            border-bottom-style: ${layoutInfo.reorderPage.reorderPageContainer.menuItem.borderBottomStyle};
            border-bottom-width: ${layoutInfo.reorderPage.reorderPageContainer.menuItem.borderBottomWidth};

            .foodTypeIcon {
                width: ${layoutInfo.reorderPage.reorderPageContainer.menuItem.foodTypeIcon.width};

                img {
                    height: ${layoutInfo.reorderPage.reorderPageContainer.menuItem.foodTypeIcon.image.height};
                }
            }

            .menuItemName {
                font-size: ${fontAndColorInfo.layout.reorderPage.reorderPageContainer.menuItem.menuItemName.fontSize};
                letter-spacing: ${layoutInfo.reorderPage.reorderPageContainer.menuItem.menuItemName.letterSpacing};
                line-height: ${layoutInfo.reorderPage.reorderPageContainer.menuItem.menuItemName.lineHeight};
                font-weight: ${layoutInfo.reorderPage.reorderPageContainer.menuItem.menuItemName.fontWeight};
            }

            .menuItemDescription {
                color: ${fontAndColorInfo.layout.reorderPage.reorderPageContainer.menuItem.menuItemDescription.color};
                font-size: ${fontAndColorInfo.layout.reorderPage.reorderPageContainer.menuItem.menuItemDescription.fontSize};
                letter-spacing: ${layoutInfo.reorderPage.reorderPageContainer.menuItem.menuItemDescription.letterSpacing};
                line-height: ${layoutInfo.reorderPage.reorderPageContainer.menuItem.menuItemDescription.lineHeight};
                font-weight: ${layoutInfo.reorderPage.reorderPageContainer.menuItem.menuItemDescription.fontWeight};
            }
        }
    }

    .reorderPageButton {
        background-color: ${fontAndColorInfo.layout.reorderPage.reorderPageButton.backgroundColor};
        color: ${fontAndColorInfo.layout.reorderPage.reorderPageButton.color};
        font-size: ${fontAndColorInfo.layout.reorderPage.reorderPageButton.fontSize};
        font-weight: ${layoutInfo.reorderPage.reorderPageButton.fontWeight};
        border-radius: ${layoutInfo.reorderPage.reorderPageButton.borderRadius};
        width: ${layoutInfo.reorderPage.reorderPageButton.width};
        height: ${layoutInfo.reorderPage.reorderPageButton.height};
    }
}

.orderItemBanner {
    background-color: ${fontAndColorInfo.layout.orderItemBanner.backgroundColor};
    color: ${fontAndColorInfo.layout.orderItemBanner.color};
    font-size: ${fontAndColorInfo.layout.orderItemBanner.fontSize};
    height: ${layoutInfo.orderItemBanner.height};
    width: ${layoutInfo.orderItemBanner.width};
    transition: ${layoutInfo.orderItemBanner.transition};
    font-weight: ${layoutInfo.orderItemBanner.fontWeight};
    line-height: ${layoutInfo.orderItemBanner.lineHeight};
    letter-spacing: ${layoutInfo.orderItemBanner.letterSpacing};

    &.orderItemBannerVisible {
        bottom: ${layoutInfo.orderItemBanner.orderItemBannerVisible.bottom};
        transform: ${layoutInfo.orderItemBanner.orderItemBannerVisible.transform};
    }
        
    &.orderItemBannerHidden {
        bottom: ${layoutInfo.orderItemBanner.orderItemBannerHidden.bottom};
        transform: ${layoutInfo.orderItemBanner.orderItemBannerHidden.transform};
    }
}

.paymentTypeModal {
  background: ${fontAndColorInfo.layout.paymentTypeModal.background};

  .paymentTypeModalContent {
    background: ${fontAndColorInfo.layout.paymentTypeModal.paymentTypeModalContent.background};
    border-radius: ${layoutInfo.paymentTypeModal.paymentTypeModalContent.borderRadius};
    box-shadow: ${layoutInfo.paymentTypeModal.paymentTypeModalContent.boxShadow};
    width: ${layoutInfo.paymentTypeModal.paymentTypeModalContent.width};
    max-width: ${layoutInfo.paymentTypeModal.paymentTypeModalContent.maxWidth};

    .paymentTypeModalActions {

      .paymentTypeActionButton {
        border-radius: ${layoutInfo.paymentTypeModal.paymentTypeModalContent.paymentTypeModalActions.paymentTypeActionButton.borderRadius};

        &.paymentTypeRequestBill {
            background: ${fontAndColorInfo.layout.paymentTypeModal.paymentTypeModalContent.paymentTypeModalActions.paymentTypeActionButton.paymentTypeRequestBill.background};
            color: ${fontAndColorInfo.layout.paymentTypeModal.paymentTypeModalContent.paymentTypeModalActions.paymentTypeActionButton.paymentTypeRequestBill.color};
            border-color: ${fontAndColorInfo.layout.paymentTypeModal.paymentTypeModalContent.paymentTypeModalActions.paymentTypeActionButton.paymentTypeRequestBill.borderColor};
            border-style: ${layoutInfo.paymentTypeModal.paymentTypeModalContent.paymentTypeModalActions.paymentTypeActionButton.paymentTypeRequestBill.borderStyle};
            border-width: ${layoutInfo.paymentTypeModal.paymentTypeModalContent.paymentTypeModalActions.paymentTypeActionButton.paymentTypeRequestBill.borderWidth};

          .paymentTypeRequestBillIcon {
            font-size: ${fontAndColorInfo.layout.paymentTypeModal.paymentTypeModalContent.paymentTypeModalActions.paymentTypeActionButton.paymentTypeRequestBill.paymentTypeRequestBillIcon.fontSize};
          }

          .paymentTypeRequestBillLabel {
            font-size: ${fontAndColorInfo.layout.paymentTypeModal.paymentTypeModalContent.paymentTypeModalActions.paymentTypeActionButton.paymentTypeRequestBill.paymentTypeRequestBillLabel.fontSize};
            line-height: ${layoutInfo.paymentTypeModal.paymentTypeModalContent.paymentTypeModalActions.paymentTypeActionButton.paymentTypeRequestBill.paymentTypeRequestBillLabel.lineHeight};
            font-weight: ${layoutInfo.paymentTypeModal.paymentTypeModalContent.paymentTypeModalActions.paymentTypeActionButton.paymentTypeRequestBill.paymentTypeRequestBillLabel.fontWeight};
          }
        }

        &.paymentTypePayOnline {
          background: ${fontAndColorInfo.layout.paymentTypeModal.paymentTypeModalContent.paymentTypeModalActions.paymentTypeActionButton.paymentTypePayOnline.backgroundColor};
          color: ${fontAndColorInfo.layout.paymentTypeModal.paymentTypeModalContent.paymentTypeModalActions.paymentTypeActionButton.paymentTypePayOnline.color};

          .paymentTypePayOnlineIcon {
            font-size: ${fontAndColorInfo.layout.paymentTypeModal.paymentTypeModalContent.paymentTypeModalActions.paymentTypeActionButton.paymentTypePayOnline.paymentTypePayOnlineIcon.fontSize};
          }

          .paymentTypePayOnlineLabel {
            color: ${fontAndColorInfo.layout.paymentTypeModal.paymentTypeModalContent.paymentTypeModalActions.paymentTypeActionButton.paymentTypePayOnline.paymentTypePayOnlineLabel.color};
            font-size: ${fontAndColorInfo.layout.paymentTypeModal.paymentTypeModalContent.paymentTypeModalActions.paymentTypeActionButton.paymentTypePayOnline.paymentTypePayOnlineLabel.fontSize};
            line-height: ${layoutInfo.paymentTypeModal.paymentTypeModalContent.paymentTypeModalActions.paymentTypeActionButton.paymentTypePayOnline.paymentTypePayOnlineLabel.lineHeight};
            font-weight: ${layoutInfo.paymentTypeModal.paymentTypeModalContent.paymentTypeModalActions.paymentTypeActionButton.paymentTypePayOnline.paymentTypePayOnlineLabel.fontWeight};
          }
        }
      }
    }
  }
}

.paymentConfirmationPage {

  .paymentConfirmationContentWrapper {
    font-size: ${fontAndColorInfo.layout.paymentConfirmationPage.paymentConfirmationContentWrapper.fontSize};
    line-height: ${layoutInfo.paymentConfirmationPage.paymentConfirmationContentWrapper.lineHeight};
    font-weight: ${layoutInfo.paymentConfirmationPage.paymentConfirmationContentWrapper.fontWeight};

    &.paymentSuccess {
      color: ${fontAndColorInfo.layout.paymentConfirmationPage.paymentConfirmationContentWrapper.paymentSuccess.color};

      .paymentSuccessIconWrapper {
        background-color: ${fontAndColorInfo.layout.paymentConfirmationPage.paymentConfirmationContentWrapper.paymentSuccess.paymentSuccessIconWrapper.backgroundColor};
        border-radius: ${layoutInfo.paymentConfirmationPage.paymentConfirmationContentWrapper.paymentSuccess.paymentSuccessIconWrapper.borderRadius};

        .paymentSuccessIcon {
          font-size: ${fontAndColorInfo.layout.paymentConfirmationPage.paymentConfirmationContentWrapper.paymentSuccess.paymentSuccessIconWrapper.paymentSuccessIcon.fontSize};
          color: ${fontAndColorInfo.layout.paymentConfirmationPage.paymentConfirmationContentWrapper.paymentSuccess.paymentSuccessIconWrapper.paymentSuccessIcon.color};
        }
      }

      .paymentFailedCountdown {
            span {
                color: ${fontAndColorInfo.layout.paymentConfirmationPage.paymentConfirmationContentWrapper.paymentSuccess.paymentSuccessCountdown.span.color};
            }
        }
    }

    &.paymentProcessing {
      color: ${fontAndColorInfo.layout.paymentConfirmationPage.paymentConfirmationContentWrapper.paymentProcessing.color};

      .paymentProcessingIcon {
        font-size: ${fontAndColorInfo.layout.paymentConfirmationPage.paymentConfirmationContentWrapper.paymentProcessing.paymentProcessingIcon.fontSize};
        color: ${fontAndColorInfo.layout.paymentConfirmationPage.paymentConfirmationContentWrapper.paymentProcessing.paymentProcessingIcon.color};
      }
    }

    &.paymentFailed {
      color: ${fontAndColorInfo.layout.paymentConfirmationPage.paymentConfirmationContentWrapper.paymentFailed.color};

      .paymentFailedIconWrapper {
        background-color: ${fontAndColorInfo.layout.paymentConfirmationPage.paymentConfirmationContentWrapper.paymentFailed.paymentFailedIconWrapper.backgroundColor};
        border-radius: ${layoutInfo.paymentConfirmationPage.paymentConfirmationContentWrapper.paymentFailed.paymentFailedIconWrapper.borderRadius};

        .paymentFailedIcon {
          font-size: ${fontAndColorInfo.layout.paymentConfirmationPage.paymentConfirmationContentWrapper.paymentFailed.paymentFailedIconWrapper.paymentFailedIcon.fontSize};
          color: ${fontAndColorInfo.layout.paymentConfirmationPage.paymentConfirmationContentWrapper.paymentFailed.paymentFailedIconWrapper.paymentFailedIcon.color};
        }
      }

      .paymentFailedCountdown {
            span {
                color: ${fontAndColorInfo.layout.paymentConfirmationPage.paymentConfirmationContentWrapper.paymentFailed.paymentFailedCountdown.span.color};
            }
        }
    }
  }
}

.footerSection {
    position: fixed;
    bottom: 0;
    background: ${fontAndColorInfo.layout.footerSection.footerBackgroundColor};
}

.ringMasterButton {
    color: ${fontAndColorInfo.layout.footerSection.footerContent.ringMasterButtonSection.color};
    font-size: ${fontAndColorInfo.layout.footerSection.footerContent.ringMasterButtonSection.fontSize};
    line-height: ${layoutInfo.footerSection.footerContent.ringMasterButtonSection.lineHeight};
    min-width: ${layoutInfo.footerSection.footerContent.ringMasterButtonSection.minWidth.small};
    height: ${layoutInfo.footerSection.footerContent.ringMasterButtonSection.height.small};
    border-radius: ${layoutInfo.footerSection.footerContent.ringMasterButtonSection.borderRadius};
}

.toCall {
    background-color: ${fontAndColorInfo.layout.footerSection.footerContent.ringMasterButtonSection.toCall.backgroundColor};
}

.toBeResolved {
    background-color: ${fontAndColorInfo.layout.footerSection.footerContent.ringMasterButtonSection.toBeResolved.backgroundColor};
}

.homeButton {
    font-size: ${fontAndColorInfo.layout.footerSection.footerContent.homeButtonSection.fontSize};
    border-color: ${fontAndColorInfo.layout.footerSection.footerContent.homeButtonSection.borderColor};
    border-style: ${layoutInfo.footerSection.footerContent.homeButtonSection.borderStyle};
    border-width: ${layoutInfo.footerSection.footerContent.homeButtonSection.borderWidth};
    min-width: ${layoutInfo.footerSection.footerContent.homeButtonSection.minWidth.small};
    height: ${layoutInfo.footerSection.footerContent.homeButtonSection.height.small};
    border-radius: ${layoutInfo.footerSection.footerContent.homeButtonSection.borderRadius};
    line-height: ${layoutInfo.footerSection.footerContent.homeButtonSection.lineHeight};
}

.reorderButton {
    background-color: ${fontAndColorInfo.layout.footerSection.footerContent.reorderButtonSection.backgroundColor};
    color: ${fontAndColorInfo.layout.footerSection.footerContent.reorderButtonSection.color};
    min-width: ${layoutInfo.footerSection.footerContent.reorderButtonSection.minWidth.small};
    height: ${layoutInfo.footerSection.footerContent.reorderButtonSection.height.small};
    border-radius: ${layoutInfo.footerSection.footerContent.reorderButtonSection.borderRadius};
    font-weight: ${layoutInfo.footerSection.footerContent.reorderButtonSection.fontWeight};

    .reloadIconContainer {
        background-color: ${fontAndColorInfo.layout.footerSection.footerContent.reorderButtonSection.reloadIconContainer.backgroundColor};
        width: ${layoutInfo.footerSection.footerContent.reorderButtonSection.reloadIconContainer.width.small};
        height: ${layoutInfo.footerSection.footerContent.reorderButtonSection.reloadIconContainer.height.small};
        border-radius: ${layoutInfo.footerSection.footerContent.reorderButtonSection.reloadIconContainer.borderRadius};

        .reloadIcon {
            color: ${fontAndColorInfo.layout.footerSection.footerContent.reorderButtonSection.reloadIconContainer.reloadIcon.color};
            font-size: ${fontAndColorInfo.layout.footerSection.footerContent.reorderButtonSection.reloadIconContainer.reloadIcon.fontSize};
            line-height: ${layoutInfo.footerSection.footerContent.reorderButtonSection.reloadIconContainer.reloadIcon.lineHeight};
        }
    }
}

.viewCartButton {
    background-color: ${fontAndColorInfo.layout.footerSection.footerContent.viewCartButtonSection.backgroundColor};
    color: ${fontAndColorInfo.layout.footerSection.footerContent.viewCartButtonSection.color};
    min-width: ${layoutInfo.footerSection.footerContent.viewCartButtonSection.minWidth.small};
    width: ${layoutInfo.footerSection.footerContent.viewCartButtonSection.width.small};
    height: ${layoutInfo.footerSection.footerContent.viewCartButtonSection.height.small};
    border-radius: ${layoutInfo.footerSection.footerContent.viewCartButtonSection.borderRadius};
    font-weight: ${layoutInfo.footerSection.footerContent.viewCartButtonSection.fontWeight};

    .cartIconContainer {
        background-color: ${fontAndColorInfo.layout.footerSection.footerContent.viewCartButtonSection.cartIconContainer.backgroundColor};
        width: ${layoutInfo.footerSection.footerContent.viewCartButtonSection.cartIconContainer.width.small};
        height: ${layoutInfo.footerSection.footerContent.viewCartButtonSection.cartIconContainer.height.small};
        border-radius: ${layoutInfo.footerSection.footerContent.viewCartButtonSection.cartIconContainer.borderRadius};
       
        .cartIcon {
            color: ${fontAndColorInfo.layout.footerSection.footerContent.viewCartButtonSection.cartIconContainer.cartIcon.color};
            font-size: ${fontAndColorInfo.layout.footerSection.footerContent.viewCartButtonSection.cartIconContainer.cartIcon.fontSize};
            line-height: ${layoutInfo.footerSection.footerContent.viewCartButtonSection.cartIconContainer.cartIcon.lineHeight};
        }
    }
}

.checkoutButton,
.payButton {
    background-color: ${fontAndColorInfo.layout.footerSection.footerContent.checkoutAndPayButtonsSection.backgroundColor};
    color: ${fontAndColorInfo.layout.footerSection.footerContent.checkoutAndPayButtonsSection.color};
    min-width: ${layoutInfo.footerSection.footerContent.checkoutAndPayButtonsSection.minWidth.small};
    width: ${layoutInfo.footerSection.footerContent.checkoutAndPayButtonsSection.width.small};
    height: ${layoutInfo.footerSection.footerContent.checkoutAndPayButtonsSection.height.small};
    border-radius: ${layoutInfo.footerSection.footerContent.checkoutAndPayButtonsSection.borderRadius};
    font-weight: ${layoutInfo.footerSection.footerContent.checkoutAndPayButtonsSection.fontWeight};

    .rightArrowIcon {
        color: ${fontAndColorInfo.layout.footerSection.footerContent.checkoutAndPayButtonsSection.rightArrowIcon.color};
        font-size: ${fontAndColorInfo.layout.footerSection.footerContent.checkoutAndPayButtonsSection.rightArrowIcon.fontSize};
        line-height: ${layoutInfo.footerSection.footerContent.checkoutAndPayButtonsSection.rightArrowIcon.lineHeight};
    }
}

@media (max-width:520px) {
    .mainLogo {
        width: ${layoutInfo.header.mainLogoWidth.small};
    }
	
    .avatarLogo{
        width: ${layoutInfo.header.avatarLogo.small};
    }

    .searchSectionSearchField {
        font-size: ${fontAndColorInfo.layout.searchSection.fontSize.small};
        border-color: ${fontAndColorInfo.layout.searchSection.borderColor};
        border-style: ${layoutInfo.searchSection.borderStyle};
        border-width: ${layoutInfo.searchSection.borderWidth};
        min-width: ${layoutInfo.searchSection.searchFieldWidth.small};
        min-height: ${layoutInfo.searchSection.searchFieldHeight.small};
        border-radius: ${layoutInfo.searchSection.borderRadius.small};
        box-shadow: ${layoutInfo.searchSection.boxShadow};
    }
	
    .searchIcon {
        width: ${layoutInfo.searchSection.searchIcon.width.small};
        height: ${layoutInfo.searchSection.searchIcon.height.small};
        top: ${layoutInfo.searchSection.searchIcon.top.small};
        left: ${layoutInfo.searchSection.searchIcon.left.small};
    }

    .largeCounterButton {
        background-color: ${fontAndColorInfo.layout.counterButton.large.backgroundColor};
        color: ${fontAndColorInfo.layout.counterButton.large.color};
        width: ${layoutInfo.counterButton.large.width.medium};
        height: ${layoutInfo.counterButton.large.height.medium};
        border-radius: ${layoutInfo.counterButton.large.borderRadius};

        .largeCounterButtonText {
            font-size: ${fontAndColorInfo.layout.counterButton.large.counterButtonText.fontSize};
            letter-spacing: ${layoutInfo.counterButton.large.counterButtonText.letterSpacing};
            line-height: ${layoutInfo.counterButton.large.counterButtonText.lineHeight};
            padding: ${layoutInfo.counterButton.large.counterButtonText.padding};
        }

        .largeCounterButtonIcon {
            font-size: ${fontAndColorInfo.layout.counterButton.large.counterButtonIcon.fontSize};
        }
    }

    
    .smallCounterButton {
        background-color: ${fontAndColorInfo.layout.counterButton.small.backgroundColor};
        color: ${fontAndColorInfo.layout.counterButton.small.color};
        width: ${layoutInfo.counterButton.small.width.medium};
        height: ${layoutInfo.counterButton.small.height.medium};
        border-radius: ${layoutInfo.counterButton.small.borderRadius};

        .smallCounterButtonText {
            font-size: ${fontAndColorInfo.layout.counterButton.small.counterButtonText.fontSize};
            letter-spacing: ${layoutInfo.counterButton.small.counterButtonText.letterSpacing};
            line-height: ${layoutInfo.counterButton.small.counterButtonText.lineHeight};
            padding: ${layoutInfo.counterButton.small.counterButtonText.padding};
        }

        .smallCounterButtonIcon {
            font-size: ${fontAndColorInfo.layout.counterButton.small.counterButtonIcon.fontSize};
            background-color: ${fontAndColorInfo.layout.counterButton.small.counterButtonIcon.backgroundColor};
            color: ${fontAndColorInfo.layout.counterButton.small.counterButtonIcon.color};
        }
    }

    .checkInPageContainer {
        background-color: ${fontAndColorInfo.layout.checkInPageContainer.backgroundColor};
        border-color: ${fontAndColorInfo.layout.checkInPageContainer.borderColor};
        border-style: ${layoutInfo.checkInPageContainer.borderStyle};
        border-width: ${layoutInfo.checkInPageContainer.borderWidth};
        border-radius: ${layoutInfo.checkInPageContainer.borderRadius};

        .checkInButton {
            background-color: ${fontAndColorInfo.layout.checkInPageContainer.checkInButton.backgroundColor};
            color: ${fontAndColorInfo.layout.checkInPageContainer.checkInButton.color};
            font-size: ${fontAndColorInfo.layout.checkInPageContainer.checkInButton.fontSize};
            width: ${layoutInfo.checkInPageContainer.checkInButton.width.small};
            height: ${layoutInfo.checkInPageContainer.checkInButton.height.small};
            border-radius: ${layoutInfo.checkInPageContainer.checkInButton.borderRadius};
            line-height: ${layoutInfo.checkInPageContainer.checkInButton.lineHeight};
            letter-spacing: ${layoutInfo.checkInPageContainer.checkInButton.letterSpacing};
            font-weight: ${layoutInfo.checkInPageContainer.checkInButton.fontWeight};
        }

        .checkInMessage {
            color: ${fontAndColorInfo.layout.checkInPageContainer.checkInMessage.color};
            font-size: ${fontAndColorInfo.layout.checkInPageContainer.checkInMessage.fontSize};
            line-height: ${layoutInfo.checkInPageContainer.checkInMessage.lineHeight};
            letter-spacing: ${layoutInfo.checkInPageContainer.checkInMessage.letterSpacing};
            font-weight: ${layoutInfo.checkInPageContainer.checkInMessage.fontWeight};
        }
    }

    .firstLevelCategoryCard {
        border-color: ${fontAndColorInfo.layout.category.firstLevelCategoryCard.borderColor};
        border-style: ${layoutInfo.category.firstLevelCategoryCard.borderStyle};
        border-width: ${layoutInfo.category.firstLevelCategoryCard.borderWidth};
        width: ${layoutInfo.category.firstLevelCategoryCard.width.small};
        min-height: ${layoutInfo.category.firstLevelCategoryCard.minHeight};
        border-radius: ${layoutInfo.category.firstLevelCategoryCard.borderRadius};

        &.selectedFirstLevelCategoryCard {
            background-color: ${fontAndColorInfo.layout.category.firstLevelCategoryCard.selectedFirstLevelCategoryCard.backgroundColor};
        }

        &.notSelectedFirstLevelCategoryCard {
            background-color: ${fontAndColorInfo.layout.category.firstLevelCategoryCard.notSelectedFirstLevelCategoryCard.backgroundColor};
        }

        .firstLevelCategoryCardName {
            color: ${fontAndColorInfo.layout.category.firstLevelCategoryCard.firstLevelCategoryCardName.color};
            font-size: ${fontAndColorInfo.layout.category.firstLevelCategoryCard.firstLevelCategoryCardName.fontSize};
            line-height: ${layoutInfo.category.firstLevelCategoryCard.firstLevelCategoryCardName.lineHeight};
            letter-spacing: ${layoutInfo.category.firstLevelCategoryCard.firstLevelCategoryCardName.letterSpacing};
            font-weight: ${layoutInfo.category.firstLevelCategoryCard.firstLevelCategoryCardName.fontWeight};
        }
    }

    .thirdLevelCategoryCard {
        font-size: ${fontAndColorInfo.layout.category.thirdLevelCategoryCard.fontSize};
        border-color: ${fontAndColorInfo.layout.category.thirdLevelCategoryCard.borderColor};
        border-style: ${layoutInfo.category.thirdLevelCategoryCard.borderStyle};
        border-width: ${layoutInfo.category.thirdLevelCategoryCard.borderWidth};
        font-weight: ${layoutInfo.category.thirdLevelCategoryCard.fontWeight};
        line-height: ${layoutInfo.category.thirdLevelCategoryCard.lineHeight};
        letter-spacing: ${layoutInfo.category.thirdLevelCategoryCard.letterSpacing};
        border-radius: ${layoutInfo.category.thirdLevelCategoryCard.borderRadius};
background-color: ${fontAndColorInfo.layout.category.secondLevelCategoryAccordion.backgroundColor};
box-shadow: ${fontAndColorInfo.layout.category.nonSecondLevelCategoryAccordian.boxShadow};
border-radius: ${layoutInfo.category.secondLevelCategoryAccordion.borderRadius};
        .foodTypeImage {
            height: ${layoutInfo.category.thirdLevelCategoryCard.foodTypeImage.height.small};
            width: ${layoutInfo.category.thirdLevelCategoryCard.foodTypeImage.width.small};
        }

        .cardText {
            font-weight: ${layoutInfo.category.thirdLevelCategoryCard.cardText.fontWeight};
            letter-spacing: ${layoutInfo.category.thirdLevelCategoryCard.cardText.letterSpacing};
        }

        .menuItemName{
        color:${fontAndColorInfo.layout.category.thirdLevelCategoryCard?.menuItemName?.color};
        font-weight:${fontAndColorInfo.layout.category.thirdLevelCategoryCard?.menuItemName?.fontWeight};
        font-size:${fontAndColorInfo.layout.category.thirdLevelCategoryCard?.menuItemName?.fontSize};
        }
        
            .cardDescription{
            font-size:${fontAndColorInfo.layout.category.thirdLevelCategoryCard?.cardDescription?.fontSize};
            line-height:${fontAndColorInfo.layout.category.thirdLevelCategoryCard?.cardDescription?.lineHeight};
            letter-spacing:${fontAndColorInfo.layout.category.thirdLevelCategoryCard?.cardDescription?.letterSpacing};
            }

        .addButton {
            background-color: ${fontAndColorInfo.layout.category.thirdLevelCategoryCard.addButton.backgroundColor};
            color: ${fontAndColorInfo.layout.category.thirdLevelCategoryCard.addButton.color};
            width: ${layoutInfo.category.thirdLevelCategoryCard.addButton.width.medium};
            height: ${layoutInfo.category.thirdLevelCategoryCard.addButton.height.medium};
            border-radius: ${layoutInfo.category.thirdLevelCategoryCard.addButton.borderRadius};
        }
        
        .customizablePriceText{
        font-size: ${fontAndColorInfo.layout.category.thirdLevelCategoryCard.customizablePriceText.fontSize};
        "letter-spacing: ${fontAndColorInfo.layout.category.thirdLevelCategoryCard.customizablePriceText.fontWeight};
        }

        &.withoutImageCard {
            height: ${layoutInfo.category.thirdLevelCategoryCard.withoutImageCard.height.small};
        }

        &.compactCard {
            height: ${layoutInfo.category.thirdLevelCategoryCard.compactCard.height.small};
        
            .compactCardImage {
                height: ${layoutInfo.category.thirdLevelCategoryCard.compactCard.compactCardImage.height.medium};
                width: ${layoutInfo.category.thirdLevelCategoryCard.compactCard.compactCardImage.width.medium};
                border-radius: ${layoutInfo.category.thirdLevelCategoryCard.compactCard.compactCardImage.borderRadius};
            }
		}
		
        &.largeCard {
            height: ${layoutInfo.category.thirdLevelCategoryCard.largeCard.height.small};

            .largeCardImage {
                height: ${layoutInfo.category.thirdLevelCategoryCard.largeCard.largeCardImage.height.small};
                border-radius: ${layoutInfo.category.thirdLevelCategoryCard.largeCard.largeCardImage.borderRadius};
            }
        }
	}

    .qrCodeModal {
        .qrCodeModalContainer {
            background-color: ${fontAndColorInfo.layout.qrCodeModal.qrCodeModalContainer.backgroundColor};
            border-color: ${fontAndColorInfo.layout.qrCodeModal.qrCodeModalContainer.borderColor};
            border-style: ${layoutInfo.qrCodeModal.qrCodeModalContainer.borderStyle};
            border-width: ${layoutInfo.qrCodeModal.qrCodeModalContainer.borderWidth};
            border-radius: ${layoutInfo.qrCodeModal.qrCodeModalContainer.borderRadius};
            box-shadow: ${layoutInfo.qrCodeModal.qrCodeModalContainer.boxShadow};
            min-height: ${layoutInfo.qrCodeModal.qrCodeModalContainer.minHeight.small};
            width: ${layoutInfo.qrCodeModal.qrCodeModalContainer.width.small};
        }

        .qrCodeButton {
            background-color: ${fontAndColorInfo.layout.qrCodeModal.qrCodeButton.backgroundColor};
            min-width: ${layoutInfo.qrCodeModal.qrCodeButton.minWidth.small};
            min-height: ${layoutInfo.qrCodeModal.qrCodeButton.minHeight.small};
            border-radius: ${layoutInfo.qrCodeModal.qrCodeButton.borderRadius};

            img {
                min-width: ${layoutInfo.qrCodeModal.qrCodeButton.image.minWidth.small};
                min-height: ${layoutInfo.qrCodeModal.qrCodeButton.image.minHeight.small};
            }
        }

        .qrCodeDescription {
            color: ${fontAndColorInfo.layout.qrCodeModal.qrCodeDescription.color};
            font-size: ${fontAndColorInfo.layout.qrCodeModal.qrCodeDescription.fontSize};
            font-style: ${layoutInfo.qrCodeModal.qrCodeDescription.fontStyle};
            line-height: ${layoutInfo.qrCodeModal.qrCodeDescription.lineHeight};
            font-weight: ${layoutInfo.qrCodeModal.qrCodeDescription.fontWeight};
        }
    }

    .smallPromotionBannerImage {
        width: ${layoutInfo.promotion.smallPromotionBannerImage.width.small};
        height: ${layoutInfo.promotion.smallPromotionBannerImage.height.small};
        border-radius: ${layoutInfo.promotion.smallPromotionBannerImage.borderRadius};
    }

    .bigPromotionBannerImage {
        width: ${layoutInfo.promotion.bigPromotionBannerImage.width.small};
        height: ${layoutInfo.promotion.bigPromotionBannerImage.height.small};
        border-radius: ${layoutInfo.promotion.bigPromotionBannerImage.borderRadius};
    }
    
    .repeatCustomizationModal {
        // background-color: ${fontAndColorInfo.layout.repeatCustomizationModal.modal.backgroundColor};
        position: ${layoutInfo.repeatCustomizationModal.modal.position};
        inset: ${layoutInfo.repeatCustomizationModal.modal.inset};
        z-index: ${layoutInfo.repeatCustomizationModal.modal.zIndex};
        display: ${layoutInfo.repeatCustomizationModal.modal.display};
        align-items: ${layoutInfo.repeatCustomizationModal.modal.alignItems};
        justify-content: ${layoutInfo.repeatCustomizationModal.modal.justifyContent};
    }

	.repeatCustomizationModalContent {
        background-color: ${fontAndColorInfo.layout.repeatCustomizationModal.modalContent.backgroundColor};
        border-radius: ${layoutInfo.repeatCustomizationModal.modalContent.borderRadius};
        box-shadow: ${layoutInfo.repeatCustomizationModal.modalContent.boxShadow};
        width: ${layoutInfo.repeatCustomizationModal.modalContent.width.small};
        min-height: ${layoutInfo.repeatCustomizationModal.modalContent.minHeight.small};
        max-height: ${layoutInfo.repeatCustomizationModal.modalContent.maxHeight.small};
        overflow-y: ${layoutInfo.repeatCustomizationModal.modalContent.overflowY};
        margin:${layoutInfo.repeatCustomizationModal.modalContent.margin};
    }

    .repeatCustomizationModalTitle {
        font-size: ${fontAndColorInfo.layout.repeatCustomizationModal.modalTitle.fontSize};
        color: ${fontAndColorInfo.layout.repeatCustomizationModal.modalTitle.fontColor};
        line-height: ${layoutInfo.repeatCustomizationModal.modalTitle.lineHeight};
        font-weight: ${layoutInfo.repeatCustomizationModal.modalTitle.fontWeight};
    }

    .repeatCustomizationModalBody {
       //  background-color: ${fontAndColorInfo.layout.repeatCustomizationModal.modalBody.backgroundColor};
       //  border-color: ${fontAndColorInfo.layout.repeatCustomizationModal.modalBody.borderColor};
       //  border-style: ${layoutInfo.repeatCustomizationModal.modalBody.borderStyle};
       //  border-width: ${layoutInfo.repeatCustomizationModal.modalBody.borderWidth};
       //  border-radius: ${layoutInfo.repeatCustomizationModal.modalBody.borderRadius};
    }

    .repeatCustomizationModalFoodImage {
        height: ${layoutInfo.repeatCustomizationModal.modalFoodImage.height.small};
    }

    .repeatCustomizationModalItemName {
        font-size: ${fontAndColorInfo.layout.repeatCustomizationModal.modalItemName.fontSize};
        letter-spacing: ${layoutInfo.repeatCustomizationModal.modalItemName.letterSpacing};
        line-height: ${layoutInfo.repeatCustomizationModal.modalItemName.lineHeight};
        font-weight: ${layoutInfo.repeatCustomizationModal.modalItemName.fontWeight};
    }

    .repeatCustomizationModalItemDescription {
        color: ${fontAndColorInfo.layout.repeatCustomizationModal.modalItemDescription.color};
        font-size: ${fontAndColorInfo.layout.repeatCustomizationModal.modalItemDescription.fontSize};
        letter-spacing: ${layoutInfo.repeatCustomizationModal.modalItemDescription.letterSpacing};
        line-height: ${layoutInfo.repeatCustomizationModal.modalItemDescription.lineHeight};
    }

    .repeatCustomizationModalEditPrice {
        font-size: ${fontAndColorInfo.layout.repeatCustomizationModal.modalEditPrice.fontSize};
        letter-spacing: ${layoutInfo.repeatCustomizationModal.modalEditPrice.letterSpacing};
        line-height: ${layoutInfo.repeatCustomizationModal.modalEditPrice.lineHeight};
        font-weight: ${layoutInfo.repeatCustomizationModal.modalEditPrice.fontWeight};
    }
    
    .repeatCustomizationModalEditButton{
     color: ${fontAndColorInfo.layout.repeatCustomizationModal.modalAddCustomizationButton.color};
        background-color: ${fontAndColorInfo.layout.repeatCustomizationModal.modalAddCustomizationButton.backgroundColor};
        letter-spacing: ${layoutInfo.repeatCustomizationModal.modalAddCustomizationButton.letterSpacing};
    }

    .repeatCustomizationModalAddCustomizationButton {
        color: ${fontAndColorInfo.layout.repeatCustomizationModal.modalAddCustomizationButton.color};
        background-color: ${fontAndColorInfo.layout.repeatCustomizationModal.modalAddCustomizationButton.backgroundColor};
        font-size: ${fontAndColorInfo.layout.repeatCustomizationModal.modalAddCustomizationButton.fontSize};
        width: ${layoutInfo.repeatCustomizationModal.modalAddCustomizationButton.width.small};
        line-height: ${layoutInfo.repeatCustomizationModal.modalAddCustomizationButton.lineHeight};
        letter-spacing: ${layoutInfo.repeatCustomizationModal.modalAddCustomizationButton.letterSpacing};
        font-weight: ${layoutInfo.repeatCustomizationModal.modalAddCustomizationButton.fontWeight};
    }
    .repeatCustomizationModalEditCustomizationButton {
        color: ${fontAndColorInfo.layout.repeatCustomizationModal.modalEditCustomizationButton.color};
        background-color: ${fontAndColorInfo.layout.repeatCustomizationModal.modalEditCustomizationButton.backgroundColor};
        border: ${fontAndColorInfo.layout.repeatCustomizationModal.modalEditCustomizationButton.border};
        font-size: ${fontAndColorInfo.layout.repeatCustomizationModal.modalAddCustomizationButton.fontSize};
        width: ${layoutInfo.repeatCustomizationModal.modalAddCustomizationButton.width.small};
        line-height: ${layoutInfo.repeatCustomizationModal.modalAddCustomizationButton.lineHeight};
        letter-spacing: ${layoutInfo.repeatCustomizationModal.modalAddCustomizationButton.letterSpacing};
        font-weight: ${layoutInfo.repeatCustomizationModal.modalAddCustomizationButton.fontWeight};
    }

    .confirmationModal {
        background-color: ${fontAndColorInfo.layout.confirmationModal.backgroundColor};

        .confirmationModalContent {
            background-color: ${fontAndColorInfo.layout.confirmationModal.confirmationModalContent.backgroundColor};
            border-radius: ${layoutInfo.confirmationModal.confirmationModalContent.borderRadius};
            box-shadow: ${layoutInfo.confirmationModal.confirmationModalContent.boxShadow};
            width: ${layoutInfo.confirmationModal.confirmationModalContent.width.small};
            min-height: ${layoutInfo.confirmationModal.confirmationModalContent.minHeight.small};
            max-width: ${layoutInfo.confirmationModal.confirmationModalContent.maxWidth.small};

            .confirmationModalHeader {
                font-size: ${fontAndColorInfo.layout.confirmationModal.confirmationModalContent.confirmationModalHeader.fontSize};
			    line-height: ${layoutInfo.confirmationModal.confirmationModalContent.confirmationModalHeader.lineHeight};
                font-weight: ${layoutInfo.confirmationModal.confirmationModalContent.confirmationModalHeader.fontWeight};
            }

            .confirmationModalMessage {
                font-size: ${fontAndColorInfo.layout.confirmationModal.confirmationModalContent.confirmationModalMessage.fontSize};
			    line-height: ${layoutInfo.confirmationModal.confirmationModalContent.confirmationModalMessage.lineHeight};
                font-weight: ${layoutInfo.confirmationModal.confirmationModalContent.confirmationModalMessage.fontWeight};
            }
        }

		.cancelButton {
            background-color: ${fontAndColorInfo.layout.confirmationModal.cancelButton.backgroundColor};
            color: ${fontAndColorInfo.layout.confirmationModal.cancelButton.color};
			font-size: ${fontAndColorInfo.layout.confirmationModal.cancelButton.fontSize};
            border-color: ${fontAndColorInfo.layout.confirmationModal.cancelButton.borderColor};
            border-style: ${layoutInfo.confirmationModal.cancelButton.borderStyle};
            border-width: ${layoutInfo.confirmationModal.cancelButton.borderWidth};
			border-radius: ${layoutInfo.confirmationModal.cancelButton.borderRadius};
			line-height: ${layoutInfo.confirmationModal.cancelButton.lineHeight};
        }

        .confirmButton {
			font-size: ${fontAndColorInfo.layout.confirmationModal.confirmButton.fontSize};
            background-color: ${fontAndColorInfo.layout.confirmationModal.confirmButton.backgroundColor};
            color: ${fontAndColorInfo.layout.confirmationModal.confirmButton.color};
			border-radius: ${layoutInfo.confirmationModal.confirmButton.borderRadius};
			line-height: ${layoutInfo.confirmationModal.confirmButton.lineHeight};
            width: ${layoutInfo.confirmationModal.confirmButton.width.small};
        }

    }

    

}

@media (min-width:521px) {

    .mainLogo {
        width: ${layoutInfo.header.mainLogoWidth.small};
    }
	
    .avatarLogo {
        width: ${layoutInfo.header.avatarLogo.large};
    } 
	
    .searchFieldWrapper {
        width: ${layoutInfo.searchSection.searchFieldWrapperWidth.large}; 
    }
	
    .searchSectionSearchField {
        font-size: ${fontAndColorInfo.layout.searchSection.fontSize.small};
        border-color: ${fontAndColorInfo.layout.searchSection.borderColor};
        border-style: ${layoutInfo.searchSection.borderStyle};
        border-width: ${layoutInfo.searchSection.borderWidth};
        min-width: ${layoutInfo.searchSection.searchFieldMinWidth.small};
        width: ${layoutInfo.searchSection.searchFieldWidth.small};
        min-height: ${layoutInfo.searchSection.searchFieldHeight.small};
        border-radius: ${layoutInfo.searchSection.borderRadius.small};
        box-shadow:${layoutInfo.searchSection.boxShadow};
    }
	
    .searchIcon {
        width: ${layoutInfo.searchSection.searchIcon.width.small};
        height: ${layoutInfo.searchSection.searchIcon.height.small};
        top: ${layoutInfo.searchSection.searchIcon.top.small};
        left: ${layoutInfo.searchSection.searchIcon.left.small};
    }

    .largeCounterButton {
        background-color: ${fontAndColorInfo.layout.counterButton.large.backgroundColor};
        color: ${fontAndColorInfo.layout.counterButton.large.color};
        width: ${layoutInfo.counterButton.large.width.small};
        height: ${layoutInfo.counterButton.large.height.small};
        border-radius: ${layoutInfo.counterButton.large.borderRadius};

        .largeCounterButtonText {
            font-size: ${fontAndColorInfo.layout.counterButton.large.counterButtonText.fontSize};
            letter-spacing: ${layoutInfo.counterButton.large.counterButtonText.letterSpacing};
            line-height: ${layoutInfo.counterButton.large.counterButtonText.lineHeight};
            padding: ${layoutInfo.counterButton.large.counterButtonText.padding};
        }

        .largeCounterButtonIcon {
            font-size: ${fontAndColorInfo.layout.counterButton.large.counterButtonIcon.fontSize};
        }
    }

    .smallCounterButton {
        background-color: ${fontAndColorInfo.layout.counterButton.small.backgroundColor};
        color: ${fontAndColorInfo.layout.counterButton.small.color};
        width: ${layoutInfo.counterButton.small.width.small};
        height: ${layoutInfo.counterButton.small.height.small};
        border-radius: ${layoutInfo.counterButton.small.borderRadius};

        .smallCounterButtonText {
            font-size: ${fontAndColorInfo.layout.counterButton.small.counterButtonText.fontSize};
            letter-spacing: ${layoutInfo.counterButton.small.counterButtonText.letterSpacing};
            line-height: ${layoutInfo.counterButton.small.counterButtonText.lineHeight};
        }

        .smallCounterButtonIcon {
            font-size: ${fontAndColorInfo.layout.counterButton.small.counterButtonIcon.fontSize};
        }
    }

    .checkInPageContainer {
        background-color: ${fontAndColorInfo.layout.checkInPageContainer.backgroundColor};
        border-color: ${fontAndColorInfo.layout.checkInPageContainer.borderColor};
        border-style: ${layoutInfo.checkInPageContainer.borderStyle};
        border-width: ${layoutInfo.checkInPageContainer.borderWidth};
        border-radius: ${layoutInfo.checkInPageContainer.borderRadius};

        .checkInButton {
            background-color: ${fontAndColorInfo.layout.checkInPageContainer.checkInButton.backgroundColor};
            color: ${fontAndColorInfo.layout.checkInPageContainer.checkInButton.color};
            font-size: ${fontAndColorInfo.layout.checkInPageContainer.checkInButton.fontSize};
            width: ${layoutInfo.checkInPageContainer.checkInButton.width.small};
            height: ${layoutInfo.checkInPageContainer.checkInButton.height.small};
            border-radius: ${layoutInfo.checkInPageContainer.checkInButton.borderRadius};
            line-height: ${layoutInfo.checkInPageContainer.checkInButton.lineHeight};
            letter-spacing: ${layoutInfo.checkInPageContainer.checkInButton.letterSpacing};
            font-weight: ${layoutInfo.checkInPageContainer.checkInButton.fontWeight};
        }
    }

    .firstLevelCategoryCard {
        border-color: ${fontAndColorInfo.layout.category.firstLevelCategoryCard.borderColor};
        border-style: ${layoutInfo.category.firstLevelCategoryCard.borderStyle};
        border-width: ${layoutInfo.category.firstLevelCategoryCard.borderWidth};
        width: ${layoutInfo.category.firstLevelCategoryCard.width.small};
        min-height: ${layoutInfo.category.firstLevelCategoryCard.minHeight};
        border-radius: ${layoutInfo.category.firstLevelCategoryCard.borderRadius};

        &.selectedFirstLevelCategoryCard {
            background-color: ${fontAndColorInfo.layout.category.firstLevelCategoryCard.selectedFirstLevelCategoryCard.backgroundColor};
        }

        &.notSelectedFirstLevelCategoryCard {
            background-color: ${fontAndColorInfo.layout.category.firstLevelCategoryCard.notSelectedFirstLevelCategoryCard.backgroundColor};
        }

        .firstLevelCategoryCardName {
            color: ${fontAndColorInfo.layout.category.firstLevelCategoryCard.firstLevelCategoryCardName.color};
            font-size: ${fontAndColorInfo.layout.category.firstLevelCategoryCard.firstLevelCategoryCardName.fontSize};
            line-height: ${layoutInfo.category.firstLevelCategoryCard.firstLevelCategoryCardName.lineHeight};
            letter-spacing: ${layoutInfo.category.firstLevelCategoryCard.firstLevelCategoryCardName.letterSpacing};
            font-weight: ${layoutInfo.category.firstLevelCategoryCard.firstLevelCategoryCardName.fontWeight};
        }
    } 
	
	
    .thirdLevelCategoryCard {
        font-size: ${fontAndColorInfo.layout.category.thirdLevelCategoryCard.fontSize};
        border-color: ${fontAndColorInfo.layout.category.thirdLevelCategoryCard.borderColor};
        border-style: ${layoutInfo.category.thirdLevelCategoryCard.borderStyle};
        border-width: ${layoutInfo.category.thirdLevelCategoryCard.borderWidth};
        font-weight: ${layoutInfo.category.thirdLevelCategoryCard.fontWeight};
        line-height: ${layoutInfo.category.thirdLevelCategoryCard.lineHeight};
        letter-spacing: ${layoutInfo.category.thirdLevelCategoryCard.letterSpacing};
        border-radius: ${layoutInfo.category.thirdLevelCategoryCard.borderRadius};

        .foodTypeImage {
            height: ${layoutInfo.category.thirdLevelCategoryCard.foodTypeImage.height.small};
            width: ${layoutInfo.category.thirdLevelCategoryCard.foodTypeImage.width.small};
        }

        .cardText {
            font-weight: ${layoutInfo.category.thirdLevelCategoryCard.cardText.fontWeight};
            letter-spacing: ${layoutInfo.category.thirdLevelCategoryCard.cardText.letterSpacing};
        }

        .addButton {
            background-color: ${fontAndColorInfo.layout.category.thirdLevelCategoryCard.addButton.backgroundColor};
            color: ${fontAndColorInfo.layout.category.thirdLevelCategoryCard.addButton.color};
            width: ${layoutInfo.category.thirdLevelCategoryCard.addButton.width.small};
            height: ${layoutInfo.category.thirdLevelCategoryCard.addButton.height.small};
            border-radius: ${layoutInfo.category.thirdLevelCategoryCard.addButton.borderRadius};
        }

        &.withoutImageCard {
            height: ${layoutInfo.category.thirdLevelCategoryCard.withoutImageCard.height.small};
        }

        &.compactCard {
            height: ${layoutInfo.category.thirdLevelCategoryCard.compactCard.height.small};
        
            .compactCardImage {
                height: ${layoutInfo.category.thirdLevelCategoryCard.compactCard.compactCardImage.height.small};
                width: ${layoutInfo.category.thirdLevelCategoryCard.compactCard.compactCardImage.width.small};
                border-radius: ${layoutInfo.category.thirdLevelCategoryCard.compactCard.compactCardImage.borderRadius};
            }
		}
		
        &.largeCard {
            height: ${layoutInfo.category.thirdLevelCategoryCard.largeCard.height.small};

            .largeCardImage {
                height: ${layoutInfo.category.thirdLevelCategoryCard.largeCard.largeCardImage.height.small};
                border-radius: ${layoutInfo.category.thirdLevelCategoryCard.largeCard.largeCardImage.borderRadius};
            }
        }
	}

    .qrCodeModal {
        .qrCodeModalContainer {
            background-color: ${fontAndColorInfo.layout.qrCodeModal.qrCodeModalContainer.backgroundColor};
            border-color: ${fontAndColorInfo.layout.qrCodeModal.qrCodeModalContainer.borderColor};
            border-style: ${layoutInfo.qrCodeModal.qrCodeModalContainer.borderStyle};
            border-width: ${layoutInfo.qrCodeModal.qrCodeModalContainer.borderWidth};
            border-radius: ${layoutInfo.qrCodeModal.qrCodeModalContainer.borderRadius};
            box-shadow: ${layoutInfo.qrCodeModal.qrCodeModalContainer.boxShadow};
            min-height: ${layoutInfo.qrCodeModal.qrCodeModalContainer.minHeight.small};
            width: ${layoutInfo.qrCodeModal.qrCodeModalContainer.width.small};
        }

        .qrCodeButton {
            background-color: ${fontAndColorInfo.layout.qrCodeModal.qrCodeButton.backgroundColor};
            min-width: ${layoutInfo.qrCodeModal.qrCodeButton.minWidth.small};
            min-height: ${layoutInfo.qrCodeModal.qrCodeButton.minHeight.small};
            border-radius: ${layoutInfo.qrCodeModal.qrCodeButton.borderRadius};

            img {
                min-width: ${layoutInfo.qrCodeModal.qrCodeButton.image.minWidth.small};
                min-height: ${layoutInfo.qrCodeModal.qrCodeButton.image.minHeight.small};
            }
        }

        .qrCodeDescription {
            font-style: ${fontAndColorInfo.layout.qrCodeModal.qrCodeDescription.fontStyle};
            color: ${fontAndColorInfo.layout.qrCodeModal.qrCodeDescription.color};
            font-size: ${layoutInfo.qrCodeModal.qrCodeDescription.fontSize};
            line-height: ${layoutInfo.qrCodeModal.qrCodeDescription.lineHeight};
            font-weight: ${layoutInfo.qrCodeModal.qrCodeDescription.fontWeight};
        }
    }

    .smallPromotionBannerImage {
        width: ${layoutInfo.promotion.smallPromotionBannerImage.width.small};
        height: ${layoutInfo.promotion.smallPromotionBannerImage.height.small};
        border-radius: ${layoutInfo.promotion.smallPromotionBannerImage.borderRadius};
    }

    .bigPromotionBannerImage {
        width: ${layoutInfo.promotion.bigPromotionBannerImage.width.small};
        height: ${layoutInfo.promotion.bigPromotionBannerImage.height.small};
        border-radius: ${layoutInfo.promotion.bigPromotionBannerImage.borderRadius};
    }

    .repeatCustomizationModal {
        // background-color: ${fontAndColorInfo.layout.repeatCustomizationModal.modal.backgroundColor};
        position: ${layoutInfo.repeatCustomizationModal.modal.position};
        inset: ${layoutInfo.repeatCustomizationModal.modal.inset};
        z-index: ${layoutInfo.repeatCustomizationModal.modal.zIndex};
        display: ${layoutInfo.repeatCustomizationModal.modal.display};
        align-items: ${layoutInfo.repeatCustomizationModal.modal.alignItems};
        justify-content: ${layoutInfo.repeatCustomizationModal.modal.justifyContent};
    }

	.repeatCustomizationModalContent {
        background-color: ${fontAndColorInfo.layout.repeatCustomizationModal.modalContent.backgroundColor};
        border-radius: ${layoutInfo.repeatCustomizationModal.modalContent.borderRadius};
        box-shadow: ${layoutInfo.repeatCustomizationModal.modalContent.boxShadow};
        width: ${layoutInfo.repeatCustomizationModal.modalContent.width.small};
        min-height: ${layoutInfo.repeatCustomizationModal.modalContent.minHeight.small};
        max-height: ${layoutInfo.repeatCustomizationModal.modalContent.maxHeight.small};
        overflow-y: ${layoutInfo.repeatCustomizationModal.modalContent.overflowY};
    }

    .repeatCustomizationModalTitle {
        font-size: ${fontAndColorInfo.layout.repeatCustomizationModal.modalTitle.fontSize};
        line-height: ${layoutInfo.repeatCustomizationModal.modalTitle.lineHeight};
        font-weight: ${layoutInfo.repeatCustomizationModal.modalTitle.fontWeight};
    }

    .repeatCustomizationModalBody {
        background-color: ${fontAndColorInfo.layout.repeatCustomizationModal.modalBody.backgroundColor};
        border-color: ${fontAndColorInfo.layout.repeatCustomizationModal.modalBody.borderColor};
        border-style: ${layoutInfo.repeatCustomizationModal.modalBody.borderStyle};
        border-width: ${layoutInfo.repeatCustomizationModal.modalBody.borderWidth};
        border-radius: ${layoutInfo.repeatCustomizationModal.modalBody.borderRadius};
    }

    .repeatCustomizationModalFoodImage {
        height: ${layoutInfo.repeatCustomizationModal.modalFoodImage.height.small};
    }

    .repeatCustomizationModalItemName {
        font-size: ${fontAndColorInfo.layout.repeatCustomizationModal.modalItemName.fontSize};
        letter-spacing: ${layoutInfo.repeatCustomizationModal.modalItemName.letterSpacing};
        line-height: ${layoutInfo.repeatCustomizationModal.modalItemName.lineHeight};
        font-weight: ${layoutInfo.repeatCustomizationModal.modalItemName.fontWeight};
    }

    .repeatCustomizationModalItemDescription {
        color: ${fontAndColorInfo.layout.repeatCustomizationModal.modalItemDescription.color};
        font-size: ${fontAndColorInfo.layout.repeatCustomizationModal.modalItemDescription.fontSize};
        letter-spacing: ${layoutInfo.repeatCustomizationModal.modalItemDescription.letterSpacing};
        line-height: ${layoutInfo.repeatCustomizationModal.modalItemDescription.lineHeight};
    }

    .repeatCustomizationModalEditPrice {
        font-size: ${fontAndColorInfo.layout.repeatCustomizationModal.modalEditPrice.fontSize};
        letter-spacing: ${layoutInfo.repeatCustomizationModal.modalEditPrice.letterSpacing};
        line-height: ${layoutInfo.repeatCustomizationModal.modalEditPrice.lineHeight};
        font-weight: ${layoutInfo.repeatCustomizationModal.modalEditPrice.fontWeight};
    }

    .repeatCustomizationModalAddCustomizationButton {
        color:${fontAndColorInfo.layout.repeatCustomizationModal.modalAddCustomizationButton.color};
        font-size: ${fontAndColorInfo.layout.repeatCustomizationModal.modalAddCustomizationButton.fontSize};
        width: ${layoutInfo.repeatCustomizationModal.modalAddCustomizationButton.width.small};
        line-height: ${layoutInfo.repeatCustomizationModal.modalAddCustomizationButton.lineHeight};
        letter-spacing: ${layoutInfo.repeatCustomizationModal.modalAddCustomizationButton.letterSpacing};
        font-weight: ${layoutInfo.repeatCustomizationModal.modalAddCustomizationButton.fontWeight};
    } 
        
    
    .confirmationModal {
        background-color: ${fontAndColorInfo.layout.confirmationModal.backgroundColor};

        .confirmationModalContent {
            background-color: ${fontAndColorInfo.layout.confirmationModal.confirmationModalContent.backgroundColor};
            border-radius: ${layoutInfo.confirmationModal.confirmationModalContent.borderRadius};
            box-shadow: ${layoutInfo.confirmationModal.confirmationModalContent.boxShadow};
            width: ${layoutInfo.confirmationModal.confirmationModalContent.width.small};
            min-height: ${layoutInfo.confirmationModal.confirmationModalContent.minHeight.small};
            max-width: ${layoutInfo.confirmationModal.confirmationModalContent.maxWidth.small};

            .confirmationModalHeader {
                font-size: ${fontAndColorInfo.layout.confirmationModal.confirmationModalContent.confirmationModalHeader.fontSize};
			    line-height: ${layoutInfo.confirmationModal.confirmationModalContent.confirmationModalHeader.lineHeight};
                font-weight: ${layoutInfo.confirmationModal.confirmationModalContent.confirmationModalHeader.fontWeight};
            }

            .confirmationModalMessage {
                font-size: ${fontAndColorInfo.layout.confirmationModal.confirmationModalContent.confirmationModalMessage.fontSize};
			    line-height: ${layoutInfo.confirmationModal.confirmationModalContent.confirmationModalMessage.lineHeight};
                font-weight: ${layoutInfo.confirmationModal.confirmationModalContent.confirmationModalMessage.fontWeight};
            }
        }

		.cancelButton {
            background-color: ${fontAndColorInfo.layout.confirmationModal.cancelButton.backgroundColor};
            color: ${fontAndColorInfo.layout.confirmationModal.cancelButton.color};
			font-size: ${fontAndColorInfo.layout.confirmationModal.cancelButton.fontSize};
            border-color: ${fontAndColorInfo.layout.confirmationModal.cancelButton.borderColor};
            border-style: ${layoutInfo.confirmationModal.cancelButton.borderStyle};
            border-width: ${layoutInfo.confirmationModal.cancelButton.borderWidth};
			border-radius: ${layoutInfo.confirmationModal.cancelButton.borderRadius};
			line-height: ${layoutInfo.confirmationModal.cancelButton.lineHeight};
        }

        .confirmButton {
			font-size: ${fontAndColorInfo.layout.confirmationModal.confirmButton.fontSize};
            color: ${fontAndColorInfo.layout.confirmationModal.confirmButton.color};
            background-color: ${fontAndColorInfo.layout.confirmationModal.confirmButton.backgroundColor};
			border-radius: ${layoutInfo.confirmationModal.confirmButton.borderRadius};
			line-height: ${layoutInfo.confirmationModal.confirmButton.lineHeight};
            width: ${layoutInfo.confirmationModal.confirmButton.width.small};
        }

    }
} 

@keyframes moveBackAndForth {
            from {
              left: 0;
            }
            to {
              left: calc(100% - 16px);
            }
          }

.dash{

position: ${layoutInfo.dash.position};
height: ${layoutInfo.dash.height};
width: ${layoutInfo.dash.width};
background-color: ${layoutInfo.dash.backgroundColor};
border-radius: ${layoutInfo.dash.borderRadius};
animation: moveBackAndForth 0.6s ease-in-out infinite alternate;
}

.loading-overlay{
position: ${layoutInfo.laodingOverloay.position};
            top: ${layoutInfo.laodingOverloay.top};
            left: ${layoutInfo.laodingOverloay.left};
            right: ${layoutInfo.laodingOverloay.right};
            bottom: ${layoutInfo.laodingOverloay.bottom};
            background-color: ${layoutInfo.laodingOverloay.backgroundColor};
            display: ${layoutInfo.laodingOverloay.display};
            justify-content: ${layoutInfo.laodingOverloay.justifyContent};
            align-items: ${layoutInfo.laodingOverloay.alignItems};
            z-index: ${layoutInfo.laodingOverloay.zIndex};
            transition: ${layoutInfo.laodingOverloay.transition};
}
            .loading-overlay.entering {
            opacity: ${layoutInfo.entering.opacity};
          }
            .loading-overlay.visible {
            opacity: ${layoutInfo.visible.opacity};
          }

`;
