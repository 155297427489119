export const rupeeFormatter = (data: any) => {
  let temp = data;
  if (typeof data === "string") {
    temp = temp.toString();
  }
  const formatter = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
    minimumFractionDigits: 2,
  });
  const result = formatter.format(temp);
  return result;
};
