import { convertToCamelCase } from "central-utils/stringUtils";
import React, { useEffect, useState } from "react";
import colorInfo from "styles/variableJSON/fontAndColor.json";
import { useAppSelector } from "store/customHooks";

interface Props {
  buttonType?: "SMALL" | "LARGE";
  buttonName?: string;
  disable?: boolean;
  disableQuantityUpdate?: boolean;
  initialQuantity?: number;
  onQuantityChange: (quantity: number) => void;
  itemId?: any;
}

const CounterButton: React.FC<Props> = ({
  buttonType = "LARGE",
  buttonName,
  disable = false,
  disableQuantityUpdate = false,
  initialQuantity = 1,
  onQuantityChange,
  itemId,
}) => {
  const [quantity, setQuantity] = useState(initialQuantity);
  const counterLoader = useAppSelector(
    (state) => state.tab.counterLoader[itemId] ?? false
  );
  const camelCasedButtonName = buttonName && convertToCamelCase(buttonName);
  useEffect(() => {
    setQuantity(initialQuantity);
  }, [initialQuantity]);

  const updateQuantity = (newQuantity: number) => {
    setQuantity(newQuantity);
    onQuantityChange(newQuantity);
  };

  const handleDecrement = () => {
    if (!disableQuantityUpdate && quantity > 0) {
      updateQuantity(quantity - 1);
    } else if (disableQuantityUpdate) {
      onQuantityChange(quantity - 1);
    }
  };

  const handleIncrement = () => {
    if (!disableQuantityUpdate) {
      updateQuantity(quantity + 1);
    } else if (disableQuantityUpdate) {
      onQuantityChange(quantity + 1);
    }
  };

  const [largeFirstColor, largeSecondColor] =
    colorInfo.layout.counterButton.large.counterButtonIcon.twoTonedColors;
  const [smallFirstColor, smallSecondColor] =
    colorInfo.layout.counterButton.small.counterButtonIcon.twoTonedColors;

  const handleLargeCounterButton = () => {
    return (
      <div
        id={camelCasedButtonName}
        className="flex justify-between items-center largeCounterButton py-2"
      >
        <button
          disabled={disable || quantity === 0}
          onClick={handleDecrement}
          className="flex-1 h-full flex items-center justify-center hover:bg-gray-800 transition-colors disabled:opacity-90"
        >
          -
        </button>
        <div className="largeCounterButtonText justify-center  flex-1 bg-white text-black h-full flex items-center justify-cente">
          {quantity}
        </div>
        <button
          disabled={disable}
          onClick={handleIncrement}
          className="flex-1 h-full flex items-center justify-center disabled:opacity-90"
        >
          +
        </button>
      </div>
    );
  };

  switch (buttonType) {
    case "LARGE":
      return handleLargeCounterButton();

    case "SMALL":
      return (
        <>
          {/* {counterLoader && <div className="loading-overlay visible" />} */}
          <div className="relative inline-flex flex-col">
            <div
              id={camelCasedButtonName}
              className={`flex justify-between items-center smallCounterButton py-2 ${
                counterLoader && "opacity-50"
              } `}
            >
              <button
                disabled={disable || quantity === 0 || counterLoader}
                onClick={handleDecrement}
                className="flex-1 h-full flex items-center justify-center"
              >
                -
              </button>
              <div className="smallCounterButtonText flex-1 bg-white text-black h-full flex items-center justify-center">
                {quantity}
              </div>
              <button
                disabled={disable || counterLoader}
                onClick={handleIncrement}
                className="flex-1 h-full flex items-center justify-center"
              >
                +
              </button>
            </div>
            {/* Loading bar container */}
            <div className="absolute -bottom-2 inset-x-0 h-0.5">
              {counterLoader && <div className="dash" />}
            </div>
          </div>
        </>
      );

    default:
      return handleLargeCounterButton();
  }
};

export default CounterButton;
