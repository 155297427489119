import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IObject } from "types";
interface AuthState {
  user: any;
  token: string | null;
  refreshToken: string | null;
  loggedIn: boolean;
  loginDetails: any;
  globalLoader: boolean;
  tenant: { id: number; tenantName: string; databaseName: string } | null;
  initialize: boolean;
  initialAPISuccess: boolean;
  tenantFetched: boolean;
  branchFetched: boolean;
  tableStatusFetched: boolean;
  logOutState: boolean;
  branch: IObject | null;
}

const initialState: AuthState = {
  user: null,
  token: null,
  refreshToken: null,
  loggedIn: false,
  loginDetails: {},
  globalLoader: false,
  tenant: null,
  initialize: false,
  initialAPISuccess: false,
  tenantFetched: false,
  branchFetched: false,
  tableStatusFetched: false,
  logOutState: false,
  branch: null,
};
const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    handleInitialization: (state) => {
      state.initialize = true;
    },
    handleTenantFetched(state, action: PayloadAction<boolean>) {
      state.tenantFetched = action.payload;
    },
    handleBranchFetched(state, action: PayloadAction<boolean>) {
      state.tenantFetched = action.payload;
    },
    handleTableStatusFetched(state, action: PayloadAction<boolean>) {
      state.tableStatusFetched = action.payload;
    },
    handleInitialAPICallSuccess: (state, action: PayloadAction<boolean>) => {
      state.initialAPISuccess = action.payload;
    },
    handleLogInState: (state) => {
      state.loggedIn = true;
    },
    handleLogOutState: (state) => {
      state.logOutState = true;
    },
    handleLogInCredentials: (state, action) => {
      // Store login details and tokens in Redux state
      state.loginDetails = action.payload.data;
      state.token = action.payload.data["access_token"];
      state.refreshToken = action.payload.data["refresh_token"];

      // Get token expiration times from payload
      const expiresIn = action.payload.data["expires_in"]; // Access token expiry in seconds
      const refreshExpiresIn = action.payload.data["refresh_expires_in"]; // Refresh token expiry in seconds

      // Calculate the expiration timestamps
      const accessTokenExpiry = Date.now() + expiresIn * 1000; // Convert seconds to milliseconds
      const refreshTokenExpiry = Date.now() + refreshExpiresIn * 1000;

      // Store tokens and their expiration in sessionStorage
      sessionStorage.setItem(
        "accessToken",
        action.payload.data["access_token"]
      );
      sessionStorage.setItem(
        "refreshToken",
        action.payload.data["refresh_token"]
      );
      sessionStorage.setItem("accessTokenExpiry", accessTokenExpiry.toString()); // Store as string
      sessionStorage.setItem(
        "refreshTokenExpiry",
        refreshTokenExpiry.toString()
      );

      // Set loggedIn state
      state.loggedIn = true;
    },
    handleLogOut: (state) => {
      state.logOutState = true;
      // Clear sessionStorage
      sessionStorage.clear();

      state.loggedIn = false;
      state.user = null;
      state.token = null;
      state.loginDetails = {};
      state.refreshToken = null;
      state.user = null;
      state.tenant = null;
    },
    handleGlobalLoader: (state, action) => {
      state.globalLoader = action.payload;
    },
    handleTenantData: (state, action) => {
      state.tenant = action?.payload;
    },
    handleBranchData: (state, action) => {
      state.branch = action?.payload;
    },
  },
});
export const {
  handleInitialAPICallSuccess,
  handleInitialization,
  handleLogInCredentials,
  handleLogOut,
  handleLogInState,
  handleGlobalLoader,
  handleTenantData,
  handleBranchData,
  handleBranchFetched,
  handleTableStatusFetched,
  handleTenantFetched,
  handleLogOutState,
} = authSlice.actions;
export default authSlice.reducer;
export const selectCurrentUser = (state: any) => state.auth.user;
export const selectCurrentToken = (state: any) => {
  return state.auth.token;
};
