import { ReactComponent as BackArrow } from "assets/images/back-arrow.svg";
import { ReactComponent as Cancel } from "assets/images/cancel.svg";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/customHooks";
import {
  handleClearLocalSearchQuery,
  handleSearchQuery,
} from "store/globalSearchAndFilters/globalSearchAndFiltersSlice";

//NOTE: STYLES NEEDS TO BE DIVIDED - JSON AND TAILWIND

const SearchBarHeader: React.FC = () => {
  const navigate = useNavigate();

  const inputRef = useRef<HTMLInputElement>(null); // Create a reference for the input

  const dispatch = useAppDispatch();
  const { searchQuery, clearLocalSearchQuery } = useAppSelector(
    (state) => state.globalSearchAndFilters
  );

  const [debouncedQuery, setDebouncedQuery] = useState(searchQuery);

  // Use useEffect to focus on the input when the component mounts
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus(); // Set focus on the input
    }
  }, []);

  useEffect(() => {
    if (clearLocalSearchQuery) {
      setDebouncedQuery("");
      dispatch(handleClearLocalSearchQuery(false));
    }
  }, [clearLocalSearchQuery, dispatch]);

  useEffect(() => {
    const handler = setTimeout(() => {
      dispatch(handleSearchQuery(debouncedQuery));
    }, 1000);

    return () => {
      clearTimeout(handler);
    };
  }, [debouncedQuery, dispatch]);

  return (
    <div className="bg-[#571246] h-[80px] p-2 w-full max-w-md">
      <div className="flex items-center h-[60px] bg-[#6E1B5A] rounded-md w-full space-x-2">
        {/* Back Arrow */}
        <BackArrow
          className="ml-[10px]"
          onClick={() => {
            setDebouncedQuery("");
            dispatch(handleSearchQuery(""));
            navigate(-1);
          }}
          color="white"
        />

        {/* Search Input */}
        <input
          ref={inputRef}
          type="text"
          placeholder="Search All Items"
          className="mx-[10px] bg-transparent text-[16px] font-normal placeholder-gray-300 text-white outline-none flex-grow"
          value={debouncedQuery}
          onChange={(e) => {
            const inputValue = e.target.value;
            // Updated regex to allow letters number and spaces
            const validInput = /^[A-Za-z0-9\s]+$/;

            // Check if the input value matches the allowed pattern or is empty
            if (inputValue === "" || validInput.test(inputValue)) {
              setDebouncedQuery(inputValue);
            }
          }}
        />

        {/* Close Button */}
        <div className="pr-2">
          <Cancel
            onClick={() => {
              dispatch(handleClearLocalSearchQuery(true));
              if (inputRef.current) {
                inputRef.current.focus(); // Set focus back to the input
              }
            }}
            color="white"
          />
        </div>
      </div>
    </div>
  );
};

export default SearchBarHeader;
