import Modal from "antd/es/modal/Modal";
import { convertToCapitalizedString } from "central-utils/stringUtils";
import { useNavigate } from "react-router-dom";

const UserProfileModal = ({ handleClose }: any) => {
  const navigate = useNavigate();
  const name = JSON.parse(
    sessionStorage.getItem("CustomerDetails") || ""
  )?.name;
  const mobile = JSON.parse(
    sessionStorage.getItem("CustomerDetails") || ""
  )?.mobile;
  return (
    <Modal
      open={true}
      onCancel={handleClose}
      footer={null}
      mask={false}
      closable={false}
      style={{
        position: "fixed",
        bottom: "0",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        maxWidth: "100%",
        top: "0",
        margin: 0,
        height: "100%",
        paddingBottom: 0,
        background: "rgba(0, 0, 0, 0.4)",
        backdropFilter: "blur(2px)",
      }}
      // bodyStyle={{
      //   padding: 0,
      //   animation: "slide-up 0.3s ease-out",
      // }}
    >
      <div className="relative">
        <div className="bg-white text-black mt-[60px] mx-5 rounded-sm p-5 flex flex-col gap-2">
          <span className="text-[#571246] text-2xl font-light">
            Hello! {convertToCapitalizedString(name)}
          </span>
          <span>
            You are logged in as <span className="font-semibold">{mobile}</span>
          </span>
          <div className="flex flex-row items-center gap-6 text-[#571246] mt-3">
            <div
              className="border-[1px] border-[#571246] p-3 px-12 rounded-md"
              onClick={handleClose}
            >
              Close
            </div>
            <div>
              View{" "}
              <span
                className="underline decoration-[#571246]"
                onClick={() => navigate("/terms/conditions")}
              >
                Terms & Conditions
              </span>
            </div>
          </div>
          <div className="absolute -top-2 right-6 w-0 h-0 border-l-[10px] border-l-transparent border-r-[10px] border-r-transparent border-b-[10px] border-b-white z-10" />
        </div>
      </div>
    </Modal>
  );
};

export default UserProfileModal;
