import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// Define a type for the slice state
interface FooterState {
  totalAmount: string | number;
  disableCheckoutButton: boolean;
  footerHeight: number;
  unconfirmedOrderItems: number;
}

// Define the initial state using that type
const initialState: FooterState = {
  totalAmount: "",
  disableCheckoutButton: true,
  footerHeight: 70,
  unconfirmedOrderItems: 0,
};

// Create the slice
const footerSlice = createSlice({
  name: "footer",
  initialState,
  reducers: {
    handleTotalAmountFooter: (
      state,
      action: PayloadAction<string | number>
    ) => {
      state.totalAmount = action.payload;
    },
    handleCheckoutButtonDisable: (state, action: PayloadAction<boolean>) => {
      state.disableCheckoutButton = action.payload;
    },
    handleFooterHeight: (state, action: PayloadAction<number>) => {
      state.footerHeight = action.payload;
    },
    handleNumberOfUnconfirmedOrderItems: (
      state,
      action: PayloadAction<number>
    ) => {
      state.unconfirmedOrderItems = action.payload;
    },
  },
});

// Export the actions
export const {
  handleTotalAmountFooter,
  handleCheckoutButtonDisable,
  handleFooterHeight,
  handleNumberOfUnconfirmedOrderItems,
} = footerSlice.actions;

// Export the reducer
export default footerSlice.reducer;
