import { ReactComponent as BillIcon } from "assets/images/bill.svg";
import { ReactComponent as HomeIcon } from "assets/images/home.svg";
import { ReactComponent as OrderIcon } from "assets/images/order.svg";
import { ReactComponent as RaisedHandIcon } from "assets/images/raised-hand.svg";
import { ReactComponent as RepeatIcon } from "assets/images/repeat.svg";
import { ReactComponent as WaiterIcon } from "assets/images/waiter.svg";
import CheckoutModal from "components/checkout/CheckoutModal";
import CustomTab from "components/CustomTab";
import TableVerification from "components/TableVerification";
import { useOrder } from "context/order/OrderContext";
import { useWaiterPusherChannel } from "context/pusher/waiter";
import React, { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/customHooks";
import { handleShowCallWaiterModal } from "store/tab/tabSlice";
import { TabItem } from "types";

const CustomFooter: React.FC = () => {
  const dispatch = useAppDispatch();

  const [isWaiterCalled, setIsWaiterCalled] = useState<boolean>(false);
  const [ShowCheckoutModal, setShowCheckoutModal] = useState<boolean>(false);

  const { orderIsUninitialized, orderData } = useOrder();
  const waiterPusherData = useWaiterPusherChannel();

  const selectedOrderPageHeaderTab = useAppSelector(
    (state) => state.tab.selectedTabDetails?.orderPageHeaderTabs
  );
  const { disableCheckoutButton } = useAppSelector((state) => state.footer);
  const showCallWaiter = useAppSelector(
    (state) => state.tab.showCallWaiterModal
  );

  const { pathname } = useLocation();

  useEffect(() => {
    if (
      waiterPusherData &&
      Object.keys(waiterPusherData)?.length &&
      Object.hasOwn(waiterPusherData, "callWaiterStatus")
    ) {
      const isCalled = waiterPusherData?.callWaiterStatus;
      setIsWaiterCalled(isCalled);
    }
  }, [waiterPusherData]);

  useEffect(() => {
    if (
      orderData &&
      Object.keys(orderData)?.length &&
      Object.hasOwn(orderData, "callWaiter")
    ) {
      const isCalled = orderData?.callWaiter;
      setIsWaiterCalled(isCalled);
    }
  }, [orderData]);

  // Paths where the footer should not be displayed
  const pathsWithoutFooter = [
    "/",
    "/customer-details",
    "/check-out",
    "/payment-confirmation",
    "/payment-in-progress-block",
    "/payment-status",
    "/search",
    "/verify-otp",
    "/enter-name",
    "/verify-pin",
    "/terms/conditions",
    "/terms/service",
  ];

  const footerTabItems: TabItem[] = useMemo(
    () => [
      {
        id: 1,
        label: "Home",
        activeLabelClassName: "text-[10px] font-bold",
        inactiveLabelClassName: "text-[10px] font-normal",
        icon: HomeIcon,
        iconWidth: 25,
        iconHeight: 24,
        type: "ROUTE",
        pathName: "/home",
        disabled: false,
      },
      {
        id: 2,
        label: "Call Waiter",
        activeLabelClassName: "text-[10px] font-bold",
        inactiveLabelClassName: "text-[10px] font-normal",
        icon: isWaiterCalled ? RaisedHandIcon : WaiterIcon,
        iconWidth: isWaiterCalled ? 33 : 20,
        iconHeight: isWaiterCalled ? 25 : 20,
        type: "FUNCTION",
        disabled: false,
        handleOnClick: () =>
          dispatch(handleShowCallWaiterModal(!showCallWaiter)),
      },
      {
        id: 3,
        label: "Repeat",
        activeLabelClassName: "text-[10px] font-bold",
        inactiveLabelClassName: "text-[10px] font-normal",
        icon: RepeatIcon,
        iconWidth: 17.41,
        iconHeight: 20,
        pathName: "/re-order",
        type: "ROUTE",
        disabled: false,
      },
      {
        id: 4,
        label: "My Orders",
        activeLabelClassName: "text-[10px] font-bold",
        inactiveLabelClassName: "text-[10px] font-normal",
        icon: OrderIcon,
        iconWidth: 18.87,
        iconHeight: 20,
        type: "ROUTE",
        pathName: "/order",
        unconfirmedItems: true,
        disabled: false,
      },
      {
        id: 5,
        label: "Pay Bill",
        activeLabelClassName: "text-[10px] font-bold",
        inactiveLabelClassName: "text-[10px] font-normal ",
        icon: BillIcon,
        iconWidth: 28.57,
        iconHeight: 20,
        type: "FUNCTION",
        pathName: "/check-out",
        disabled: disableCheckoutButton,
        handleOnClick: () => setShowCheckoutModal(true),
      },
    ],
    [disableCheckoutButton, isWaiterCalled, showCallWaiter, dispatch]
  );

  // If the current path is in the pathsWithoutFooter array, do not render the footer
  if (pathsWithoutFooter.includes(pathname)) {
    return null;
  }

  return (
    <div className="footerSection w-full">
      {pathname === "/order" &&
      !orderIsUninitialized &&
      orderData &&
      !orderData?.isVerified &&
      selectedOrderPageHeaderTab?.label === "Pending" ? (
        <TableVerification />
      ) : (
        ""
      )}
      <div className="h-[68px]">
        <CustomTab identifierKey="footerTabs" tabItems={footerTabItems} />
      </div>
      {ShowCheckoutModal && (
        <CheckoutModal onClose={() => setShowCheckoutModal(false)} />
      )}
    </div>
  );
};

export default CustomFooter;
