import React from "react";
import { ReactComponent as ErrIcon } from "assets/images/field-error.svg";
interface Props {
  message: string;
}

// NOTE: STYLES NEEDS TO BE DIVIDED - JSON AND TAILWIND

const FieldErrorMessage: React.FC<Props> = ({ message }) => {
  return (
    <div className="flex justify-start items-center mt-[3px]">
      <ErrIcon
        height={17.55}
        width={17.55}
        fill="#EE0004"
        color={"#EE0004"}
        className="mr-[5px]"
      />
      <div className="text-[#EE0004] text-[16px] font-normal">{message}</div>
    </div>
  );
};

export default FieldErrorMessage;
