export const handleValidImageUrl = (imageObj: any) => {
  let validImageUrl = null;
  for (let i = 0; i < imageObj?.length; i++) {
    const imgURL = imageObj?.[i]?.url;
    if (imgURL) {
      validImageUrl = imgURL;
      break;
    }
  }

  return validImageUrl;
};
