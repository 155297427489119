import {
  convertStringToHyphenated,
  convertToCapitalizedString,
} from "central-utils/stringUtils";

// Function to handle API filtering
export const handleAPIFiltering = (
  apiData: any[],
  searchQuery: string,
  paramText: string
): any[] => {
  if (!Array.isArray(apiData) || apiData.length === 0) {
    return [];
  }

  // Helper function to filter and merge categories
  const filterAndMergeCategories = (categories: any[]): any[] => {
    const seenCategories = new Set<string>();

    // Function to process each category
    const processCategory = (category: any): any => {
      if (!category) return null;

      // Check if the category name matches the search query
      const isMatch =
        searchQuery?.trim() &&
        (category?.categoryName
          ?.toLowerCase()
          ?.includes(searchQuery?.trim().toLowerCase()) ||
          category?.tagName
            ?.toLowerCase()
            ?.includes(searchQuery?.trim().toLowerCase()));

      // Filter subcategories and menu details only if the category name does not match
      const filteredSubCategories =
        !isMatch && Array.isArray(category?.subCategories)
          ? filterAndMergeCategories(category.subCategories)
          : category?.subCategories || [];
      const filteredMenuDetails = !isMatch
        ? searchQuery?.trim()
          ? (category?.menuResponseDetails || []).filter((item: any) =>
              item?.displayName
                ?.toLowerCase()
                ?.includes(searchQuery.trim().toLowerCase())
            )
          : category?.menuResponseDetails || []
        : category?.menuResponseDetails || [];

      // If the current category or any of its subcategories or menu details match the search query, include it
      if (
        isMatch ||
        filteredSubCategories?.length ||
        filteredMenuDetails?.length
      ) {
        return {
          ...category,
          subCategories: filteredSubCategories,
          menuResponseDetails: filteredMenuDetails,
        };
      }
      return null;
    };

    // Ensure `categories` is an array and process each category
    return Array.isArray(categories)
      ? categories?.flatMap((category) => {
          const result = processCategory(category);
          if (result && !seenCategories.has(result.categoryName)) {
            seenCategories.add(result.categoryName);
            return [result];
          }
          return [];
        })
      : [];
  };

  // Initial filtering of categories based on paramText
  const initialCategories = apiData?.filter((obj: any) => {
    const tagName = obj?.tagName ? convertToCapitalizedString(obj.tagName) : "";
    return (
      // convertStringToHyphenated(obj?.categoryName ?? tagName) === paramText
      (obj?.categoryName ?? tagName) === paramText
    );
  });

  // Filter out initial categories to get the remaining categories
  const remainingCategories = apiData?.filter(
    (obj: any) => !initialCategories.includes(obj)
  );

  // Process remaining categories to find matches for the search query
  let queryInRemainingData = [];
  if (Array.isArray(remainingCategories) && remainingCategories.length > 0) {
    for (const catData of remainingCategories) {
      const filteredResult = filterAndMergeCategories([catData]);
      if (filteredResult.length > 0) {
        queryInRemainingData.push(...filteredResult);
      }
    }
  }

  if (
    !filterAndMergeCategories(initialCategories)?.length &&
    queryInRemainingData?.length > 0
  ) {
    const [foundCategoryData] = queryInRemainingData;
    return [
      [...filterAndMergeCategories(initialCategories)],
      encodeURIComponent(
        convertStringToHyphenated(foundCategoryData?.categoryName)
      ),
    ];
  }

  return filterAndMergeCategories(initialCategories);
};
