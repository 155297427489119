import { ReactComponent as PendingIcon } from "assets/images/pending-orders.svg";
import { useOrder } from 'context/order/OrderContext';
import { useCheckoutPusherChannel } from 'context/pusher/checkout';
import usePreventBackNavigation from 'hooks/usePreventBackNavigation';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// NOTE: STYLES NEEDS TO BE DIVIDED - JSON AND TAILWIND

const PaymentInProgressBlockPage: React.FC = () => {
    usePreventBackNavigation("/payment-in-progress-block");

    const navigate = useNavigate();
    const location = useLocation();
    const { orderData, orderIsUninitialized, refetchOrderData } = useOrder();

    const pusherCheckoutDataProvider = useCheckoutPusherChannel();

    const [pageMessage, setPageMessage] = useState<string>("You cannot place any orders as your table is currently being checked out!");

    useEffect(() => {
        if (pusherCheckoutDataProvider && Object.keys(pusherCheckoutDataProvider ?? {})?.length && pusherCheckoutDataProvider?.orderStatus === "CHECKOUT") {
            if (pusherCheckoutDataProvider && Object.hasOwn(pusherCheckoutDataProvider, "customerId") && pusherCheckoutDataProvider?.customerId) {
                setPageMessage("You cannot place any orders as your table is currently being checked out!");
            } else {
                setPageMessage("The waiter is processing your payment!");
            }
        }
    }, [pusherCheckoutDataProvider]);

    useEffect(() => {
        if (location.state && Object.keys(location.state ?? {}).length > 0) {
            setPageMessage(location.state?.message);
        }
    }, [location]);

    useEffect(() => {
        const interval = setInterval(() => {
            if (!orderIsUninitialized && orderData?.status !== "COMPLETED") {
                refetchOrderData();
            }
        }, 180000);
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        let timer: NodeJS.Timeout;

        if (orderData?.status === "COMPLETED") {
            timer = setTimeout(() => {
                navigate("/payment-status");
            }, 4000);
        }

        return () => {
            if (timer) clearTimeout(timer);
        };
    }, [navigate, orderData]);

    return (
        <div className="flex flex-col items-center justify-center h-full text-[#571246] text-[16px] font-normal leading-[24px] text-center mx-10">
            <PendingIcon width={44} height={45} className='mb-[20px]' />
            <div>{pageMessage}</div>
        </div>

    )
}

export default PaymentInProgressBlockPage